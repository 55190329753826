import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/App/App'
import * as serviceWorker from './serviceWorker'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import axios from 'axios'
import { checkStateExpired, saveState } from './store/sessionStorage'
import InApp from 'detect-inapp';
import {isAndroid} from 'react-device-detect';
import envs from './config/Variables'

//Inicializa a classe para verificar por onde o usuário está acessando o site
const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);
var condicaoInApp = typeof window.location.pathname.toString().split('/')[2] == "undefined"

if (process.env.NODE_ENV === 'production'){
  if (condicaoInApp == false && sessionStorage.getItem('redirect-check') == null){
    sessionStorage.setItem('redirect-check', true)
    window.location.href = window.location.origin + '/' + window.location.pathname.toString().split('/')[1]
  }
  
  if (condicaoInApp && sessionStorage.getItem('redirect-inapp') == null) {
    sessionStorage.setItem('redirect-inapp', true)
    sessionStorage.setItem('redirect-check', true)
    window.location.href = envs.REACT_APP_API_URL + '/biometria/in-app-check?parceiro=' + window.location.pathname.toString().split('/')[1]
  }
}

//Verifica se o cadastro do usuário não está vencido
checkStateExpired()
const store = createStore(rootReducer, applyMiddleware(thunk))

let idLojista = sessionStorage.getItem('idLojista')
let parceiroAntigo = sessionStorage.getItem('parceiro')
let parceiro = window.location.pathname.toString().split('/')[1]
let url = window.location.href.split('/')[3]



if (process.env.NODE_ENV === 'production'){
  url = window.location.href.split('/')[4]
}

if ((typeof url === 'undefined' || url !== 'cadastro') && sessionStorage.getItem('state')) {
  sessionStorage.removeItem('state')
  window.location.reload()
}

if (((idLojista === null || idLojista === 0) || (parceiroAntigo === null || parceiro !== parceiroAntigo)) && condicaoInApp == false) {
  sessionStorage.removeItem('state')
  if (window.location.href.split('/')[4] === 'cadastro') {
    window.location = 'bem-vindo'
  } else {
    axios.get('cores', {headers: {'Parceiro-Origem': 'localhost:3000'} } )
    .then(async (response) => {
      sessionStorage.setItem('parceiro', parceiro)

      let idLojista = typeof response.headers.idlojista === "undefined" ? 0 : response.headers.idlojista 
      sessionStorage.setItem('idLojista', idLojista)
      sessionStorage.setItem('headers', JSON.stringify(response.headers))

      //Caso não seja retornado o id do lojista, direcionamos o cliente para tela de "Página não encontrada"
      if (process.env.NODE_ENV === 'production' && idLojista === 0){
        window.location.href = "pagina-nao-encontrada"
      }

      //Preenche as cores que foram retornadas da API no GlobalReducer
      if (typeof response.data !== "undefined"){
        await sessionStorage.setItem('cores', JSON.stringify(response.data))
      }
    })
  }  
} else if (process.env.NODE_ENV != 'production') {
  axios.get('cores', {headers: {'Parceiro-Origem': 'localhost:3000'} } )
    .then(async (response) => {
      sessionStorage.setItem('parceiro', parceiro)

      let idLojista = typeof response.headers.idlojista === "undefined" ? 0 : response.headers.idlojista 
      sessionStorage.setItem('idLojista', idLojista)
      sessionStorage.setItem('headers', JSON.stringify(response.headers))

      //Caso não seja retornado o id do lojista, direcionamos o cliente para tela de "Página não encontrada"
      if (process.env.NODE_ENV === 'production' && idLojista === 0){
        window.location.href = "pagina-nao-encontrada"
      }

      //Preenche as cores que foram retornadas da API no GlobalReducer
      if (typeof response.data !== "undefined"){
        await sessionStorage.setItem('cores', JSON.stringify(response.data))
      }
    })
}

store.subscribe(() => {
  let state = store.getState()

  //Caso o usuário esteja no step 1, limpa toda a sessão do mesmo
  if (state.global.step >= 3) {
    saveState(state);
  } else {
    sessionStorage.removeItem('state');
  }
})

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
