import { loadState} from './../sessionStorage'

let initState = {
  fieldsRequired: ['vencimento', 'formaEnvio'],
  fields: {
    stepInvalido: true,
    vencimento: '',
    formaEnvio: '',
    formaRecebimento: '',
    emailFatura: '',
    melhorDiaCompra: '',
  },
  errors: {
    vencimento: [],
    formaEnvio: [],
    formaRecebimento: [],
    emailFatura: [],
  },
  api: {
    event: '',
    message: '',
  },
  drops: {
    vencimento: [],
    formaEnvio: [],
    formaRecebimento: [],
    melhorDiaCompra: [
      {
        dia: "1",
        melhorDiaCompra: "21"
      },
      {
        dia: "2",
        melhorDiaCompra: "22"
      },
      {
        dia: "5",
        melhorDiaCompra: "25"
      },
      {
        dia: "7",
        melhorDiaCompra: "27"
      },
      {
        dia: "10",
        melhorDiaCompra: "1"
      },
      {
        dia: "15",
        melhorDiaCompra: "5"
      },
      {
        dia: "20",
        melhorDiaCompra: "10"
      },
      {
        dia: "25",
        melhorDiaCompra: "15"
      }
    ]
  }
}

const persistedState = loadState('cartao')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const CartaoReducer = (state = initState, action) => {
  switch (action.type) {
      case 'RESET_CARTAO':
        return initState
      case 'SET_CARTAO':
        return action.state
      case 'SET_FIELDS_CARTAO':
        return {...state, fields: action.fields}
      case 'SET_FIELDS_CARTAO_RASCUNHO':

        let objectDiaCompra
        let objectVencimento = state.drops.vencimento.find(x => x.valor === action.fields.vencimento.toString())

        let diaVencimento = ""
        if (typeof objectVencimento !== "undefined" && objectVencimento !== null) {
          diaVencimento = objectVencimento.chave
          objectDiaCompra = initState.drops.melhorDiaCompra.find(x => x.dia === objectVencimento.valor)
        }

        let melhorDiaCompra = ""
        if (typeof objectDiaCompra !== "undefined" && objectDiaCompra !== null) {
          melhorDiaCompra = objectDiaCompra.melhorDiaCompra
        }

        if (action.fields.formaRecebimento === null) {
          action.fields.formaRecebimento = state.fields.formaRecebimento
        }

        return {...state, fields: {...action.fields, vencimento: diaVencimento, melhorDiaCompra } }
      case 'SET_DROPS_CARTAO':
        state = {...state, drops: {...action.drops, melhorDiaCompra: initState.drops.melhorDiaCompra}}

        //Caso só seja retornado um valor no drop de formaRecebimento, já preenche o valor do fields com esse item
        if (state.drops.formaRecebimento.length === 1){
          state.fields.formaRecebimento = state.drops.formaRecebimento[0].chave
        } else {
          state.fieldsRequired.push('formaRecebimento')
        }

        return state
      case 'SET_API_OCUPACAO':
        return {...state, api: action.api}
      default:
        return state;
  }
}

export default CartaoReducer