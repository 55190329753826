import React, { Component } from 'react'
import './NotFoundDesktop.css'
import { Typography, Box } from '@material-ui/core'
  
export class NotFoundDesktop extends Component {

  render() {
    return (
      <React.Fragment>
          <Box className="box-not-found">
            <div className="div-padding-left-5">
              <div className="div-flex">
                <div className="flex-6">
                  <div className="div-60 margin-top-vh3">
                    <Typography className="title-not-found-desktop margin-0">
                        Ops...
                    </Typography>
                  </div>
                  <div className="div-60 margin-top-vh3">
                    <Typography className="sub-title-not-found-desktop" >
                        Como você chegou até aqui?
                    </Typography>
                  </div>
                </div>
                <div className="flex-4">
                  <img className="img-404-superior-desktop" src="/images/404.png" />
                </div>
              </div>
              <div className="div-center margin-top-vh2">
                  <img className="img-fim-desktop" src="/images/fim-desktop.png" />
              </div>
              <div className="div-center div-90 margin-top-vh2">
                <Typography className="text-description-not-found-desktop" >
                    Parece que a página que você tentou acessar não existe.
                </Typography>
              </div>
            </div>
          </Box>
      </React.Fragment>
    );
  }
}

 
export default NotFoundDesktop;