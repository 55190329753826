import request from './../../common/http-client'
import { checkErrors, stringCamelize, stringCapitalize } from './../../common/validators'

export const setDadosIniciais = (state) => {
    return async (dispatch, getState) => {

      state.api = {...state.api, event: '', message: ''}

      if (checkErrors(state.errors))
          state.fields = {...state.fields, stepInvalido: true}

      let oldState = getState()
      let idLojista = sessionStorage.getItem('idLojista')
      let ddd = state.fields.celular.toString().substr(1, 2)
      let celular = state.fields.celular.replace(/\D/g, '').substr(2, 9)

      sessionStorage.setItem('celular', "(" + ddd + ") " + celular)

      let options = {
        url: '/sessao/init',
        method: 'POST',
        data: {
          idLojista: (idLojista !== null && idLojista !== "0") ? idLojista : process.env.REACT_APP_API_ID_LOJISTA,
          browser: oldState.global.browser,
          date: oldState.global.date,
          device: navigator.platform,
          os: oldState.global.os,
          cpf: state.fields.cpf.replace(/\D/g, ''),
          nome: state.fields.nome,
          numeroCelular: celular,
          ddd: ddd
        },
      }

      sessionStorage.setItem('nomeCliente', state.fields.nome);
      sessionStorage.setItem('cpfCliente', state.fields.cpf);
      sessionStorage.removeItem('integraPlataformaBiometrica');

      await request(options)
        .then(async (response) => {
          state.fields = {...state.fields, stepInvalido: false}
          sessionStorage.setItem('integraPlataformaBiometrica', response?.model?.integraPlataformaBiometrica);
          await dispatch({ type: 'SET_STEP', step: 2 })
          dispatch({ type: 'SET_TOKEN_SESSAO', tokenSessao: response.model.id})
        }).catch(error => {
          if (typeof error.errors === 'undefined') {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          } else {
            error.errors.map(error => {
              let key = stringCamelize(error.key)
              
              if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
                state.api.message = []
                state.api.event = "warning"
                state.api.message.push(error.value)
              }

              if (Array.isArray(state.errors[key]) === false)
                state.errors[key] = []
  
              state.errors[key].push(error.value)
            })
  
            if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
              state.api = {...state.api, event: 'warning', message: 'Sess�o n�o encontrada'}
              dispatch({ type: 'SET_STEP', step: 1 })
            }
  
            if (error.code === 500) {
              state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
            }
          }

          state.fields = {...state.fields, stepInvalido: true}
        })

        await dispatch({ type: 'SET_DADOS_INICIAIS', state })
    }
}

export const setAPI = (api) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_API_DADOS_INICIAIS', api })
  }
}