import { loadState} from './../sessionStorage'

let initState = {
  fieldsRequired: ['frente', 'verso', 'selfie', 'complemento'],
  fields: {
    stepInvalido: true,
    upload_doc: false,
    termo_required: false,
    documentos_enviados: false,
    frente: '',
    verso: '',
    rg_frente: '',
    rg_verso: '',
    cnh_frente: '',
    cnh_verso: '',
    rne_frente: '',
    rne_verso: '',
    selfie: '',
    complemento: '',
    documento: '',
  },
  errors: {
    rg_frente: '',
    rg_verso: '',
    cnh_frente: '',
    cnh_verso: '',
    rne_frente: '',
    rne_verso: '',
    selfie: [],
    complemento: [],
  },
  api: {
    event: '',
    message: '',
  },
  drops: {}
}

const persistedState = loadState('documento')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const DocumentoReducer = (state = initState, action) => {
  switch (action.type) {
      case 'RESET_DOCUMENTO':
        return initState
      case 'SET_DOCUMENTO':
        return action.state
      case 'SET_DOCUMENTO_IDENTIFICACAO': 
        return {...state, fields: {...state.fields, documento: action.documento, [action.documento.toString().toLowerCase() + "_frente"]: action.value, [action.documento.toString().toLowerCase() + "_verso"]: action.value } }
      case 'SET_COMPLEMENTO':
        return {...state, fields: {...state.fields, termo: action.value } }
      case 'SET_SELFIE':
        return {...state, fields: {...state.fields, selfie: action.value } }
      case 'SET_TERMO_REQUIRED':
        state.fields = {...state.fields, termo_required: action.termo_required}
        return state
      case 'SET_API_DOCUMENTO':
        return {...state, api: action.api}
      default:
        return state;
  }
}

export default DocumentoReducer