import React, { Component } from 'react';
import './Etapa3.css'
import { Box, Typography, Button, MobileStepper } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export class Etapa3 extends Component {

  render() {

    return (
      <React.Fragment>
        <Box className="box">
          <div className="margin-top-vh7 div-95">
            <Typography className="title">
              Bora!
            </Typography>
          </div>
          <div className="margin-top-vh15 div-center">
            <img className="img-etapa-3" src="/images/etapa3.png" alt="Bora pro cadastro" />
          </div>
          <div className="div-center div-90">
            <Typography className="text-onboarding">
              Então vamos lá, você está muito perto de ter seu novo cartão de crédito! :D 
            </Typography>
          </div>
          <div className="divButtons div-bottom-5">
            <div className="div-flex">
              <div className="flex-7">
                <MobileStepper
                  variant="dots"
                  steps={3}
                  activeStep={2}
                  className="margin-top-vh1"
                />
              </div>
              <div className="flex-3">
                <Button variant="contained" href="cadastro" className="button-next-onboarding" ><ArrowForwardIcon className="icon-button-next"/></Button>
                <Button variant="contained" onClick={this.props.prevStep} id="button-back-onboarding"  className="button-back-onboarding" ><ArrowBackIcon className="icon-button-next"/></Button>
              </div>
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
  }
}

export default Etapa3;