import request from './../../common/http-client'
import { stringCamelize, formataData, checkMaioridade, stringCapitalize } from './../../common/validators'

export const validarToken = (state) => {
    return async (dispatch, getState) => {

      //Limpa os eventos do snackbar de avisos
      state.api = {...state.api, event: '', message: ''}
      //Preenche o step como inválido, só coloca ele como válido caso a API retorne 200(success)
      state.fields = {...state.fields, stepInvalido: true}
      
      let idSessao = getState().global.tokenSessao
      let options = {
        url: '/sessao/token/validar',
        method: 'PATCH',
        data: {
          idSessao: idSessao,
          token: state.fields.token.replace(/\D/g, ''),
        },
      }

      await request(options)
        .then(async (response) => {
          state.fields = {...state.fields, stepInvalido: false, tokenValidado: true}
        })
        .catch(error => {
          if (typeof error.errors === 'undefined') {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          } else {
            error.errors.map(error => {
              let key = stringCamelize(error.key)
              
              if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
                state.api.message = []
                state.api.event = "warning"
                state.api.message.push(error.value)
              }

              if (Array.isArray(state.errors[key]) === false)
                state.errors[key] = []
  
              state.errors[key].push(error.value)
            })
  
            if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
              state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
              dispatch({ type: 'SET_STEP', step: 1 })
            }
  
            if (error.code === 500) {
              state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
            }
          }

          state.fields = {...state.fields, stepInvalido: true}
        })
      
      await dispatch({ type: 'SET_TOKEN', state })
    }
}

export const integrarDadosIniciais = (state) => {
  return async (dispatch, getState) => {

    //Limpa os eventos do snackbar de avisos
    state.api = {...state.api, event: '', message: ''}
    //Preenche o step como inválido, só coloca ele como válido caso a API retorne 200(success)
    state.fields = {...state.fields, stepInvalido: true}
    
    let idSessao = getState().global.tokenSessao
    let options = {
      url: '/proposta/dados-iniciais',
      method: 'POST',
      data: {
        idSessao: idSessao,
      },
    }

    await request(options)
      .then(async (response) => {
        state.fields = {...state.fields, stepInvalido: false}
      })
      .catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, stepInvalido: true}
      })
    
    await dispatch({ type: 'SET_TOKEN', state })
  }
}

export const reenviarToken = (state) => {
  return async (dispatch, getState) => {
    state.api = {...state.api, event: '', message: ''}

    let idSessao = getState().global.tokenSessao
    let options = {
      url: '/sessao/token/reenviar',
      method: 'POST',
      data: {
        idSessao: idSessao,
      },
    }

    await request(options)
      .then(response => {
        state.api = {...state.api, event: '', message: ''}
      })
      .catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }
      })

      await dispatch({ type: 'SET_API_TOKEN', api: state.api })
  }
}

/**
 * Realiza a consulta da proposta do cliente e direciona ele para a etapa em que parou ou preencheu errado
*/
export const obterRascunhoProposta = (idSessao = null) => {
  return async (dispatch, getState) => {
    let reenvio_de_proposta = true
    await dispatch({ type: 'SET_STEP', step: 3 })

    if (idSessao === null) {
      idSessao = getState().global.tokenSessao
      reenvio_de_proposta = false
    }
    
    if (reenvio_de_proposta)
      await dispatch({ type: 'SET_TOKEN_SESSAO', tokenSessao: idSessao })

    let state = getState().dadosIniciais
    
    //Limpa os eventos do snackbar de avisos
    state.api = {...state.api, event: '', message: ''}
    //Preenche o step como inválido, só coloca ele como válido caso a API retorne 200(success)
    state.fields = {...state.fields, stepInvalido: true}
    
    let options = {
      url: 'proposta/obter-proposta',
      method: 'GET',
      params: {
        idSessao: idSessao,
      },
    } 

    await request(options)
      .then(async (response) => {
        state.fields = {...state.fields, stepInvalido: false}
        let model = response.model
        let telefone = (model.dadosEmpresaRenda === null || model.dadosEmpresaRenda.dddTelefoneComercial === null) ? "" : model.dadosEmpresaRenda.dddTelefoneComercial.replace(/([^0-9])/g, '') + model.dadosEmpresaRenda.telefoneComercial.replace(/([^0-9])/g, '')
        let salario = (model.dadosEmpresaRenda === null || model.dadosEmpresaRenda.rendaPrincipal === null) ? "" : model.dadosEmpresaRenda.rendaPrincipal
        //Dados Pessoais
        if (model.dadosPessoais !== null && model.dadosPessoais.rg !== "")
          await dispatch({ type: 'SET_FIELDS_DADOS_PESSOAIS_RASCUNHO', fields: {...model.dadosPessoais, dataNascimento: formataData(model.dadosPessoais.dataNascimento), dataExpedicaoRg: formataData(model.dadosPessoais.dataExpedicaoRg), pep: Number(model.dadosPessoais.pessoaPoliticamenteExposta) }})
        //Verifica a idade do cliente, e preence se é necessário o envio do termo de emancipação ou não
        if (model.dadosPessoais !== null && model.dadosPessoais.dataNascimento !== "")
          await dispatch({ type: 'SET_TERMO_REQUIRED', termo_required: checkMaioridade(formataData(model.dadosPessoais.dataNascimento)) })
        //Endereço
        if (model.dadosEndereco !== null && model.dadosEndereco.cep !== null)
          await dispatch({ type: 'SET_FIELDS_ENDERECO', fields: model.dadosEndereco })
        //Ocupacao
        if (model.dadosEmpresaRenda !== null && model.dadosEmpresaRenda.rendaPrincipal !== 0)
          await dispatch({ type: 'SET_FIELDS_OCUPACAO', fields: {...model.dadosEmpresaRenda, telefone, salario, profissao: model.dadosEmpresaRenda.codigoProfissao} })
        //Cartao
        if (model.dadosFatura !== null && model.dadosFatura.diaVencimentoFatura !== null)
          await dispatch({ type: 'SET_FIELDS_CARTAO_RASCUNHO', fields: { vencimento: model.dadosFatura.diaVencimentoFatura, formaEnvio: model.dadosFatura.codigoMeioRecebimentoFatura, formaRecebimento: model.dadosFatura.codigoRecebimentoCartao } })
        //Documentos
        if (typeof model.listDocs !== "undefined" && model.listDocs.length > 0) {
          model.listDocs.map(async (value) => {
            //RG - CNH -RNE
            if ([1,2,7].includes(value)) {
              let documento = getTypeDoc(value)
              await dispatch({ type: 'SET_DOCUMENTO_IDENTIFICACAO', documento, value: 'preenchido' })
            }
            //Complemento
            if (value === 11)
              await dispatch({ type: 'SET_COMPLEMENTO', value: 'preenchido' })
            //Selfie
            if (value === 12)
              await dispatch({ type: 'SET_SELFIE', value: 'preenchido' })
          })
        }
  
        if (reenvio_de_proposta === false) {
          switch (model.passoAtual) {
            case "Passo1":
              await dispatch({ type: 'SET_STEP', step: 3 })
              break;
            case "Passo2":
              await dispatch({ type: 'SET_STEP', step: 4})
              break;
            case "Passo3":
              await dispatch({ type: 'SET_STEP', step: 5 })
              break;
            case "Passo4":
              await dispatch({ type: 'SET_STEP', step: 6 })
              break;
             case "Passo5":
              await dispatch({ type: 'SET_STEP', step: 6 })
              break;
            case "PassoCadastroCompleto":
              await dispatch({ type: 'SET_STEP', step: 7 })
              break;
            default:
              await dispatch({ type: 'SET_STEP', step: 3 })
              break;
          }
        }
      })
      .catch(async (error) => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }
  
            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []
  
            state.errors[key].push(error.value)
          })
  
          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }
  
          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }
  
        state.fields = {...state.fields, stepInvalido: true}
        await dispatch({ type: 'SET_STEP', step: 1 })
      })

    await dispatch({ type: 'SET_DADOS_INICIAIS', state })
  }
}

const getTypeDoc = (typeDoc) => {

  switch (typeDoc) {
    case 1:
      return "RG"
      break;
    case 2:
      return "CNH"
      break;
    case 7:
      return "RNE"
      break;
    default:
      return ""
      break;
  }

}

export const carregarDrops = (state, idSessao = null) => {
  return async (dispatch, getState) => {

  if (idSessao === null)
    idSessao = getState().global.tokenSessao

  //Limpa os eventos do snackbar de avisos
  state.api = {...state.api, event: '', message: ''}
  //Preenche o step como inválido, só coloca ele como válido caso a API retorne 200(success)
  state.fields = {...state.fields, stepInvalido: true}
  
  let options = {
    url: '/campos',
    method: 'GET',
    params: {
      idSessao: idSessao,
    },
  }

  await request(options)
    .then(async (response) => {
      state.fields = {...state.fields, stepInvalido: false}
      let drops = {
        genero: response.model.opcoesGenero,
        ufRg: response.model.uFs,
        escolaridade: response.model.opcoesEscolaridade,
        nacionalidade: response.model.opcoesNacionalidade,
      }
      await dispatch({ type: 'SET_DROPS_DADOS_PESSOAIS', drops})
      drops = {
        estado: response.model.uFs,
      }
      await dispatch({ type: 'SET_DROPS_ENDERECO', drops})
      drops = {
        profissao: response.model.opcoesOcupacao,
        patrimonio: response.model.opcoesPatrimonio,
      }
      await dispatch({ type: 'SET_DROPS_OCUPACAO', drops})
      drops = {
        vencimento: response.model.opcoesDiaFatura,
        formaEnvio: response.model.opcoesEnvioCorrespondencia,
        formaRecebimento: response.model.opcoesModoRecebimentoCartao,
      }
      await dispatch({ type: 'SET_DROPS_CARTAO', drops})
    })
    .catch(error => {
      if (typeof error.errors === 'undefined') {
        state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
      } else {
        error.errors.map(error => {
          let key = stringCamelize(error.key)
          
          if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
            state.api.message = []
            state.api.event = "warning"
            state.api.message.push(error.value)
          }

          if (Array.isArray(state.errors[key]) === false)
            state.errors[key] = []

          state.errors[key].push(error.value)
        })

        if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
          state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
          dispatch({ type: 'SET_STEP', step: 1 })
        }

        if (error.code === 500) {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        }
      }

      state.fields = {...state.fields, stepInvalido: true}
    })

    await dispatch({ type: 'SET_TOKEN', state })
  }
}

export const consultarStatus = (state) => {
  return async (dispatch, getState) => {

    let idSessao = getState().global.tokenSessao
    let nomeCliente = sessionStorage.getItem('nomeCliente')

    //Limpa os eventos do snackbar de avisos
    state.api = {...state.api, event: '', message: ''}
    //Preenche o step como inválido, só coloca ele como válido caso a API retorne 200(success)
    state.fields = {...state.fields, stepInvalido: true}
    
    let options = {
      url: '/proposta/consulta-status',
      method: 'GET',
      params: {
        idSessao: idSessao,
      },
    }

    await request(options)
      .then(response => {
        state.fields = {...state.fields, stepInvalido: false}
        
        //Caso a API retorne que existe proposta, direcionamos o cliente para a tela de consulta do status
        if (response.model.propostaExistente) {
          let data = {...response.model, nomeCliente }
          sessionStorage.setItem('dataConsultaStatus', JSON.stringify(data))
          sessionStorage.setItem('idSessao', idSessao)
          sessionStorage.removeItem('consultarStatus')
          window.location.href = 'consulta-do-status'
        }
        
      })
      .catch(async (error) => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado, não foi possível consultar sua proposta!'}
          }

        }
      })
      
      await dispatch({ type: 'SET_TOKEN', state })
      
  }
}

export const consultaExistenciaCartao = (state) => {
  return async (dispatch, getState) => {

    let idSessao = getState().global.tokenSessao

    //Limpa os eventos do snackbar de avisos
    state.api = {...state.api, event: '', message: ''}
    //Preenche o step como inválido, só coloca ele como válido caso a API retorne 200(success)
    state.fields = {...state.fields, stepInvalido: true}

    let options = {
      url: '/proposta/valida-existencia-cartao',
      method: 'GET',
      params: {
        idSessao: idSessao,
      },
    }

    await request(options)
      .then(response => {
        state.fields = {...state.fields, stepInvalido: false}
      }).catch(error => {

        if (typeof error.errors === 'undefined' || error.errors[0]?.key == 'WS-Erro-Generico') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        }

        if (error.code === 404 && error.errors[0]?.key != 'WS-Erro-Generico'){
          //Separa o nome em array e preenche com o primeiro nome informado. Ex: Antonio Carlos Teixeira, ficaria só Antonio
          let nome = getState().dadosIniciais.fields.nome.toString().split(" ")[0]
          sessionStorage.setItem('nome', stringCapitalize(nome))
          window.location.href = 'cliente-existente'
        }

        if (error.code === 500 || error.code === 400) {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        }

        state.fields = {...state.fields, stepInvalido: true}
      })

    await dispatch({ type: 'SET_TOKEN', state })
  }
}

export const setAPI = (api) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_API_TOKEN', api })
  }
}