import { loadState} from './../sessionStorage'

let resetState = {
  fields: {
    desafiosFinalizados: false,
    sortearNovoDesafio: false,
    codigoDesafio: '',
    mensagem: '',
    selfie: '',
    numTentativasDesafio: 0,
    numTotalTentativas: 0,
    fotos: [],
  },
  errors: {
    erros_api: 0,
    erros_usuario: 0,
    selfie: [],
  },
  api: {
    event: '',
    message: '',
  },
}

let initState = {
  fields: {
    desafiosFinalizados: false,
    sortearNovoDesafio: false,
    codigoDesafio: '',
    mensagem: '',
    selfie: '',
    numTentativasDesafio: 0,
    numTotalTentativas: 0,
    numDesafios: 0,
    fotos: [],
  },
  errors: {
    erros_api: 0,
    erros_usuario: 0,
    selfie: [],
  },
  api: {
    event: '',
    message: '',
  },
}

const persistedState = loadState('provaDeVida')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const ProvaDeVidaReducer = (state = initState, action) => {
  switch (action.type) {
      case 'SET_PROVA_DE_VIDA':
        return action.state
      case 'RESET_PROVA_DE_VIDA':
        return resetState
      case 'SET_CODIGO_DO_DESAFIO':
        let numDesafios = Number(state.fields.numDesafios) + 1
        let alterState = {...state, fields: {...state.fields, codigoDesafio: action.codigo, mensagem: action.mensagem, numTentativasDesafio: 0, numDesafios }, errors: {...state.errors, erros_usuario: 0, erros_api: 0} }
        return alterState
      case 'SET_API_PROVA_DE_VIDA':
        return {...state, api: action.api}
      default:
        return state;
  }
}

export default ProvaDeVidaReducer