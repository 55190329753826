const initState = {
    nome: sessionStorage.getItem('nome'),
  }
  
  const ClienteExistente = (state = initState, action) => {
    switch (action.type) {
        case 'SET_CLIENTE_EXISTENTE':
          return {...state, nome: action.nome}
        default:
            return state;
    }
  }
  
  export default ClienteExistente