import React, { Component } from 'react';
import './FAQ.css'
import { connect } from 'react-redux'
import { carregarFAQ } from '../../store/actions/globalActions'
import { Dialog, Slide, Box, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgressMaterial from '@material-ui/core/CircularProgress'

const TransitionUp = React.forwardRef((props, ref) => {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

const TransitionLeft = React.forwardRef((props, ref) => {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export class FAQ extends Component {
  state = {
    loading: false,
  }

  async componentDidMount () {

    if (this.props.faq.length === 0) {
      this.setState({loading: true})
      await this.props.carregaFAQ()
      this.setState({loading: false})
    }

  }

  htmlEncode = (id, text) => e => {
    e.preventDefault();
    document.getElementById(id).innerHTML = text
  }

  render() {
    const { open } = this.props

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.close}
        TransitionComponent={this.props.mobile ? TransitionUp : TransitionLeft}
        transitionDuration={600}
        className="dialog-fullscreen-termo"
      >
        <Box className="box">
          <CloseIcon onClick={this.props.close} className="closeIcon" aria-label="close"/>
          <div>
            <img className="logo-faq" src="images/logo.png" alt="Logo do parceiro" />
            <p className="text-logo">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
          </div>
          <div className="div-title-faq">
            <Typography className="title-page-faq div-center">
              {this.props.faq.length === 0 &&  this.state.loading === false ? 'Não encontramos nenhum FAQ' : 'Como podemos ajudar?'}
            </Typography>
          </div>
          <div className={this.props.faq.length === 0 ? "margin-top-vh3 div-expansion-panel div-center" : "margin-top-vh3 div-expansion-panel"}>
            {
              this.props.faq.length > 0 &&
              this.props.faq.map(faq => {
                return <React.Fragment>
                  <ExpansionPanel className="margin-top-vh2 expansion-panel-faq" >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"panel-content" + faq.ordem}
                      id={"panel-header" + faq.ordem}
                      onClick={this.htmlEncode("faq-"+ faq.faqLojista + '-' + faq.ordem, faq.descricaoFaq)}
                    >
                      <Typography className="title-faq">{faq.titulo}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="expansion-panel-detail" id={"faq-"+ faq.faqLojista + '-' + faq.ordem}>
                      {faq.descricaoFaq}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </React.Fragment>
              })
            }
            <br/><br/>
            {
              this.props.faq.length === 0 && this.state.loading === true &&
              <CircularProgressMaterial className="circular-progress-faq"/>
            }
          </div>
        </Box>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faq: state.faq.faq,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    carregaFAQ: () => dispatch(carregarFAQ()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);