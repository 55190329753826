import React, { Component } from 'react';
import './TermoReferencia.css'
import { connect } from 'react-redux'
import { carregarTermo } from '../../../store/actions/globalActions'
import { Dialog, Slide, Box, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgressMaterial from '@material-ui/core/CircularProgress'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class TermoReferencia extends Component {
  state = {
    dialog: false
  }

  async componentDidMount () {

    if (this.props.termo === '') {
      await this.props.carregarTermo()
    }

  }

  htmlEncode = () => {
    if (this.props.termo !== '' && document.getElementById('div-faq-dinamico') === null) {
      this.setState({})
    }
    
    if (document.getElementById('div-faq-dinamico') !== null) {
      document.getElementById('div-faq-dinamico').innerHTML = this.props.termo
    }
  }

  render() {
    const { open } = this.props

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.close}
        TransitionComponent={Transition}
        transitionDuration={600}
        className="dialog-fullscreen-termo"
      >
        <Box className="box" onLoad={this.htmlEncode()}>
          <CloseIcon onClick={this.props.close} className="closeIcon" aria-label="close"/>
          <div id="div-faq-dinamico"></div>
          {
            this.props.termo === '' &&
            <div className="div-center">
              <CircularProgressMaterial className="circular-progress-termo"/>
            </div>
          }
        </Box>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    termo: state.termo.termo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    carregarTermo: () => dispatch(carregarTermo()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermoReferencia);