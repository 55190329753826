import DadosIniciaisReducer from './dadosIniciaisReducer'
import TokenReducer from './TokenReducer'
import DadosPessoaisReducer from './DadosPessoaisReducer'
import EnderecoReducer from './EnderecoReducer'
import OcupacaoReducer from './OcupacaoReducer'
import CartaoReducer from './CartaoReducer'
import DocumentoReducer from './DocumentoReducer'
import ProvaDeVidaReducer from './ProvaDeVidaReducer'
import ClienteExistenteReducer from './ClienteExistenteReducer'
import ConsultaStatusReducer from './ConsultaStatusReducer'
import FaqReducer from './FaqReducer'
import TermoReducer from './TermoReducer'
import globalReducer from './globalReducer'
import { combineReducers } from 'redux'

let rootReducer = combineReducers({
    dadosIniciais: DadosIniciaisReducer,
    token: TokenReducer,
    dadosPessoais: DadosPessoaisReducer,
    endereco: EnderecoReducer,
    ocupacao: OcupacaoReducer,
    cartao: CartaoReducer,
    documento: DocumentoReducer,
    provaDeVida: ProvaDeVidaReducer,
    clienteExistente: ClienteExistenteReducer,
    consultaStatus: ConsultaStatusReducer,
    faq: FaqReducer,
    termo: TermoReducer,
    global: globalReducer,
})

export default rootReducer