import React, { Component } from 'react'
import './Boas-Vindas.css'
import { Link, Typography, Box, Button } from '@material-ui/core'
import FAQ from './../../FAQ/FAQ';

export class BoasVindas extends Component {

  state = {
    faq: false,
  }
  
  consultarStatus = async e => {
    e.preventDefault()

    //Flega que o cliente clicou em consultar status
    sessionStorage.setItem('consultarStatus', true);
    window.location.href = 'cadastro'

  }

  solicitarCartao = async e => {
    e.preventDefault()

    //Remove a flag, informando que o usuário deseja ir ao wizard
    sessionStorage.removeItem('consultarStatus')
    window.location.href = 'início'
  }

  render() {
    const { faq } = this.state
    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />
    
    return (
      <React.Fragment>
        <link rel="stylesheet" href="css/cores.css" />
        <Box className="box">
          <div className="margin-0 margin-top-vh3">
            <img className="Logo" src="images/logo.png" alt="Logo do parceiro" />
            <p className="text-logo">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
          </div>
          <div className={"text-right div-faq"} onClick={() => this.setState({faq: true})}>
            <a className="link-faq">Precisa de ajuda?</a>
          </div>
          <div className="div-60 div-faq-boas-vindas">
            <Typography className="title" >
              Olá!
            </Typography>
          </div>
          <div className="div-58 margin-top-vh2">
            <Typography className="sub-title" >
              O que deseja fazer hoje?
            </Typography>
          </div>
          <div className="div-center margin-top-vh10">
            <img className="img-bem-vindo" src="/images/bem-vindo.png" alt="Bem-vindo"/>
          </div>
          <div className="divButtons margin-top-vh10">
            <Button fullWidth variant="contained" className="buttonNext" onClick={this.solicitarCartao}>Solicitar cartão</Button>
            <Link fullWidth component="button" variant="body2" className="link-status margin-top-15" onClick={this.consultarStatus}>Consultar status</Link>
          </div>
          <br/>
          {faq ? dialogFAQ : ''}
        </Box>
      </React.Fragment>
    );
  }
}

export default BoasVindas;