import React, { Component } from 'react'
import './Onboarding.css'
import { Link, Typography, Box, Button } from '@material-ui/core'
  
export class Onboarding extends Component {
  render() {
    
    return (
      <React.Fragment>
        <div className="div-left margin-top-vh3">
          <img className="logo-onboarding margin-left-vw5" src="images/logo.png" alt="Logo do parceiro" />
          <p className="text-logo text-left margin-left-vw5">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
        </div>
        <Box className="divFlex div-center">
          <Box className="flex-3 div-center box-etapa">
            <div>
              <Typography className="title-onboarding" >
                Bem-vindo!
              </Typography>
            </div>
            <img className="img-etapa-1 margin-top-vh5" src="/images/etapa1.png" alt="Bem-Vindo" />
            <div className="margin-top-vh3">
              <Typography className="text-onboarding" >
                Vamos começar seu cadastro e para isso precisaremos de algumas informações importantes para a criação do seu cartão :)  
              </Typography>
            </div>
          </Box>
          <Box className="flex-3 div-center box-etapa">
            <div>
              <Typography className="title-onboarding" >
                Simples e rápido :) 
              </Typography>
            </div>
            <img className="img-etapa-2 margin-top-vh5" src="/images/etapa2.png" alt="Simples e rápido :)" />
            <div className="margin-top-vh3">
              <Typography className="text-onboarding" >
                Mas não se preocupe, deixamos o processo o mais amigável possível para sua comodidade, prometemos que não demora mais de 2 minutinhos.
              </Typography>
            </div>
          </Box>
          <Box className="flex-3 div-center box-etapa">
            <div>
              <Typography className="title-onboarding" >
                Bora! 
              </Typography>
            </div>
            <img className="img-etapa-3 margin-top-vh5" src="/images/etapa3.png" alt="Bora" />
            <div className="margin-top-vh3">
              <Typography className="text-onboarding" >
                Então vamos lá, você está muito perto de ter seu novo cartão de credito! :D 
              </Typography>
            </div>
          </Box>
        </Box>
        <div className="divButtons margin-top-vh10 div-center">
          <Button variant="contained" className="buttonNext" href="cadastro">Começar</Button>
        </div>
      </React.Fragment>
    );
  }
}

export default Onboarding;