import React, { Component } from 'react'
import './Fim-Biometria.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Typography, Box, Button } from '@material-ui/core'
  
export class FimBiometria extends Component {
  
  render() {

    const parceiro = sessionStorage.getItem("parceiro");
    const href = `${parceiro}/bem-vindo`;

    return (
      <React.Fragment>
          <Box className="box ballons">
            <div className="div-center margin-0 margin-top-vh2">
              <Typography className="title-fim primary-color" >
                Muito Obrigada...
              </Typography>
            </div>
            <div className="div-center margin-top-vh2">
              <Typography className="text-fim" >
                Já recebemos sua proposta e analisaremos o seu pedido.
              </Typography>
              <Typography className="text-fim" >
                Você será avisado assim que terminarmos.
              </Typography>
            </div>
            <div className="div-center">
              <img className="img-home-center img-fim" src="/images/fim.png" />
            </div>
            <div className="div-center margin-top-vh1">
              <Typography className="text-fim" >
                Agora é só aguardar! :)
              </Typography>
            </div>
            <div className="divButtons margin-top-vh2 buttons-fim-wizard">
              <Button href={href} fullWidth variant="outlined" className="button-back"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
            <br/>
          </Box>
      </React.Fragment>
    );
  }
}

export default FimBiometria;