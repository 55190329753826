import React, { Component } from 'react'
import './ConsultaStatus.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { stringCapitalize } from './../../../common/validators'
import { Typography, Box, Button } from '@material-ui/core'
import { connect } from 'react-redux'
  
export class ConsultaStatus extends Component {

  reenviarProposta = () => e => {
    e.preventDefault()

    sessionStorage.setItem('reenvio_da_proposta', true);

    window.location.href = 'cadastro'
  }

  getDescription = (situacao) => {
    switch(situacao)
    {
      case "processo":
        return (
          <React.Fragment>
            <div>
              <Typography className="title">
                  Em análise
              </Typography>
            </div>
            <div className="div-90 margin-top-vh2">
              <Typography className="text-info">
                Olá {this.props.nome}, seu cadastro está em análise.
              </Typography>
            </div>
            <div className="margin-top-vh2">
              <Typography className="text-info">
                Fique ligado(a)! Em breve você terá novidades sobre o seu cartão.
              </Typography>
            </div>
          </React.Fragment>
        )
      case "reprovada":
        return (
          <React.Fragment>
            <div>
              <Typography className="title">
                  Não aprovado
              </Typography>
            </div>
            <div className="div-90 margin-top-vh2">
                <Typography className="text-info" >
                  Olá {this.props.nome}, dessa vez não foi possível aprovar o seu cartão.
                </Typography>
            </div>
            <div className="div-90">
                <Typography className="text-info" >
                  Mas daqui {this.props.diasParaNovaProposta} dias você pode realizar uma nova solicitação!
                </Typography>
            </div>
          </React.Fragment>
        )
      case "pendente":
        return (
          <React.Fragment>
            <div>
              <Typography className="title">
                  Pendente
              </Typography>
            </div>
            <div className="div-90 margin-top-vh2">
                <Typography className="text-info" >
                  Olá {this.props.nome}, falta pouco!
                </Typography>
            </div>
            <div className="div-90 margin-top-vh2">
                <Typography className="text-info" >
                  Sua proposta está pendente, pois identificamos algumas informações incompletas/incorretas, como:
                </Typography>
            </div>
          </React.Fragment>
        )
      case "aprovada":
        if (this.props.recebimentoCartao !== 2)
        {
          return (
            <React.Fragment>
              <div>
                <Typography className="title">
                    Aprovado
                </Typography>
              </div>
              <div className="div-90 margin-top-vh2">
                <Typography className="text-info">
                  Olá {this.props.nome}, seu cadastro foi aprovado! :D
                </Typography>
              </div>
              <div className="div-90">
                <Typography className="text-info">
                  Agora basta ir até a loja mais próxima retirar seu cartão.
                </Typography>
              </div>
              <div className="div-90">
                <Typography className="text-info">
                  Ah, não esqueça de levar um documento com foto.
                </Typography>
              </div>
            </React.Fragment>
          )
        }
        else{
          return(
            <React.Fragment>
              <div>
                <Typography className="title">
                    Aprovado
                </Typography>
              </div>
              <div className="div-90 margin-top-vh2">
                <Typography className="text-info">
                  Olá {this.props.nome}, seu cadastro foi aprovado! :D
                </Typography>
              </div>
              <div className="div-90">
                <Typography className="text-info">
                  Em até 20 dias você receberá o cartão em seu endereço.
                </Typography>
              </div>
              <div className="div-90">
                <Typography className="text-info">
                  Aguarde!
                </Typography>
              </div>
            </React.Fragment>
          )
        }
      default:
        return (
          <React.Fragment>
            <div className="div-90 margin-top-vh2">
                <Typography className="text-info" >
                  Segue abaixo o descritivo de como sua proposta se encontra conosco.
                </Typography>
            </div>
          </React.Fragment>
        )
    }
  }

  getNameImg = (situacao) => {
    switch(situacao)
    {
      case "reprovada":
        return "reprovada"
      case "pendente":
        return "pendente"
      case "aprovada":
        return "aprovada"
      default:
        return "aprovada"
    }
  } 

  getFooter = (situacao) => {
    switch(situacao)
    {
      case "processo":
        return (
          <React.Fragment>
            <div className="divButtons margin-top-vh5">
              <Button fullWidth href="bem-vindo" fullWidth variant="outlined" className="buttonBack"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
          </React.Fragment>
        )
      case "reprovada":
        return (
          <React.Fragment>
            <div className="div-center-no-margin">
              <Typography className="text-info" >
                Ficou com dúvidas?
              </Typography>
            </div>
            <div className="div-center-no-margin">
              <Typography className="text-info" >
                Entre em contato conosco!
              </Typography>
            </div>
            <div className="div-center-no-margin margin-top-vh2">
                <a href="https://www.senff.com.br/institucional/fale-conosco/" className="link-internet-banking">Fale com a Senff</a>
            </div>
            <div className="divButtons margin-top-vh5">
              <Button fullWidth href="bem-vindo" fullWidth variant="outlined" className="buttonBack"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
          </React.Fragment>
        )
      case "pendente":
        return (
          <React.Fragment>
            <div className="div-center-no-margin">
              <Typography className="text-info" >
                Mas fique tranquilo(a)!
              </Typography>
            </div>
            <div className="div-center-no-margin">
              <Typography className="text-info" >
                Basta corrigir os itens e reenviar a proposta! 
              </Typography>
            </div>
            <div className="div-center-no-margin">
              <Typography className="text-info" >
                Vamos lá? 
              </Typography>
            </div>
            <div className="divButtons margin-top-vh2">
              <Button fullWidth variant="outlined" className="buttonBack" onClick={this.reenviarProposta()}><ArrowBackIcon/> Reenviar proposta</Button>
            </div>
            <div className="divButtons margin-top-vh2">
              <Button fullWidth href="bem-vindo" fullWidth variant="outlined" className="buttonBack"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
            <br/>
          </React.Fragment>
        )
      case "aprovada":
        return (
          <React.Fragment>
            <div className="div-center">
              <Typography className="text-info" >
                É muito bom ter você como cliente!
              </Typography>
            </div>
            <div className="divButtons margin-top-vh5">
              <Button fullWidth href="bem-vindo" fullWidth variant="outlined" className="buttonBack"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
          </React.Fragment>
        )
      default:
        return (
          <React.Fragment>
            <div className="div-center-no-margin">
              <Typography className="text-info" >
                Ficou com dúvidas?
              </Typography>
            </div>
            <div className="div-center-no-margin">
              <Typography className="text-info" >
                Entre em contato conosco!
              </Typography>
            </div>
            <div className="div-center-no-margin margin-top-vh2">
                <a href="https://www.senff.com.br/institucional/fale-conosco/" className="link-internet-banking">Fale com a Senff</a>
            </div>
            <div className="divButtons margin-top-vh5">
              <Button fullWidth href="bem-vindo" fullWidth variant="outlined" className="buttonBack"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
          </React.Fragment>
        )
    }
  }

  /**
   * Remove os dados do cliente da session storage e caso o mesmo já esteja nulo, redireciona o usuário para a tela inicial.
   */
  removeNomeCliente = () => {

    if (sessionStorage.getItem('dataConsultaStatus') === null)
      window.location.href = 'bem-vindo'

    sessionStorage.removeItem('dataConsultaStatus')
  }

  render() {
    
    //Ira remover os dados do cliente da session storare.
    this.removeNomeCliente()

    return (
      <React.Fragment>
          <link rel="stylesheet" href="css/cores.css" />
          <Box className="box">
            <div className="margin-0">
                <img className="Logo" src="images/logo.png" alt="Logo do parceiro" />
                <p className="text-logo">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
            </div>
            {
              this.getDescription(this.props.situacao)
            }            
            {
              this.props.situacao !== "reprovada" && this.props.motivosPendencia.length > 0 &&
              <div className="div-90 margin-top-vh1">
                <Typography className="text-situacao" >
                    Motivos da pendência:
                </Typography>
                <ul>
                {
                  this.props.motivosPendencia.map(pendencia => {
                    return <li className="text-info">{pendencia.motivo}</li>
                  })
                }
                </ul>
              </div>
            }
            <div className="div-center-no-margin margin-top-vh3">
                <img className={"img-center-" + this.getNameImg(this.props.situacao)} src={"/images/" + this.getNameImg(this.props.situacao) + ".png"} />
            </div>
            {
              this.getFooter(this.props.situacao)
            }
          </Box>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {

    let dataConsultaStatus = sessionStorage.getItem('dataConsultaStatus')

    if (dataConsultaStatus === null)
      return {}

    dataConsultaStatus = JSON.parse(dataConsultaStatus)

    let nome = dataConsultaStatus.nomeCliente.toString().split(" ")[0]
    return {
      nome: stringCapitalize(nome),
      situacao: dataConsultaStatus.situacao.toString().toLowerCase(),
      motivosPendencia: dataConsultaStatus.motivosReprovacaoPendencia,
      diasParaNovaProposta: dataConsultaStatus.diasParaNovaProposta,
      recebimentoCartao: dataConsultaStatus.recebimentoCartao,
    }
}
 
export default connect(mapStateToProps)(ConsultaStatus);