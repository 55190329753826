import request from './../../common/http-client'

export const setIdSessao = (token) => {
    return (dispatch, getStete) => {
        dispatch({ type: 'SET_TOKEN_SESSAO', token })
    }
}

export const setStep = (step) => {
    return (dispatch, getStete) => {
        //call your database
        dispatch({ type: 'SET_STEP', step })
    }
}

export const setIdLojista = (idLojista) => {
    return (dispatch, getStete) => {
        //call your database
        dispatch({ type: 'SET_ID_LOJISTA', idLojista })
    }
}

export const consultarStatus = (idLojista) => {
    return (dispatch, getStete) => {
        //call your database
        dispatch({ type: 'CONSULTAR_STATUS' })
    }
}

export const setCores = (cores) => {
    return (dispatch, getStete) => {
        //call your database
        dispatch({ type: 'SET_CORES', cores })
    }
}

export const carregarFAQ = () => {
    return async (dispatch, getState) => {

        let idLojista = sessionStorage.getItem('idLojista')
        idLojista = (idLojista !== null && idLojista !== "0") ? idLojista : process.env.REACT_APP_API_ID_LOJISTA

        let options = {
            url: '/faq',
            method: 'GET',
            params: {
                idLojista
            },
        }
        
        await request(options)
            .then(async (response) => {
                await dispatch({ type: 'SET_FAQ', state: {faq: response.model} })
            })
        
    }
}

export const carregarTermo = () => {
    return async (dispatch, getState) => {

        let idLojista = sessionStorage.getItem('idLojista')
        idLojista = (idLojista !== null && idLojista !== "0") ? idLojista : process.env.REACT_APP_API_ID_LOJISTA

        let options = {
            url: '/parametrizacao',
            method: 'GET',
            params: {
                idLojista
            },
        }
        
        await request(options)
            .then(async (response) => {
                await dispatch({ type: 'SET_TERMO', state: {termo: response.model.termosDeUso} })
            })
        
    }
}

export const resetState = () => {
    return async (dispatch, getState) => {
        await dispatch({ type: 'RESET_DADOS_PESSOAIS'})
        await dispatch({ type: 'RESET_ENDERECO'})
        await dispatch({ type: 'RESET_OCUPACAO'})
        await dispatch({ type: 'RESET_CARTAO'})
        await dispatch({ type: 'RESET_DOCUMENTO'})
    }
}