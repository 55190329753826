const initState = {
  nome: '',
  situacao: '',
  motivosPendencia: [
  ],
}

const ConsultaStatus = (state = initState, action) => {
  switch (action.type) {
      case 'SET_CLIENTE_EXISTENTE':
        return {...state, nome: action.nome, nomeLojista: action.nomeLojista}
      default:
          return state;
  }
}

export default ConsultaStatus