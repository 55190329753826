import { loadState} from './../sessionStorage'

let initState = {
  faq: [],
}

const persistedState = loadState('faq')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const FaqReducer = (state = initState, action) => {
  switch (action.type) {
      case 'SET_FAQ':
        return action.state;
      default: 
        return state;
  }
}

export default FaqReducer