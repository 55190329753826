import React, { Component } from 'react'
import './InputSelectAutoComplete.css'
import { InputLabel, TextField, FormHelperText } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export class InputSelectAutoComplete extends Component {

  render() {
    const { value, error, options, disabled, label, field, onChange, onBlur, onKeyDown, required } = this.props

    return (
      <div>
        <InputLabel tabIndex={false} className={"label"} htmlFor={field}>{label}</InputLabel>
        <Autocomplete
          id={field}
          options={options}
          color="secondary"
          disabled={disabled}
          getOptionLabel={option => option.valor}
          autoComplete="off"
          defaultValue={typeof options === "undefined" ? "" : options[options.indexOf(options.find(o => o.valor === value))]}
          renderInput={params => {
            return <TextField autoComplete="off" color="secondary" inputProps={{"aria-autocomplete": "nope", "autocomplete": "off"}} {...params} onBlur={onBlur} onChange={onChange} className={"textField"} fullWidth error={error.length > 0} />
          }}
        />
        <div  className={"input-helper textDanger"}>
          {
            Array.isArray(error) === true && error.length > 0 &&
              <ul className="ul-padding-20">
                {
                  error.map((erro, index) => {
                    return <li key={index}>{erro}</li>
                  })
                }
              </ul>
          }
        </div>
      </div>
    );
  }
}

export default InputSelectAutoComplete;