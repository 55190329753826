import React, { Component } from 'react';
import './Snackbar.css'
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export class Error extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      variant: this.props.variant,
      message: this.props.message,
    }
    
  }

  render() {
    const { open, variant, message } = this.state

    const MySnackbarContentWrapper = (props) => {
      const { message, onClose, variant} = props

      const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
      }
      const Icon = variantIcon[variant]

      return (
        <SnackbarContent
          className={"snackbar-"+variant}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className="message max-width-65">
              <Icon className={"icon icon"+variant} />
              {
                Array.isArray(message) && 
                  <ul className="ul-margin-0 ul-padding-20">
                  {
                    message.map((msg, i) => {
                      return <li key={i}>{msg}</li>
                    })
                  }
                  </ul>
                ||
                message
              }
            </span>
          }
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon className="" />
            </IconButton>,
          ]}
        />
      );
    }

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          onClose={this.props.onClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.onClose}
            variant={typeof variant === "undefined" ? "warning" : variant}
            message={typeof message === "undefined" ? "Ocorreu um erro inesperado !" : message}
          />
        </Snackbar>
      </div>
    );
  }
}

export default Error