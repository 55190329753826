import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import withSizes from 'react-sizes'


//Layout's mobile
import BoasVindas from './../containers/Mobile/Boas-Vindas/Boas-Vindas'
import Home from './../containers/Mobile/Home/Home'
import Onboarding from './../containers/Mobile/Onboarding/Onboarding'
import Wizard from './../containers/Wizard/Wizard'

//Layout's desktop
import BoasVindasDesktop from './../containers/Desktop/Boas-Vindas/Boas-Vindas'
import OnboardingDesktop from './../containers/Desktop/Onboarding/Onboarding'
import WizardDesktop from './../containers/Desktop/Wizard/Wizard'
import FimWizardDesktop from './../containers/Desktop/Fim-Wizard/Fim-Wizard'
import FimBiometriaDesktop from './../containers/Desktop/Fim-Biometria/Fim-Biometria'
import ConsultaStatusDesktop from './../containers/Desktop/ConsultaStatus/ConsultaStatus'
import ClienteExistenteDesktop from './../containers/Desktop/ClienteExistente/ClienteExistente'
import NotFoundDesktop from './../containers/Desktop/NotFound/NotFoundDesktop'

//Layout's não definidos
import FimWizard from './../containers/Mobile/Fim-Wizard/Fim-Wizard'
import FimBiometria from './../containers/Mobile/Fim-Biometria/Fim-Biometria'
import ClienteExistente from './../containers/Mobile/ClienteExistente/ClienteExistente'
import ConsultaStatus from './../containers/Mobile/ConsultaStatus/ConsultaStatus'
import NotFound from './../containers/Mobile/NotFound/NotFound'

export class RouterMain extends Component {
  render() {
    return process.env.NODE_ENV === 'production' ?
     (
      <BrowserRouter>
        <Switch>
          <Route exact path="*/bem-vindo" component={this.props.isDesktop ? BoasVindasDesktop : BoasVindas} />
          <Route exact path="*/início" component={Home} />
          <Route exact path="*/tutorial" component={this.props.isDesktop ? BoasVindasDesktop : Onboarding} />
          <Route exact path="*/cadastro" component={this.props.isDesktop ? WizardDesktop : Wizard} />
          <Route exact path="*/fim" component={this.props.isDesktop ? FimWizardDesktop : FimWizard} />
          <Route exact path="*/fim-biometria" component={this.props.isDesktop ? FimBiometriaDesktop : FimBiometria} />
          <Route exact path="*/consulta-do-status" component={this.props.isDesktop ? ConsultaStatusDesktop : ConsultaStatus} />
          <Route exact path="*/cliente-existente" component={this.props.isDesktop ? ClienteExistenteDesktop : ClienteExistente} />
          <Route exact path="*/pagina-nao-encontrada" component={this.props.isDesktop ? NotFoundDesktop : NotFound} />
        </Switch>
      </BrowserRouter>
    )
    :
    (
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to="/bem-vindo" />
          <Route exact path="*/bem-vindo" component={this.props.isDesktop ? BoasVindasDesktop : BoasVindas} />
          <Route exact path="*/início" component={Home} />
          <Route exact path="*/tutorial" component={this.props.isDesktop ? BoasVindasDesktop : Onboarding} />
          <Route exact path="*/cadastro" component={this.props.isDesktop ? WizardDesktop : Wizard} />
          <Route exact path="*/fim" component={this.props.isDesktop ? FimWizardDesktop : FimWizard} />
          <Route exact path="*/fim-biometria" component={this.props.isDesktop ? FimBiometriaDesktop : FimBiometria} />
          <Route exact path="*/consulta-do-status" component={this.props.isDesktop ? ConsultaStatusDesktop : ConsultaStatus} />
          <Route exact path="*/cliente-existente" component={this.props.isDesktop ? ClienteExistenteDesktop : ClienteExistente} />
          <Route exact path="*/pagina-nao-encontrada" component={this.props.isDesktop ? NotFoundDesktop : NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isDesktop: width > 900,
})
 
export default withSizes(mapSizesToProps)(RouterMain)