export const validaCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, '')
    let valido = true;
    
    let soma = 0;
    let resto;
    let i = 0;

    if(cpf === '00000000000') {
      valido = false;
    } 
    for(i=1; i<=9; i++) {
      soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
    } 
    resto = (soma * 10) % 11;

    if((resto === 10) || (resto === 11)) {
      resto = 0;
    }
    if(resto !== parseInt(cpf.substring(9, 10))) {
      valido =  false;
    } 

    soma = 0;
    for(i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i-1, i))*(12-i);
    }

    resto = (soma * 10) % 11;

    if((resto === 10) || (resto === 11)) {
      resto = 0;
    } 
    if(resto !== parseInt(cpf.substring(10, 11))) {
      valido =  false;
    }

    return valido   
}

export const checkMaioridade = (data) => {
  data = data.replace(/\//g, "-"); // substitui eventuais barras (ex. IE) "/" por hífen "-"
  var data_array = data.split("-"); // quebra a data em array
  
  // para o IE onde será inserido no formato dd/MM/yyyy
  if(data_array[0].length != 4){
     data = data_array[2]+"-"+data_array[1]+"-"+data_array[0]; // remonto a data no formato yyyy/MM/dd
  }

  if (data_array[1] == 2 && Number(data_array[0]) > 29)
    return false;
  
  // comparo as datas e calculo a idade
  var hoje = new Date();
  var date  = new Date(data);

  return ((hoje.getTime() - date.getTime()) / (1000 * 3600 * 24) / 365) <= 18
}

export const validaCelular = (celular) => {
  celular = celular.replace(/\D/g, '')

  if (celular == '0000000000') {
    return false
  } else if (celular == '00000000000') {
    return false
  } 
  if (["00", "01", "02", "03", , "04", , "05", , "06", , "07", , "08", "09", "10"].indexOf(celular.substring(0, 2)) != -1) {
    return false
  }
  if (celular.length !== 11) {
    return false
  }
  if (["9"].indexOf(celular.substring(2, 3)) == -1) {
    return false
  }

  return true
}

export const validaEmail = (email) => {

  let atpos = email.indexOf("@")
  let dotpos = email.lastIndexOf('.')
  if(atpos < 1 ||  dotpos < (atpos+2) || (dotpos+2) >=email.length)
    return false

  let reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

  return reg.test(email)
}

export const validaData = (data) => {
  data = data.replace(/\//g, "-"); // substitui eventuais barras (ex. IE) "/" por hífen "-"
  var data_array = data.split("-"); // quebra a data em array
  
  if (data_array.length < 3)
    return false

  if (Number(data_array[2]) < 1900)
    return false
  
  // para o IE onde será inserido no formato dd/MM/yyyy
  if(data_array[0].length != 4){
     data = data_array[2]+"-"+data_array[1]+"-"+data_array[0]; // remonto a data no formato yyyy/MM/dd
  }

  if (data_array[1] == 2 && Number(data_array[0]) > 29)
    return false;
  
  // comparo as datas e calculo a idade
  var hoje = new Date();
  var date  = new Date(data);

  if (isNaN(date.getTime()))
    return false
  
  if (date.getFullYear() > hoje.getFullYear())
    return false

  return true
}

export const validaDataDeNascimento = (data) => {
  data = data.replace(/\//g, "-"); // substitui eventuais barras (ex. IE) "/" por hífen "-"
  var data_array = data.split("-"); // quebra a data em array

  if (data_array.length < 3)
    return false
  
  if (Number(data_array[2]) < 1900)
    return false

  // para o IE onde será inserido no formato dd/MM/yyyy
  if(data_array[0].length != 4){
     data = data_array[2]+"-"+data_array[1]+"-"+data_array[0]; // remonto a data no formato yyyy/MM/dd
  }

  if (data_array[1] == 2 && Number(data_array[0]) > 29)
    return false;

  if (data_array[2].length < 4)
    return false
  
  // comparo as datas e calculo a idade
  var hoje = new Date();
  var nasc  = new Date(data);

  if (isNaN(nasc.getTime()))
    return false
  
  var idade = hoje.getFullYear() - nasc.getFullYear();
  var m = hoje.getMonth() - nasc.getMonth();
  if (m < 0 || (m === 0 && hoje.getDate() < nasc.getDate())) idade--;
  
  if(idade < 16)
    return false;

  // se for maior que 60 não vai acontecer nada!
  return true;
}

export const validaNomeCompleto = (nome) => {
  return !!nome.trim().match(/[a-z]* [a-z]*/)
}

export const checkErrors = (errors) => {
  let count = 0
  
  Object.entries(errors).map((error, key) => {
    if (error[1].length > 0) {
      count++
    }
  })

  if (count > 0)
    return true
  
  return false
}

/**
 * Adapters 
*/

export const stringCapitalize = (str) => {
  str = str.split(" ")

  if (str === null || str.length === 0)
    return ""

  try {
    for (var i = 0, x = str.length; i < x; i++) {
      if (str[i].toString().trim() !== "") {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase()
      }
    }
  } catch (error) {
    return "";
  }

  return str.join(" ")
}

export const stringCamelize = (str) => {
  if (str === null || str.length === 0)
    return str

  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index == 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const dateToISO = (data) => {
  data = data.replace(/\//g, "-"); // substitui eventuais barras (ex. IE) "/" por hífen "-"
  var data_array = data.split("-"); // quebra a data em array
  
  // para o IE onde será inserido no formato dd/MM/yyyy
  if(data_array[0].length != 4){
     data = data_array[2]+"-"+data_array[1]+"-"+data_array[0]; // remonto a data no formato yyyy/MM/dd
  }
  
  // comparo as datas e calculo a idade
  var date  = new Date(data);

  return date.toISOString()
}

export const formataData = (data) => {
  let arrayData
  
  data = data.split("T")[0]
  
  if (data.length !== 10)
    return ""
  
  arrayData = data.split("-")

  if (arrayData.length !== 3)
    return ""

  return arrayData[2] + "/" + arrayData[1] + "/" + arrayData[0]
}

export const onTouch = (el, callback) => {
 
    var touchsurface = el,
    dir,
    swipeType,
    startX,
    startY,
    distX,
    distY,
    dist = 0,
    threshold = 150, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 500, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handletouch = callback || function(evt, dir, phase, swipetype, distance){}
 
    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        dir = 'none'
        swipeType = 'none'
        dist = 0
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
        handletouch(e, 'none', 'start', swipeType, 0) // fire callback function with params dir="none", phase="start", swipetype="none" etc
        //e.preventDefault()
 
    }, false)
 
    touchsurface.addEventListener('touchmove', function(e){
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        if (Math.abs(distX) > Math.abs(distY)){ // if distance traveled horizontally is greater than vertically, consider this a horizontal movement
            dir = (distX < 0)? 'left' : 'right'
            handletouch(e, dir, 'move', swipeType, distX) // fire callback function with params dir="left|right", phase="move", swipetype="none" etc
        }
        else{ // else consider this a vertical movement
            dir = (distY < 0)? 'up' : 'down'
            handletouch(e, dir, 'move', swipeType, distY) // fire callback function with params dir="up|down", phase="move", swipetype="none" etc
        }
        //e.preventDefault() // prevent scrolling when inside DIV
    }, false)
 
    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipeType = dir // set swipeType to either "left" or "right"
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipeType = dir // set swipeType to either "top" or "down"
            }
        }
        // Fire callback function with params dir="left|right|up|down", phase="end", swipetype=dir etc:
        handletouch(e, dir, 'end', swipeType, (dir =='left' || dir =='right')? distX : distY)
        //e.preventDefault()
    }, false)
}