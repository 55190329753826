import { loadState} from './../sessionStorage'

let initState = {
    fieldsRequired: ['nome', 'cpf', 'celular'],
    fields: {
        stepInvalido: true,
        nome: '',
        cpf: '',
        celular: '',
    },
    errors: {
        nome: [],
        cpf: [],
        celular: [],        
    },
    api: {
        event: '',
        message: '',
    },
    drops: {

    }
}

const dadosIniciaisReducer = (state = initState, action) => {
    switch (action.type) {
        case 'RESET_DADOS_INICIAIS':
            return {...state, fields: { stepInvalido: false, nome: '', cpf: '', celular: '' } }
        case 'SET_DADOS_INICIAIS':
            return {...state, fields: { stepInvalido: false, nome: action.state.fields.nome, cpf: action.state.fields.cpf, celular: action.state.fields.celular } }
        case 'SET_API_DADOS_INICIAIS':
            return {...state, api: action.api}
        default:
            return state;
    }
}

export default dadosIniciaisReducer