import React, { Component } from 'react'
import './InputMask.css'
import MaskedInput from 'react-text-mask';
import { InputLabel, TextField } from '@material-ui/core'

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      guide={false}
      ref={inputRef}
      mask={props.mask}
    />
  );
}

export class InputMask extends Component {

  render() {
    const { value, error, label, mask, type, field, onBlur, onChange, onKeyDown, required, placeholder, autoFocus } = this.props

    return (
      <div>
        <InputLabel  className={"label"} htmlFor={field}>{label}</InputLabel>
        <TextField
          id={field}
          type={type}
          autoComplete="off"
          className={"textField"}
          color="secondary"
          InputProps={{
            inputComponent: TextMaskCustom,
            inputProps: {mask: mask},
            value: value,
            autoComplete: "off",
            "aria-autocomplete": "nope",
            onChange: onChange,
            onKeyDown: onKeyDown,
            onBlur: onBlur,
          }}
        />
        <div  className={"input-helper textDanger"}>
          {
            Array.isArray(error) === true && error.length > 0 &&
              <ul className="ul-padding-20">
                {
                  error.map((erro, index) => {
                    return <li key={index}>{erro}</li>
                  })
                }
              </ul>
          }
        </div>
      </div>
    );
  }
}

export default InputMask;