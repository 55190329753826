import React from 'react'
import './App.css'
import RouterMain from './../../routes/RouterMain'
import packageJson from "./../../../package.json";
import { getBuildDate } from "./../../common/util"
import withClearCache from "./../../ClearCache"

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />
}

function MainApp(props) {
  return (
    <div className="App">
      <p id="versao" className="hide">Build date: {getBuildDate(packageJson.buildDate)}</p>
      <RouterMain />
    </div>
  );
}

export default App
