import React, { Component } from 'react'
import './DadosPessoais.css'
import HeaderStepper from './../../../components/HeaderStepper/HeaderStepper'
import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import InputText from '../../../components/InputText/InputText'
import InputMask from '../../../components/InputMask/InputMask'
import InputSelect from '../../../components/InputSelect/InputSelect'
import FAQ from './../../FAQ/FAQ'
import InputSelectAutoComplete from '../../../components/InputSelectAutoComplete/InputSelectAutoComplete'
import { connect } from 'react-redux'
import { setDadosPessoais, setAPI } from '../../../store/actions/DadosPessoaisActions'
import { validaEmail, validaDataDeNascimento, validaData, validaNomeCompleto } from '../../../common/validators'
import Snackbar from '../../../components/Snackbar/Snackbar'
import DialogPermissaoCamera from '../../DialogPermissaoCamera/DialogPermissaoCamera'

export class DadosPessoais extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fieldsRequired: this.props.fieldsRequired,
      fields: this.props.fields,
      errors: this.props.errors,
      drops: this.props.drops,
      api: this.props.api,
      dialogPEP: false,
      faq: false,
      dialog_permissao_camera: false
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    document.getElementById('pep').options.remove(0)
    this.verificarPermissaoCamera()
  }

  verificarPermissaoCamera = async () => {
    
    let self = this

    navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia);

    if (typeof navigator.getMedia === 'function') {
      navigator.getMedia({ video: true }, async function () {
      }, async function () {
        self.setState({ dialog_permissao_camera: true })
      });
    }

    var constraints = { video: true, audio: false };

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => function () {
        
      })
      .catch(e => function () {
        self.setState({ dialog_permissao_camera: true })
      });

  }

  prev = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.props.setAPI(api)
    await this.setState({api: api})
  }

  changeText = (field) => e => {

    if (e.target.id === 'nomePai' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z]|[\d])/g, '')
      this.state.fields[field] = e.target.value
    }

    if (e.target.id === 'nomeMae' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z]|[\d])/g, '')
      this.state.fields[field] = e.target.value
    }

    if (e.target.id === 'orgaoEmissor' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z]|[\d])/g, '')
      this.state.fields[field] = e.target.value
    }
    
    if (e.target.id === 'nacionalidadeCidade' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z]|[\d])/g, '')
      this.state.fields[field] = e.target.value
    }

    if (e.target.id === 'nacionalidade' && e.target.value !== '') {
      let index = this.state.fieldsRequired.indexOf('nacionalidadeEstado')
      let drops = this.state.drops
      //Chave 1 é a nacionalidade brasileira, onde devemos habilitar o campo nacionalidadeEstado
      /**
       * Regras:
        - Se informar BRASILEIRA: ABRE COMBO COM "Estado" e a "Cidade", mas CIDADE será de livre digitação;

        - Se informar NATURALIZADO: Abre livre digitação apenas CIDADE (estado ocultar)

        - Se informar ESTRANGEIRA: Abre livre digitação apenas CIDADE (estado ocultar)
       */
      let nacionalidadeEstadoRequired = typeof drops.nacionalidade.find(x => x.chave === Number(e.target.value)) !== "undefined" && drops.nacionalidade.find(x => x.chave === Number(e.target.value)).chave === 1
      if (index === -1 && nacionalidadeEstadoRequired) {
        this.state.fieldsRequired.push('nacionalidadeEstado')
      }
      if (index !== -1 && nacionalidadeEstadoRequired === false) {
        this.state.fieldsRequired.splice(index, 1)
      }
    }

    this.state.fields[field] = e.target.value

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  continue = async e => {
    e.preventDefault()
    this.props.changeLoading()
    this.state.fields.stepInvalido = true

    await this.props.setDadosPessoais(this.state)
    this.state.api = this.props.api

    if (this.props.fields.stepInvalido === false)
      this.props.nextStep()

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    for (var error in this.state.errors) {
      if (this.state.errors[error].length > 0 && document.getElementById(error) !== null) {
        document.getElementById(error).focus()
        countErrors++
      }
    }

    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
    this.props.changeLoading()
  }

  handleTextFieldKeyDown = (field) => event => {
    if (event.key === 'Enter' || event.key === undefined) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  handleChange = (input) => e => {
    e.preventDefault();

    this.state.errors[input] = []
    if (this.state.fieldsRequired.includes(input) && e.target.value === "") {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    this.state.fields[input] = e.target.value.trim().toUpperCase()

    if (e.target.id === 'email' && e.target.value !== '' && validaEmail(e.target.value) === false)
      this.state.errors.email.push('Digite um e-mail válido')

    if (e.target.id === 'dataNascimento' && e.target.value !== '' && validaDataDeNascimento(e.target.value) === false)
      this.state.errors.dataNascimento.push('Data inválida')

    if (e.target.id === 'dataExpedicaoRg' && e.target.value !== '' && validaData(e.target.value) === false)
      this.state.errors.dataExpedicaoRg.push('Data inválida')

    if (e.target.id === 'nomePai' && e.target.value !== '' && validaNomeCompleto(e.target.value) === false)
      this.state.errors.nomePai.push('Você deve digitar o nome completo')

    if (e.target.id === 'nomeMae' && e.target.value !== '' && validaNomeCompleto(e.target.value) === false)
      this.state.errors.nomeMae.push('Você deve digitar o nome completo')

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.errors[field].length > 0 || this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  render() {
    const { fields, errors, fieldsRequired, api, dialogPEP, faq, dialog_permissao_camera } = this.state
    const { drops } = this.props
    
    const dialogToken = <Dialog
                          open={dialogPEP}
                          keepMounted
                          aria-labelledby="alert-dialog-slide-title"
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogTitle id="alert-dialog-slide-title">{"Pessoa Politicamente Exposta"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                              São considerados legalmente como PPE: "Os agentes que desempenham ou tenham desempenhado, 
                              nos últimos 5 (cinco) anos, no Brasil ou em países, territórios e dependências estrangeiros, 
                              cargos, empregos ou funções públicas relevantes, assim como seus representantes, 
                              familiares e outras pessoas de seu relacionamento próximo."
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => this.setState({dialogPEP: false})} color="primary">
                              Ok
                            </Button>
                          </DialogActions>
                        </Dialog>

    const dialogPermissaoCamera = <DialogPermissaoCamera open={dialog_permissao_camera} onClose={() => this.setState({ dialog_permissao_camera: false })} />

    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />
    
    return (
        <React.Fragment>
          <HeaderStepper step={1} />
          <Box className={"box div-height-100-important"}>
          {
            this.props.mobile === false &&
            <div className={"text-right div-faq"}>
              <a className="link-faq" onClick={() => this.setState({faq: true})}>Precisa de ajuda?</a>
            </div>
          }
          {
            this.props.mobile &&
            <div className={"divContent"}>
              <div>
                <Typography className={"title-wizard"} color="primary" variant="h4">
                  Dados pessoais
                </Typography>
              </div>
              <div className={"text-right div-faq"} onClick={() => this.setState({faq: true})}>
                <a className="link-faq">Precisa de ajuda?</a>
              </div>
            </div>
          }
          <div className={"divContent margin-top-vh3"}>
            <InputText 
              type="email"
              value={fields.email} 
              error={errors.email} 
              field="email"
              label="E-mail"
              maxLength={100}
              required={fieldsRequired.includes('email')} 
              onChange={this.changeText('email')}
              onBlur={this.handleChange('email')} 
              onKeyDown={this.handleTextFieldKeyDown('dataNascimento')} 
            />
          </div>
          <div className={"divContent"}>
            <InputMask 
              value={fields.dataNascimento}
              type={"tel"}
              mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]} 
              error={errors.dataNascimento} 
              field="dataNascimento"
              label="Data de nascimento" 
              required={fieldsRequired.includes('dataNascimento')} 
              onChange={this.changeText('dataNascimento')}
              onBlur={this.handleChange('dataNascimento')} 
              onKeyDown={this.handleTextFieldKeyDown('genero')} 
            />
          </div>
          <div className={"divContent"}>
            <InputSelect
              value={fields.genero}
              error={errors.genero} 
              field="genero"
              label="Gênero" 
              required={fieldsRequired.includes('genero')} 
              onBlur={this.handleChange('genero')} 
              onKeyDown={this.handleTextFieldKeyDown('rg')}
              options={drops.genero}
            />
          </div>
          <div className={"divContent"}>
            <InputMask 
              type={"tel"}
              mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}  
              value={fields.rg} 
              error={errors.rg} 
              field="rg"
              label="RG" 
              maxLength={15}
              required={fieldsRequired.includes('rg')} 
              onChange={this.changeText('rg')}
              onBlur={this.handleChange('rg')} 
              onKeyDown={this.handleTextFieldKeyDown('ufRg')} 
            />
          </div>
          <div className={"divContent"}>
            <InputSelect
              value={fields.ufRg}
              error={errors.ufRg} 
              field="ufRg"
              label="Estado de emissão do RG" 
              required={fieldsRequired.includes('ufRg')} 
              onBlur={this.handleChange('ufRg')} 
              onKeyDown={this.handleTextFieldKeyDown('dataExpedicaoRg')}
              options={drops.ufRg}
            />
          </div>
          <div className={"divContent"}>
            <InputMask
              type={"tel"}
              mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}  
              value={fields.dataExpedicaoRg}
              error={errors.dataExpedicaoRg}
              field="dataExpedicaoRg"
              label="Data de emissão do RG"
              required={fieldsRequired.includes('dataExpedicaoRg')} 
              onChange={this.changeText('dataExpedicaoRg')}
              onBlur={this.handleChange('dataExpedicaoRg')} 
              onKeyDown={this.handleTextFieldKeyDown('orgaoEmissor')} 
            />
          </div>
          <div className={"divContent"}>
            <InputText 
              type="text"
              value={fields.orgaoEmissor} 
              error={errors.orgaoEmissor} 
              field="orgaoEmissor"
              label="Órgão Emissor do RG"
              maxLength={20}
              required={fieldsRequired.includes('orgaoEmissor')} 
              onChange={this.changeText('orgaoEmissor')}
              onBlur={this.handleChange('orgaoEmissor')} 
              onKeyDown={this.handleTextFieldKeyDown('nacionalidade')} 
            />
          </div>
          <div className={"divContent"}>
            <InputSelect
              value={fields.nacionalidade}
              error={errors.nacionalidade} 
              field="nacionalidade"
              label="Nacionalidade" 
              required={fieldsRequired.includes('nacionalidade')}
              onChange={this.changeText('nacionalidade')}
              onBlur={this.handleChange('nacionalidade')} 
              onKeyDown={this.handleTextFieldKeyDown('nacionalidadeEstado')}
              options={drops.nacionalidade}
            />
          </div>
          {
            fieldsRequired.indexOf('nacionalidadeEstado') !== -1 &&
            <div className={"divContent"}>
              <InputSelect
                value={fields.nacionalidadeEstado}
                error={errors.nacionalidadeEstado} 
                field="nacionalidadeEstado"
                label="Estado em que nasceu" 
                required={fieldsRequired.includes('nacionalidadeEstado')}
                onChange={this.changeText('nacionalidadeEstado')}
                onBlur={this.handleChange('nacionalidadeEstado')} 
                onKeyDown={this.handleTextFieldKeyDown('nacionalidadeCidade')}
                options={drops.ufRg}
              />
            </div>
          }
          <div className={"divContent"}>
            <InputText 
              value={fields.nacionalidadeCidade} 
              error={errors.nacionalidadeCidade} 
              field="nacionalidadeCidade"
              label="Cidade em que nasceu"
              maxLength={100}
              required={fieldsRequired.includes('nacionalidadeCidade')} 
              onChange={this.changeText('nacionalidadeCidade')}
              onBlur={this.handleChange('nacionalidadeCidade')} 
              onKeyDown={this.handleTextFieldKeyDown('nomeMae')} 
            />
          </div>
          <div className={"divContent"}>
            <InputText 
              value={fields.nomeMae} 
              error={errors.nomeMae} 
              field="nomeMae"
              label="Nome da sua mãe"
              maxLength={100}
              required={fieldsRequired.includes('nomeMae')} 
              onChange={this.changeText('nomeMae')}
              onBlur={this.handleChange('nomeMae')} 
              onKeyDown={this.handleTextFieldKeyDown('nomePai')} 
            />
          </div>
          <div className={"divContent"}>
            <InputText 
              value={fields.nomePai} 
              error={errors.nomePai} 
              field="nomePai"
              label="Nome do seu pai"
              placeholder="Opcional"
              disabled={fields.checkboxPai}
              maxLength={100}
              required={fieldsRequired.includes('nomePai')} 
              onChange={this.changeText('nomePai')}
              onBlur={this.handleChange('nomePai')} 
              onKeyDown={this.handleTextFieldKeyDown('escolaridade')} 
            />
          </div>
          <div className={"divContent"}>
            <InputSelect
              value={fields.escolaridade}
              error={errors.escolaridade} 
              field="escolaridade"
              label="Escolaridade" 
              required={fieldsRequired.includes('escolaridade')} 
              onBlur={this.handleChange('escolaridade')} 
              onKeyDown={this.handleTextFieldKeyDown('cpf_conjuge')}
              options={drops.escolaridade}
            />
          </div>
          <div className={"divContent"}>
            <div className={"divFlex"}>
              <div className={"flex-7"}>
                <label className={"label"}  htmlFor="pep">Pessoa Politicamente Exposta ?</label>
              </div>
              <div className={"flex-3 text-right"}>
                <a className="link-pep" onClick={() => this.setState({dialogPEP: true})}>O que é isso ?</a>
              </div>
            </div>
            <InputSelect
              value={fields.pep}
              error={errors.pep} 
              field="pep"
              required={fieldsRequired.includes('pep')} 
              onBlur={this.handleChange('pep')} 
              onChange={this.changeText('pep')}
              options={[{chave: 0, valor: 'Não'}, {chave: 1, valor: 'Sim'}]}
            />
          </div>
          <div className="divButtons">
            <Button fullWidth variant="contained" onClick={this.continue} color="primary" disabled={fields.stepInvalido} className={fields.stepInvalido ? "button-disabled" : "button-next"} >Próximo</Button>
            {/* <br/>
            <Button fullWidth variant="outlined" onClick={this.prev} color="primary" className={"button-back margin-top-vh2"} ><ArrowBackIcon/> Voltar</Button> */}
          </div>
          <br />
          {dialogToken}
          {
            api.event !== "" &&
            <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
          }
          {dialogFAQ}
          {dialogPermissaoCamera}
          </Box>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldsRequired: state.dadosPessoais.fieldsRequired,
    fields: state.dadosPessoais.fields,
    errors: state.dadosPessoais.errors,
    drops: state.dadosPessoais.drops,
    globalFields: state.global,
    api: state.dadosPessoais.api,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDadosPessoais: (state) => dispatch(setDadosPessoais(state)),
    setAPI: (api) => dispatch(setAPI(api)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DadosPessoais);