import { loadState} from './../sessionStorage'

let initState = {
  fieldsRequired: ['dataNascimento', 'genero', 'rg', 'orgaoEmissor', 'dataExpedicaoRg', 'ufRg', 'nacionalidade', 'nacionalidadeEstado', 'nacionalidadeCidade', 'nomeMae', 'pep'],
  fields: {
    stepInvalido: true,
    email: '',
    dataNascimento: '',
    dataExpedicaoRg: '',
    genero: '',
    rg: '',
    orgaoEmissor: '',
    ufRg: '',
    nacionalidade: '',
    nacionalidadeEstado: '',
    nacionalidadeCidade: '',
    nomeMae: '',
    nomePai: '',
    checkboxPai: false,
    escolaridade: '',
    pep: 0,
  },
  errors: {
    email: [],
    dataNascimento: [],
    dataExpedicaoRg: [],
    genero: [],
    rg: [],
    orgaoEmissor: [],
    ufRg: [],
    nacionalidade: [],
    nacionalidadeEstado: [],
    nacionalidadeCidade: [],
    nomeMae: [],
    nomePai: [],
    checkboxPai: [],
    escolaridade: [],
    pep: [],
  },
  api: {
    event: '',
    message: '',
  },
  drops: {
    genero: [],
    ufRg: [],
    escolaridade: [],
    nacionalidade: [],
  }
}

const persistedState = loadState('dadosPessoais')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const dadosIniciaisReducer = (state = initState, action) => {
  switch (action.type) {
      case 'RESET_DADOS_PESSOAIS':
        return initState
      case 'SET_DADOS_PESSOAIS':
        return action.state
      case 'SET_FIELDS_DADOS_PESSOAIS':
        return {...state, fields: action.fields}
      case 'SET_FIELDS_DADOS_PESSOAIS_RASCUNHO':
        let nacionalidadeEstadoChave = null
        if (action.fields.nacionalidadeEstado !== null) {
          nacionalidadeEstadoChave = state.drops.ufRg.find(x => x.valor === action.fields.nacionalidadeEstado.toString())
          nacionalidadeEstadoChave = typeof nacionalidadeEstadoChave !== "undefined" && nacionalidadeEstadoChave !== null ? nacionalidadeEstadoChave.chave : null
        }
        let ufRgChave = null;
        if (action.fields.ufRg !== null) {
          ufRgChave = state.drops.ufRg.find(x => x.valor === action.fields.ufRg.toString())
          ufRgChave = typeof ufRgChave !== "undefined" && ufRgChave !== null ? ufRgChave.chave : null
        }
        return {...state, fields: {...action.fields, ufRg: ufRgChave, nacionalidadeEstado: nacionalidadeEstadoChave } }
      case 'SET_DROPS_DADOS_PESSOAIS':
        state = {...state, drops: action.drops} 
        return state
      case 'SET_API_DADOS_PESSOAIS':
        return {...state, api: action.api}
      default:
        return state;
  }
}

export default dadosIniciaisReducer