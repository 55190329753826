const initState = {
    fieldsRequired: ['token'],
    fields: {
        stepInvalido: true,
        token: '',
        tokenValidado: false,
    },
    errors: {
        token: [],
    },
    api: {
        event: '',
        message: '',
    },
    drops: {

    }
}

const tokenReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_TOKEN':
            return action.state
        case 'SET_API_TOKEN':
            return {...state, api: action.api}
        default: 
            return state;
    }
}

export default tokenReducer