import { loadState} from './../sessionStorage'

let initState = {
  fieldsRequired: ['profissao', 'salario', 'patrimonio'],
  fields: {
    stepInvalido: true,
    profissao: '',
    empresa: '',
    salario: '',
    telefone: '',
    patrimonio: '',
  },
  errors: {
    profissao: [],
    empresa: [],
    salario: [],
    telefone: [],
    patrimonio: [],
  },
  api: {
    event: '',
    message: '',
  },
  drops: {
    profissao: [],
    patrimonio: [],
  }
}

const persistedState = loadState('ocupacao')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const OcupacaoReducer = (state = initState, action) => {
  switch (action.type) {
      case 'RESET_OCUPACAO':
        return initState
      case 'SET_OCUPACAO':
        return action.state;
      case 'SET_FIELDS_OCUPACAO':
        return {...state, fields: action.fields}
      case 'SET_DROPS_OCUPACAO':
        return {...state, drops: action.drops}
      case 'SET_API_OCUPACAO':
        return {...state, api: action.api}
      default: 
        return state;
  }
}

export default OcupacaoReducer