import React, { Component, useEffect } from 'react';
import './DadosIniciais.css'
import { Box, Typography, Button } from '@material-ui/core';
import InputText from '../../../components/InputText/InputText'
import InputMask from '../../../components/InputMask/InputMask'
import { connect } from 'react-redux'
import { setDadosIniciais, setAPI } from '../../../store/actions/dadosIniciaisActions'
import { carregarDrops, obterRascunhoProposta } from '../../../store/actions/TokenActions'
import { validaCPF, validaCelular, validaNomeCompleto } from './../../../common/validators'
import Snackbar from '../../../components/Snackbar/Snackbar'
import FAQ from './../../FAQ/FAQ'

export class DadosIniciais extends Component {  
  state = {
    fieldsRequired: this.props.fieldsRequired,
    fields: this.props.fields,
    errors: this.props.errors,
    api: this.props.api,
    faq: false,
  }
  
  componentDidMount () {
  }

  async componentDidMount () {
    //Scrolla a tela até o topo
    window.scrollTo(0, 0)
    //Verifica se o parametro de reenvio da proposta está marcado como true, caso esteja, realiza a consulta da proposta e direciona o usuário para o passo atual
    let reenvio_da_proposta = sessionStorage.getItem('reenvio_da_proposta')
    let idSessao = sessionStorage.getItem('idSessao')
    
    if (Boolean(reenvio_da_proposta) === true && idSessao !== null) {
      //Habilita o loading da tela
      this.props.changeLoading()
      
      //Chamada para a API
      await this.props.carregarDrops(this.state, idSessao)
      await this.props.buscarProposta(idSessao)
      this.state.api = this.props.api

      //Condição para verificar se a API não retornou erro, caso tenha retornado erro o step não é atualizado
      if (this.props.fields.stepInvalido === false)
        await this.props.refreshStep()

      //Remove as váriaveis utilizadas da sessão
      sessionStorage.removeItem('reenvio_da_proposta')
      sessionStorage.removeItem('idSessao')

      //Desabilita o loading da tela
      this.props.changeLoading()
    }

  }

  continue = async e => {
    e.preventDefault()
    this.props.changeLoading()
    this.state.fields.stepInvalido = true
    
    //Scrolla a tela até o topo
    window.scrollTo(0, 0)

    //Chamada para a API para integrar os dados iniciais
    await this.props.setDadosIniciais(this.state)
    this.state.api = this.props.api

    if (this.props.fields.stepInvalido === false)
      await this.props.refreshStep()

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    for (var error in this.state.errors) {
      if (this.state.errors[error].length > 0 && document.getElementById(error) !== null) {
        document.getElementById(error).focus()
        countErrors++
      }
    }

    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
    this.props.changeLoading()
  }

  prev = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.props.setAPI(api)
    await this.setState({api: api})
  }

  handleTextFieldKeyDown = (field) => event => {
    if (event.key === 'Enter' || event.key === undefined) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  changeText = (campo) => e => {
    if (e.target.id === 'nome' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z]|[\d])/g, '')
      this.state.fields[campo] = e.target.value
      this.props.nomeChanged(this.state.fields['nome']);
    }

    this.state.fields[campo] = e.target.value
    if (e.target.id === 'cpf') this.props.cpfChanged(this.state.fields['cpf']);

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "" || (campo === 'celular' && validaCelular(this.state.fields.celular) === false))
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  handleChange = (input) => e => {
    e.preventDefault();

    this.state.errors[input] = []
    if (e.target.value === "") {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    this.state.fields[input] = e.target.value.trim().toUpperCase()

    if (e.target.id === 'nome' && e.target.value !== '' )
    {
      validaNomeCompleto(e.target.value) ? this.props.nomeChanged(this.state.fields['nome']) : this.state.errors.nome.push('Você deve digitar seu nome completo');
    }
    
    if (e.target.id === 'cpf' && e.target.value !== '')
    {
      validaCPF(e.target.value) ? this.props.cpfChanged(this.state.fields['cpf']) : this.state.errors.cpf.push('CPF inválido')
    }

    if (e.target.id === 'celular' && e.target.value !== '' && validaCelular(e.target.value) === false)
      this.state.errors.celular.push('Digite um celular válido')

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.errors[field].length > 0 || this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }


  render() {
    const { fields, errors, fieldsRequired, api, faq } = this.state
    
    let consultarStatus = Boolean(sessionStorage.getItem('consultarStatus'))
    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />

    const mudouCpf = () => {
      this.handleTextFieldKeyDown('cpf'); 
      this.props.cpfChanged(this.state.fields['cpf']); 
    }

    const mudouNome = () => {
      this.handleTextFieldKeyDown('nome'); 
      this.props.nomeChanged(this.state.fields['nome']); 
    }
    
    return (
      <React.Fragment>
        <Box className={"box"} >
          <div className="margin-0 div-95">
            <Typography className="title">
              {consultarStatus === false ? 'Sobre você' : 'Que bom ter você por aqui!'}
            </Typography>
          </div>
          <div className="margin-top-vh2 div-95">
            <Typography className="sub-title" >
              {consultarStatus === false ? 'Vamos começar pelo essencial :)' : 'Preencha os dados abaixo para que possamos verificar o status da sua proposta.'}
            </Typography>
          </div>
          <div className={"text-right div-faq margin-top-vh2"} onClick={() => this.setState({faq: true})}>
            <a className="link-faq">Precisa de ajuda?</a>
          </div>
          <div className="div-flex">
            <div className="div-left flex-5 margin-top-vh3">
              <img className="img-dados-iniciais" src="/images/dados_iniciais.png" />
            </div>
            <div className="flex-5 margin-top-vh13">
              <Typography className="text-description">
                Sua segurança é a nossa prioridade, seus dados estão totalmente protegidos conosco.
              </Typography>
            </div>
          </div>
          <div className="div-field margin-top-vh3">
            <InputText 
              value={fields.nome} 
              error={errors.nome} 
              field={"nome"}
              label={"Qual o seu nome completo?"}
              maxLength={50}
              required={fieldsRequired.includes('nome')} 
              onChange={this.changeText('nome')}
              onBlur={this.handleChange('nome')}
              onKeyDown={mudouNome} 
            />
          </div>
          <div className="div-field">
            <InputMask 
              type={"tel"}
              mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
              value={fields.cpf} 
              error={errors.cpf} 
              field={"cpf"}
              label={"CPF"}
              required={fieldsRequired.includes('cpf')}
              onChange={this.changeText('cpf')}
              onBlur={this.handleChange('cpf')} 
              onKeyDown={mudouCpf}
              placeholder={"000.000.000-00"}
            />
          </div>
          <div className="div-field">
            <InputMask
              type={"tel"}
              mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={fields.celular} 
              error={errors.celular} 
              field={"celular"}
              label={"Telefone celular"} 
              required={fieldsRequired.includes('celular')} 
              onChange={this.changeText('celular')}
              onBlur={this.handleChange('celular')} 
              onKeyDown={this.handleTextFieldKeyDown('celular')} 
              placeholder={"(DD) 99999-9999"}
            />
          </div>
          <div className="divButtons">
            <Button fullWidth variant="contained" onClick={this.continue} disabled={fields.stepInvalido} className={fields.stepInvalido === false ? "button-next" : "button-disabled"} color="primary">Continuar</Button>
          </div>
          <br />
          {
            api.event !== "" &&
            <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
          }
          {dialogFAQ}
        </Box>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    fieldsRequired: state.dadosIniciais.fieldsRequired,
    fields: state.dadosIniciais.fields,
    errors: state.dadosIniciais.errors,
    api: state.dadosIniciais.api,
    globalFields: state.global,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDadosIniciais: (state) => dispatch(setDadosIniciais(state)),
    setAPI: (api) => dispatch(setAPI(api)),
    carregarDrops: (state, idSessao) => dispatch(carregarDrops(state, idSessao)),
    buscarProposta: (idSessao) => dispatch(obterRascunhoProposta(idSessao)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DadosIniciais);
