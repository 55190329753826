import request from './../../common/http-client'
import { checkErrors, stringCamelize } from './../../common/validators'

export const setCartao = (state) => {
  
  return async (dispatch, getState) => {

    state.api = {...state.api, event: '', message: ''}
    
    if (checkErrors(state.errors))
      state.fields = {...state.fields, stepInvalido: true}

    let options = {
      url: '/proposta/dados-fatura',
      method: 'POST',
      data: {
        idSessao: getState().global.tokenSessao,
        diaVencimentoFatura: Number(state.drops.vencimento.find(o => o.chave === Number(state.fields.vencimento)).valor),
        codigoMeioRecebimentoFatura: Number(state.fields.formaEnvio),
        meioRecebimentoFatura: state.drops.formaEnvio.find(o => o.chave === Number(state.fields.formaEnvio)).valor,
        codigoRecebimentoCartao: Number(state.fields.formaRecebimento),
        emailFatura: Number(state.fields.formaEnvio) === 2 && getState().dadosPessoais.fields.email === "" ? state.fields.emailFatura : getState().dadosPessoais.fields.email,
      },
    }

    await request(options)
      .then(response => {
        state.fields = {...state.fields, stepInvalido: false}
      }).catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, stepInvalido: true}
      })
      
      await dispatch({ type: 'SET_CARTAO', state })
  }
}

export const setAPI = (api) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_API_CARTAO', api })
  }
}