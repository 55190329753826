import React, { Component } from 'react'
import './Token.css'
import { connect } from 'react-redux'
import { validarToken, setAPI, reenviarToken, consultarStatus, consultaExistenciaCartao, carregarDrops, obterRascunhoProposta, integrarDadosIniciais } from '../../../store/actions/TokenActions'
import { Link, Typography, Box, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button } from '@material-ui/core'
import InputMask from '../../../components/InputMask/InputMask'
import Snackbar from '../../../components/Snackbar/Snackbar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FAQ from './../../FAQ/FAQ'

export class Token extends Component {
  state = {
    fieldsRequired: this.props.fieldsRequired,
    fields: { ...this.props.fields, tokenReenviado: false },
    errors: this.props.errors,
    api: {...this.props.api, open: () => this.props.api.event !== ''},
    drops: {},
    faq: false,
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }
  
  prev = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.props.setAPI(api)
    await this.setState({api: api})
  }

  continue = async e => {
    e.preventDefault();
    this.props.changeLoading()
    this.state.fields.stepInvalido = true

    if (this.props.fields.tokenValidado === false) {
      await this.props.validarToken(this.state)
      this.setState({})
    }

    if (this.props.fields.stepInvalido === false) {
      //Valida a proposta do cliente e caso o mesmo já possua uma proposta ativa, direciona ele para a tela de consulta do status
      await this.props.consultaExistenciaCartao(this.state)
      this.setState({})
    }
    
    if (this.props.fields.stepInvalido === false) {
      //Valida a proposta do cliente e caso o mesmo já possua uma proposta ativa, direciona ele para a tela de consulta do status
      await this.props.consultarStatus(this.state)
      this.setState({})
    }

    if (this.props.fields.stepInvalido === false) {
      //Valida a proposta do cliente e caso o mesmo já possua uma proposta ativa, direciona ele para a tela de consulta do status
      await this.props.carregarDrops(this.state)
      this.setState({})
    }

    if (this.props.fields.stepInvalido === false) {
      //Valida a proposta do cliente e caso o mesmo já possua uma proposta ativa, direciona ele para a tela de consulta do status
      await this.props.obterRascunhoProposta()
      this.setState({})
    }
    
    if (this.props.fields.stepInvalido === false) {
      //Integra os dados iniciais do cliente
      await this.props.integrarDadosIniciais(this.state)
      this.setState({})
    }

    if (this.props.fields.stepInvalido === false)
      await this.props.refreshStep()
    
    this.setState({})
    this.props.changeLoading()
  }

  handleTextFieldKeyDown = (field) => event => {
    if (event.key === 'Enter' || event.key === undefined) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  changeText = (field) => e => {
    this.state.fields[field] = e.target.value

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  reenviarToken = async e => {
    e.preventDefault();
    this.props.changeLoading()

    await this.props.reenviarToken(this.state)
    this.setState({api: this.props.api, errors: this.props.errors})

    if (this.props.api.event === '' && this.props.api.message === '')
      this.setState({fields: {...this.state.fields, tokenReenviado: true}})
    
    this.props.changeLoading()
  }

  handleChange = (input) => e => {
    e.preventDefault();

    this.state.errors[input] = []
    if (e.target.value === "") {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    this.state.fields[input] = e.target.value
    if (e.target.value.replace(/\D/g, '').length < 6) {
      this.state.fields[input] = ""
      this.state.errors[input].push("O código deve possuir 6 caracteres")
    }

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.errors[field].length > 0 || this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  render() {
    const { api, fields, errors, fieldsRequired, faq } = this.state
    let consultarStatus = Boolean(sessionStorage.getItem('consultarStatus'))

    const dialogToken = <Dialog
                          open={fields.tokenReenviado}
                          keepMounted
                          aria-labelledby="alert-dialog-slide-title"
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogTitle id="alert-dialog-slide-title">{"Código reenviado ao seu celular!"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                              Reenviamos um SMS com um código para o celular {sessionStorage.getItem('celular')}.
                              Por favor, verifique suas mensagens.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => this.setState({fields: {...fields, tokenReenviado: false}})} color="primary">
                              Ok
                            </Button>
                          </DialogActions>
                        </Dialog>

    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />

    return (
      <React.Fragment>
        <Box className="div-flex div-height-vh100">
          <Box className="box-left margin-top-vh3 box-grey">
            <div className="margin-top-vh2">
              <img className="Logo" src="images/logo.png" alt="Logo do parceiro" />
              <p className="text-logo text-left margin-left-vw5">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
            </div>
            <div className="margin-top-vh10">
              <Typography className="title text-left margin-left-vw5" >
                {consultarStatus === false ? 'Bem-vindo(a)!' : 'Que bom ter você por aqui!'}
              </Typography>
            </div>
            <div className="div-40 margin-top-vh3">
              <Typography className="sub-title text-left margin-left-vw5" >
                {consultarStatus === false ? 
                'Precisaremos de algumas informações importantes para a criação do seu cartão :)' : 
                'Preencha os dados ao lado para que possamos verificar o status da sua proposta.'}
              </Typography>
            </div>
            <div className="div-40 margin-top-vh3">
              <Typography className="sub-title text-left margin-left-vw5" >
                {consultarStatus === false ? 'Não se preocupe, este processo pode demorar um pouquinho, mas vai valer a pena!' : ''}
              </Typography>
            </div>
            <div className="margin-top-vh13 margin-left-vw5 div-flex">
              <img className="img-dados-iniciais flex-3" src="/images/dados_iniciais_desktop.png" alt="Bem-vindo"/>
              <div className="flex-6">
                <Typography className="sub-title-wizard" >
                  Sua segurança é a nossa prioridade, seus dados estão totalmente protegidos conosco.
                </Typography>
              </div>
            </div>
          </Box>
          <Box className="flex-5 margin-left-vw10 div-center margin-top-vh20">
            <div className={"text-right div-faq"} onClick={() => this.setState({faq: true})}>
              <a className="link-faq">Precisa de ajuda?</a>
            </div>
            <div className="div-left">
              <Typography className="title" >
                Código
              </Typography>
            </div>
            <div className="margin-top-vh2 div-left">
              <Typography className="sub-title" >
                Para sua segurança, enviamos um código para seu celular.
              </Typography>
            </div>
            <div className="div-field margin-top-vh10">
              <InputMask
                type={"tel"}
                mask={[/[0-9]/, ' ', '-', ' ', /[0-9]/, ' ', '-', ' ', /[0-9]/, ' ', '-', ' ', /[0-9]/, ' ', '-', ' ', /[0-9]/, ' ', '-', ' ', /[0-9]/]}
                value={fields.token} 
                error={errors.token} 
                field={"token"}
                label={"Insira o código recebido"}
                onChange={this.changeText('token')}
                required={fieldsRequired.includes('token')} 
                onBlur={this.handleChange('token')} 
                onKeyDown={this.handleTextFieldKeyDown('token')} 
              />            
            </div>
            <div className="margin-top-vh5 div-35 div-left">
              <Link component="button" variant="body2" className="link" onClick={this.reenviarToken}>Reenviar código</Link>
            </div>
            <div className="divButtons div-35 margin-top-vh10">
              <Button fullWidth variant="contained" onClick={this.continue} disabled={fields.stepInvalido} className={fields.stepInvalido === false ? "button-next" : "button-disabled"} color="primary" >Continuar</Button>
              <br/>
              <Button fullWidth variant="outlined" onClick={this.prev} color="primary" className={"button-back margin-top-vh2"} ><ArrowBackIcon/> Voltar</Button>
            </div>
            {dialogToken}
            {
              api.event !== "" &&
              <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
            }
          </Box>
        </Box>
        {dialogFAQ}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldsRequired: state.token.fieldsRequired,
    fields: state.token.fields,
    errors: state.token.errors,
    drops: state.token.drops,
    api: state.token.api,
    globalFields: state.global
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validarToken: async (state) => dispatch(validarToken(state)),
    setAPI: (api) => dispatch(setAPI(api)),
    reenviarToken: async (state) => dispatch(reenviarToken(state)),
    consultarStatus: async (state) => dispatch(consultarStatus(state)),
    consultaExistenciaCartao: async (state) => dispatch(consultaExistenciaCartao(state)),
    carregarDrops: async (state) => dispatch(carregarDrops(state)),
    obterRascunhoProposta: async () => dispatch(obterRascunhoProposta()),
    integrarDadosIniciais: async (state) => dispatch(integrarDadosIniciais(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Token);