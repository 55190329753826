import React, { Component } from 'react'
import './InputCurrency.css'
import CurrencyInput from 'react-currency-input';
import { InputLabel, TextField, FormHelperText } from '@material-ui/core'

let componentDidMount_super = CurrencyInput.prototype.componentDidMount; CurrencyInput.prototype.componentDidMount = function() { this.theInput.setSelectionRange_super = this.theInput.setSelectionRange; this.theInput.setSelectionRange = (start, end) => { if (document.activeElement === this.theInput) { this.theInput.setSelectionRange_super(start, end); } }; componentDidMount_super.call(this, ...arguments); };

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <CurrencyInput
      {...other}
      ref={inputRef}
      prefix="R$ " 
      decimalSeparator="," 
      thousandSeparator="."
      maxLength={{...other}.rows}
      autoFocus={false}
      tabIndex="0"
    />
  );
}

export class InputMask extends Component {

  render() {
    const { value, error, label, type, field, onBlur, onChange, onKeyDown, maxLength, required } = this.props

    return (
      <div>
        <InputLabel className={"label"} htmlFor={field}>{label}</InputLabel>
        <TextField
          required={required}
          error={error.length > 0}
          className={"textField"}
          id={field}
          autoComplete="off"
          type={type} 
          placeholder={required ? "Campo obrigatório" : ""}
          InputProps={{
            inputComponent: TextMaskCustom,
            value: value,
            onChange: onChange,
            onKeyDown: onKeyDown,
            onBlur: onBlur,
            rows: maxLength,
          }}
        />
        <div  className={"input-helper textDanger"}>
          {
            Array.isArray(error) === true && error.length > 0 &&
              <ul className="ul-padding-20">
                {
                  error.map((erro, index) => {
                    return <li key={index}>{erro}</li>
                  })
                }
              </ul>
          }
        </div>
      </div>
    );
  }
}

export default InputMask;