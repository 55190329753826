import { loadState} from './../sessionStorage'

let initState = {
  termo: '',
}

const persistedState = loadState('termo')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const TermoReducer = (state = initState, action) => {
  switch (action.type) {
      case 'SET_TERMO':
        return action.state;
      default: 
        return state;
  }
}

export default TermoReducer