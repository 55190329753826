import React, { Component } from 'react'
import './Onboarding.css'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { onTouch } from './../../../common/validators'
import Etapa1 from './../Onboarding/Etapas/Etapa1/Etapa1'
import Etapa2 from './../Onboarding/Etapas/Etapa2/Etapa2'
import Etapa3 from './../Onboarding/Etapas/Etapa3/Etapa3'

export class Onboarding extends Component {
  state = {
    step: 1,
  }

  render() {
    const { step } = this.state

    const step1 = <Etapa1
      nextStep={() => this.setState({step: this.state.step + 1})}
    />

    const step2 = <Etapa2
      nextStep={() => this.setState({step: this.state.step + 1})}
      prevStep={() => this.setState({step: this.state.step - 1})}
    />

    const step3 = <Etapa3
      prevStep={() => this.setState({step: this.state.step - 1})}
    />

    const switchStep = (step) => {
      switch (step) {
        case 1:
          return step1
        case 2:
          return step2
        case 3:
          return step3
        default:
            return step1
      }
    }

    window.addEventListener('load', function() {
      var el = document.getElementById('root')
      onTouch(el, function(evt, dir, phase, swipetype, distance) {

        if (typeof dir !== "undefined" && dir === 'left' && document.getElementById('button-next-onboarding') !== null)
          document.getElementById('button-next-onboarding').click()
        if (typeof dir !== "undefined" && dir === 'right' && document.getElementById('button-back-onboarding') !== null)
          document.getElementById('button-back-onboarding').click()

      })

    }, false)
    
    return (
      <MuiThemeProvider>
        <React.Fragment>
          {switchStep(step)}
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}



export default Onboarding;