import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import './ConsultaCEP.css'
import HomeIcon from '@material-ui/icons/Home';
import InputText from '../../components/InputText/InputText'
import InputSelectAutoComplete from '../../components/InputSelectAutoComplete/InputSelectAutoComplete'
import { Dialog, Slide, Box, Typography, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '../../components/Snackbar/Snackbar'
import { setCEP } from '../../store/actions/EnderecoActions'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const intialState = {
  fields: {
    estado_cep: '',
    identificador: '',
    rua_cep: '',
    cep: '',
    selectedIndex: '',
  },
  errors: {
    estado_cep: [],
    identificador: [],
    rua_cep: [],
    cep: [],
  },
  drops: {
    cep: []
  },
  api: {
    event: '',
    message: [],
  }
}

export class ConsultaCEP extends Component {
  constructor(props) {
    super(props)
    this.state = intialState

  }

  setCEP = async e => {
    e.preventDefault();
    this.setState(intialState)
    await this.props.setCEP(this.state.fields.cep)
    await this.props.close()
  }

  handleTextFieldKeyDown = (field) => event => {
    if ((event.key === 'Enter' || event.key === undefined) && document.getElementById(field) !== null) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.setState({api: api})
  }

  changeText = (field) => e => {

    e.target.value = e.target.value.toUpperCase()
    this.state.fields[field] = e.target.value

    if ((field === 'identificador' || field === 'rua_cep') && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z]|[\d])/g, '')
      this.state.fields[field] = e.target.value
    }

    this.setState({})
  }

  handleChange = (input) => async e => {
    e.preventDefault();

    this.state.errors[input] = []
    if (e.target.value === "") {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    this.state.fields[input] = e.target.value

    await this.setState({})
  }

  handleListItemClick = (cep, index) => {
    this.setState({fields: {...this.state.fields, cep: cep, selectedIndex: index}})
  }

  buscaCEP = async e => {
    e.preventDefault();
    
    await axios.get('https://viacep.com.br/ws/'+encodeURI(this.state.fields.estado_cep)+'/'+encodeURI(this.state.fields.identificador)+'/'+encodeURI(this.state.fields.rua_cep)+'/json/')
      .then(async (response) => {
        if (response.data !== null) {
          //Verificação se existe erro no cep, a API dos correios devolve "erro": true caso o CEP não seja encontrado
          if (response.data.length === 0) {
            await this.setState({api: {event: 'warning', message: 'Não foi possível localizar um CEP para esse endereço!'}})
          } else {
            await this.setState({drops: {...this.state.drops, cep: response.data}})
          }
        }
    })
    

  }
  
  render() {
    const { fields, errors, drops, api } = this.state
    const { open } = this.props
    const stepInvalido = drops.cep.length === 0 ? (fields.estado_cep === "" || fields.identificador === "" || fields.rua_cep === "") : (fields.cep === "")

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.close}
        TransitionComponent={Transition}
        transitionDuration={600}
        className="dialog-fullscreen-consulta-cep"
      >
        <Box className="box">
          <CloseIcon onClick={this.props.close} className="closeIcon" aria-label="close"/>
          {
            drops.cep.length === 0 &&
            <div>
              <div className="margin-0 div-75">
                <Typography className={"title-wizard"} color="primary" variant="h4">
                  Não sabe seu cep?
                </Typography>
              </div>
              <div className="margin-top-vh3 div-58">
                <Typography className="sub-title" >
                  Nao tem problema, nós iremos lhe ajudar a encontrá-lo :)
                </Typography>
              </div>
              <div className="div-center">
                <img className="img-consulta-cep" src="/images/house.png" />
              </div>
              <div className={"divContent div-cep"}>
                <InputSelectAutoComplete 
                  value={fields.estado_cep} 
                  error={errors.estado_cep} 
                  field="estado_cep"
                  label="Estado" 
                  onBlur={this.handleChange('estado_cep')} 
                  onChange={this.changeText('estado_cep')}
                  options={this.props.estado}
                />
              </div>
              <div className={"divContent div-cep"}>
                <InputText 
                  value={fields.identificador} 
                  error={errors.identificador} 
                  field="identificador"
                  label="Cidade"
                  maxLength={50}
                  onBlur={this.handleChange('identificador')}
                  onChange={this.changeText('identificador')}
                  onKeyDown={this.handleTextFieldKeyDown('rua_cep')} 
                />
              </div>
              <div className={"divContent div-cep"}>
                <InputText 
                  value={fields.rua_cep} 
                  error={errors.rua_cep} 
                  field="rua_cep"
                  label="Rua"
                  maxLength={50}
                  onBlur={this.handleChange('rua_cep')} 
                  onChange={this.changeText('rua_cep')}
                />
              </div>
            </div>
          }
          {
            drops.cep.length >= 1 &&
            <div>
              <div className="margin-0 div-75">
                <Typography className={"title-wizard"} color="primary" variant="h4">
                  Selecione seu CEP
                </Typography>
              </div>
              <div className="margin-top-vh3">
                <Typography className="sub-title">
                  - <b>Estado:</b> {fields.estado_cep}<br/>
                  - <b>Cidade:</b> {fields.identificador}<br/>
                  - <b>Rua:</b> {fields.rua_cep}<br/>
                </Typography>
              </div>
              <div className="divContent div-center">
                <a href="#" className="link-cep" onClick={() => this.setState({drops: {...drops, cep: []}, fields: {...fields, estado_cep: '', identificador: '', rua_cep: ''}})}>Buscar CEP novamente</a>
              </div>
              <div className={"divContent"}>
                <List className="textField">
                  {drops.cep.map((cep, index) => {
                    return  <ListItem key={index} button selected={fields.selectedIndex === index} onClick={event => this.handleListItemClick(cep.cep, index)}>
                              <ListItemAvatar>
                                <Avatar className="icon-home-green">
                                  <HomeIcon/>
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={cep.cep + " - " + cep.bairro} secondary={cep.complemento !== "" ? "Numeração " + cep.complemento : ""} />
                            </ListItem>
                  })}
                </List>
              </div>
            </div>
          }
          <div className="divButtons margin-top-vh3">
            <Button fullWidth variant="contained" color="primary" onClick={(drops.cep.length >= 1) ? this.setCEP : this.buscaCEP} disabled={stepInvalido} className={stepInvalido ? "button-disabled" : "button-next"} >{drops.cep.length >= 1 ? "Próximo" : "Buscar CEP"}</Button>
          </div>
        </Box>
        <br/>
        {
          api.event !== "" &&
          <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
        }
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCEP: (cep) => dispatch(setCEP(cep)),
  }
}

export default connect(null, mapDispatchToProps)(ConsultaCEP);