const Variables = {
  REACT_APP_API_URL : window.env?.REACT_APP_API_URL ?? process.env?.REACT_APP_API_URL ?? "",
  REACT_APP_API_ID_LOJISTA : window.env?.REACT_APP_API_ID_LOJISTA ?? process.env?.REACT_APP_API_ID_LOJISTA ?? "",  
  REACT_APP_OAUTH_URL : window.env?.REACT_APP_OAUTH_URL ?? process.env?.REACT_APP_OAUTH_URL ?? "",
  REACT_APP_OAUTH_CLIENT_ID : window.env?.REACT_APP_OAUTH_CLIENT_ID ?? process.env?.REACT_APP_OAUTH_CLIENT_ID ?? "",
  REACT_APP_OAUTH_CLIENT_SECRET : window.env?.REACT_APP_OAUTH_CLIENT_SECRET ?? process.env?.REACT_APP_OAUTH_CLIENT_SECRET ?? "",
  REACT_APP_MAX_ERROS_API_BIOMETRIA : window.env?.REACT_APP_MAX_ERROS_API_BIOMETRIA ?? process.env?.REACT_APP_MAX_ERROS_API_BIOMETRIA ?? "",
  REACT_APP_MAX_ERROS_USUARIO_BIOMETRIA : window.env?.REACT_APP_MAX_ERROS_USUARIO_BIOMETRIA ?? process.env?.REACT_APP_MAX_ERROS_USUARIO_BIOMETRIA ?? "",

  
  REACT_APP_IMAGE_COMPRESS_MAX_WIDTH: window.env?.REACT_APP_IMAGE_COMPRESS_MAX_WIDTH ?? process.env?.REACT_APP_IMAGE_COMPRESS_MAX_WIDTH ?? 1024,
  REACT_APP_IMAGE_COMPRESS_MAX_HEIGHT: window.env?.REACT_APP_IMAGE_COMPRESS_MAX_HEIGHT ?? process.env?.REACT_APP_IMAGE_COMPRESS_MAX_HEIGHT ?? 1024,
  REACT_APP_IMAGE_COMPRESS_QUALITY: window.env?.REACT_APP_IMAGE_COMPRESS_QUALITY ?? process.env?.REACT_APP_IMAGE_COMPRESS_QUALITY ?? 100,
  REACT_APP_IMAGE_COMPRESS_ROTATION: window.env?.REACT_APP_IMAGE_COMPRESS_ROTATION ?? process.env?.REACT_APP_IMAGE_COMPRESS_ROTATION ?? 0
};

export default Variables;