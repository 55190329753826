import React, { Component } from 'react'
import './Wizard.css'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import DadosIniciais from './../EtapasWizard/DadosIniciais/DadosIniciais'
import { setStep, resetState } from '../../store/actions/globalActions'
import { connect } from 'react-redux'
import CircularProgress from './../../components/CircularProgress/CircularProgress'
import Token from './../EtapasWizard/Token/Token'
import DadosPessoais from './../EtapasWizard/DadosPessoais/DadosPessoais'
import Endereco from './../EtapasWizard/Endereco/Endereco'
import Ocupacao from './../EtapasWizard/Ocupacao/Ocupacao'
import Cartao from './../EtapasWizard/Cartao/Cartao'
import Documentos from './../EtapasWizard/Documentos/Documento'
import { checkStateExpired } from './../../store/sessionStorage'

export class Wizard extends Component {
  constructor(props) {
    super(props)

    //Caso não tenha obtido as cores ainda, direciona para a tela de bem-vindo
    let cores = JSON.parse(sessionStorage.getItem('cores'))
    if (cores === null)
      window.location.href = 'bem-vindo'

    this.state = {
      step: this.props.step !== null ? this.props.step : 1,
      loading: false,
      cores: cores,
      cpf: '',
      nome: ''
    }
  } 

  componentDidUpdate() {
    //Verifica se o cadastro do usuário não está vencido
    checkStateExpired()

    const { match } = this.props;
    const step = this.state.step;
    const self = this; //  this should not be double quoted;

    if (step != 1 && match.path === "*/cadastro") {
      window.history.pushState(null, document.title, window.location.href)

      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href)
        event.preventDefault()
        
        if (step > 3) {
          let newStep = step <= 3  ? step : step - 1

          self.setState({
            step: newStep
          })

          self.props.setStep(newStep)
        }

      })
    }
  }

  // Proceed to next step
  nextStep = async () => {
    const { step } = this.state

    let newStep = step + 1
    this.setState({
      step: newStep,
      stepInvalido: true
    })

    await this.props.setStep(newStep)
  }

  // Go back to prev step
  prevStep = async () => {
    const { step } = this.state

    let newStep = step === 3 ? 1 : step - 1

    //Caso o usuário esteja no step 1, limpa toda a sessão do mesmo
    if (newStep === 1) {
      sessionStorage.removeItem('state')
      await this.props.resetState()
    }

    this.setState({
      step: newStep,
      stepInvalido: false,
    })

    await this.props.setStep(newStep)
  }

  // Refresh to step
  refreshStep = () => {

    this.setState({
      step: this.props.step,
      stepInvalido: true
    })

  }

  render() {
    const { step, loading } = this.state

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: this.state.cores.primary
        },
        secondary: {
          main: this.state.cores.secondary
        },
      },
    })


    const step1 = <DadosIniciais
      cpfChanged={(cpf) => this.setState({cpf})}
      nomeChanged={(nome) => this.setState({nome})}
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      refreshStep={this.refreshStep}
      changeLoading={() => this.setState({ loading: !this.state.loading })}
      mobile={true}
    />

    const step2 = <Token
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      refreshStep={this.refreshStep}
      changeLoading={() => this.setState({ loading: !this.state.loading })}
      mobile={true}
    />

    const step3 = <DadosPessoais
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({ loading: !this.state.loading })}
      mobile={true}
    />

    const step4 = <Endereco
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({ loading: !this.state.loading })}
      mobile={true}
    />

    const step5 = <Ocupacao
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({ loading: !this.state.loading })}
      mobile={true}
    />

    const step6 = <Cartao
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({ loading: !this.state.loading })}
      mobile={true}
    />

    const step7 = <Documentos
      cpf={sessionStorage.getItem('cpfCliente')}
      nome={sessionStorage.getItem('nomeCliente')}
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({ loading: !this.state.loading })}
      mobile={true}
    />

    const switchStep = (step) => {
      switch (step) {
        case 1:
          return step1
        case 2:
          return step2
        case 3:
          return step3
        case 4:
          return step4
        case 5:
          return step5
        case 6:
          return step6
        case 7:
          return step7
        default:
          return step1
      }
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div className={loading ? "opacity-2" : ""}>
          <React.Fragment>
            <div>
              {switchStep(step)}
            </div>
          </React.Fragment>
        </div>
        {
          loading &&
          <CircularProgress />
        }
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    step: state.global.step,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (step) => dispatch(setStep(step)),
    resetState: () => dispatch(resetState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);