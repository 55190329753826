import React, { Component } from 'react';
import './Etapa2.css'
import { Box, Typography, Button, MobileStepper } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export class Etapa2 extends Component {

  render() {

    return (
      <React.Fragment>
        <Box className="box">
          <div className="margin-top-vh7 div-85">
            <Typography className="title">
              É muito simples :)
            </Typography>
          </div>
          <div className="margin-top-vh15 div-center">
            <img className="img-etapa-2" src="/images/etapa2.png" alt="Simples e rápido :)" />
          </div>
          <div className="div-center div-90">
            <Typography className="text-onboarding">
              Não se preocupe, este processo pode demorar um pouquinho, mas vai valer a pena!
            </Typography>
          </div>
          <div className="divButtons div-bottom-5">
            <div className="div-flex">
              <div className="flex-7">
                <MobileStepper
                  variant="dots"
                  steps={3}
                  activeStep={1}
                  className="margin-top-vh1"
                />
              </div>
              <div className="flex-3">
                <Button variant="contained" onClick={this.props.nextStep} id="button-next-onboarding" className="button-next-onboarding" ><ArrowForwardIcon className="icon-button-next"/></Button>
                <Button variant="contained" onClick={this.props.prevStep} id="button-back-onboarding" className="button-back-onboarding" ><ArrowBackIcon className="icon-button-next"/></Button>
              </div>
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
  }
}

export default Etapa2;