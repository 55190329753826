import React, { Component } from 'react'
import './InputText.css'
import { InputLabel, TextField } from '@material-ui/core'

export class InputText extends Component {

  render() {
    const { value, error, disabled, label, type, field, onBlur, onKeyPress, onChange, onKeyDown, required, placeholder, maxLength, transform } = this.props

    let name = Math.random()

    return (
      <div>
        <InputLabel className={"label"} htmlFor={field}>{label}</InputLabel>
        <TextField
          type={type}
          required={required}
          error={error.length > 0}
          disabled={disabled}
          className={"textField"}
          id={field}
          name={name}
          onChange={onChange}
          color="secondary"
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          defaultValue={value}
          autoComplete="off"
          placeholder={typeof placeholder !== "undefined" ? placeholder : ""}
          inputProps={{
            tabIndex: "0",
            maxLength: maxLength,
            autocomplete: "off",
            autocapitalize: "none",
            autocorrect: "off",
            spellcheck: "false",
            "aria-autocomplete": "nope",
            className: "text-uppercase"
          }}
        />
        <div  className={"input-helper textDanger"}>
          {
            Array.isArray(error) === true && error.length > 0 &&
              <ul className="ul-padding-20">
                {
                  error.map((erro, index) => {
                    return <li key={index}>{erro}</li>
                  })
                }
              </ul>
          }
        </div>
      </div>
    );
  }
}

export default InputText;