import React, { Component } from 'react'
import HeaderStepper from './../../../components/HeaderStepper/HeaderStepper'
import { Typography, Box, Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import InputText from '../../../components/InputText/InputText'
import InputMask from '../../../components/InputMask/InputMask'
import InputCurrency from '../../../components/InputCurrency/InputCurrency'
import InputSelect from '../../../components/InputSelect/InputSelect'
import InputSelectAutoComplete from '../../../components/InputSelectAutoComplete/InputSelectAutoComplete'
import Snackbar from '../../../components/Snackbar/Snackbar'
import { connect } from 'react-redux'
import { setOcupacao, setAPI } from '../../../store/actions/OcupacaoActions'
import FAQ from './../../FAQ/FAQ'

export class Ocupacao extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldsRequired: this.props.fieldsRequired,
      fields: this.props.fields,
      errors: this.props.errors,
      drops: this.props.drops,
      api: {...this.props.api},
      faq: false,
    }
  }
  
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  prev = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.props.setAPI(api)
    await this.setState({api: api})
  }

  continue = async e => {
    e.preventDefault()
    this.props.changeLoading()
    this.state.fields.stepInvalido = true

    await this.props.setOcupacao(this.state)
    this.state.api = this.props.api

    if (this.props.fields.stepInvalido === false)
      this.props.nextStep()

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    for (var error in this.state.errors) {
      if (document.getElementById(error) !== null && this.state.errors[error].length > 0) {
        document.getElementById(error).focus()
        countErrors++
      }
    }

    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.props.changeLoading()
    this.setState({})
  }

  handleTextFieldKeyDown = (field) => event => {
    if (event.key === 'Enter' || event.key === undefined) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  changeText = (field) => e => {

    if (field === 'empresa' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z])/g, '')
      this.state.fields[field] = e.target.value
    }
    
    let renda = null;
    if (field === 'salario') {
      renda = e.substring(3, e.length).replace('.', '').replace('.', '').replace(',', '.')
      renda = parseFloat(renda)
      this.state.fields[field] = e
    } else {
      this.state.fields[field] = e.target.value
    }

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === 0 || this.state.fields[field] === "" || this.state.fields[field] === "R$ 0,00")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    if (renda !== null && renda > 2000000)
      this.state.fields.stepInvalido = true

    this.setState({})
  }

  handleChange = (input) => async e => {
    e.preventDefault();
    
    this.state.errors[input] = []
    if (this.state.fieldsRequired.includes(input) && (e.target.value === "R$ 0,00" || e.target.value === "" || e.target.value === 0)) {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    if (e.target.id === "patrimonio")
      this.state.fields[input] = e.target.value

    if (e.target.id === "empresa")
      this.state.fields[input] = e.target.value.trim().toUpperCase()

    if (e.target.value !== "" && e.target.id === "profissao")
      this.state.fields[input] = this.state.drops.profissao.find(o => o.valor === e.target.value).chave

    if (input === 'salario' && e.target.value !== '') {
      let renda = e.target.value.substring(3, e.target.value.length).replace('.', '').replace('.', '').replace(',', '.')
      renda = parseFloat(renda)
      if (renda > 2000000)
        this.state.errors.salario.push('Valor máximo permitido: R$ 2.000.000,00')

    }

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === 0 || this.state.errors[field].length > 0  || (this.state.fields[field] === "" || this.state.fields[field] === "R$ 0,00"))
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  render() {
    const { fields, errors, fieldsRequired, api, faq } = this.state
    const { drops } = this.props
    let profissaoSelected = drops.profissao.find(x => x.chave === fields.profissao)
    if (typeof profissaoSelected !== "undefined")
      profissaoSelected = profissaoSelected.valor

    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />

    return (
      <React.Fragment>
        <HeaderStepper step={3} />
        <Box className={"box"}>
          {
            this.props.mobile === false &&
            <div className={"text-right div-faq"}>
              <a className="link-faq" onClick={() => this.setState({faq: true})}>Precisa de ajuda?</a>
            </div>
          }
          {
            this.props.mobile &&
            <div className={"divContent"}>
              <div className={"divContent"}>
                <Typography className={"title-wizard"} color="primary" variant="h4">
                  Renda e Patrimônio
                </Typography>
              </div>
              <div className={"text-right div-faq"} onClick={() => this.setState({faq: true})}>
                <a className="link-faq">Precisa de ajuda?</a>
              </div>
            </div>
          }
          <div className={"divContent margin-top-vh3"}>
            <InputSelectAutoComplete 
              value={profissaoSelected} 
              error={errors.profissao} 
              field="profissao"
              label="Profissão" 
              required={fieldsRequired.includes('profissao')} 
              onBlur={this.handleChange('profissao')} 
              onChange={this.changeText('profissao')}
              options={drops.profissao}
            />
          </div>
          <div className={"divContent"}>
            <InputText 
              value={fields.empresa} 
              error={errors.empresa} 
              field="empresa"
              label="Empresa onde trabalha" 
              maxLength={50}
              required={fieldsRequired.includes('empresa')} 
              onBlur={this.handleChange('empresa')} 
              onChange={this.changeText('empresa')}
              onKeyDown={this.handleTextFieldKeyDown('telefone')} 
            />
          </div>
          <div className={"divContent"}>
            <InputMask 
              type={"tel"}
              mask={(fields.telefone.length >= 5 && fields.telefone.toString().substr(5, 1) === "9") ? ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] : ['(', /[0-9]/, /\d/, ')', ' ',  /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={fields.telefone} 
              error={errors.telefone} 
              field="telefone"
              label="Telefone comercial" 
              required={fieldsRequired.includes('telefone')} 
              onBlur={this.handleChange('telefone')} 
              onChange={this.changeText('telefone')} 
              onKeyDown={this.handleTextFieldKeyDown('salario')} 
            />
          </div>
          <div className={"divContent"}>
            <InputCurrency 
              type={"tel"}
              value={fields.salario} 
              error={errors.salario} 
              field="salario"
              label="Renda principal"
              maxLength={15}
              required={fieldsRequired.includes('salario')} 
              onBlur={this.handleChange('salario')} 
              onChange={this.changeText('salario')}
              onKeyDown={this.handleTextFieldKeyDown('patrimonio')} 
            />
          </div>
          <div className={"divContent"}>
            <InputSelect
              value={fields.patrimonio}
              error={errors.patrimonio}
              field="patrimonio"
              label="Qual valor do seu patrimônio total?"
              required={fieldsRequired.includes('patrimonio')}
              onChange={this.changeText('patrimonio')}
              onBlur={this.handleChange('patrimonio')}
              options={drops.patrimonio}
            />
          </div>
          <div className="divButtons">
            <Button fullWidth variant="contained" onClick={this.continue} color="primary" disabled={fields.stepInvalido} className={fields.stepInvalido ? "button-disabled" : "button-next"} >Próximo</Button>
            <br/>
            <Button fullWidth variant="outlined" onClick={this.prev} color="primary" className={"button-back margin-top-vh2"} ><ArrowBackIcon/> Voltar</Button>
          </div>
          <br />
          {
            api.event !== "" &&
            <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
          }
          {dialogFAQ}
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldsRequired: state.ocupacao.fieldsRequired,
    fields: state.ocupacao.fields,
    errors: state.ocupacao.errors,
    drops: state.ocupacao.drops,
    globalFields: state.global,
    api: state.ocupacao.api,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOcupacao: (state) => dispatch(setOcupacao(state)),
    setAPI: (api) => dispatch(setAPI(api)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ocupacao);