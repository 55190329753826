import React, { Component } from 'react'
import './Boas-Vindas.css'
import { Link, Typography, Box, Button, Checkbox } from '@material-ui/core'
import TermoReferencia from '../../EtapasWizard/TermoReferencia/TermoReferencia'
import FAQ from './../../FAQ/FAQ'

export class BoasVindas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accept_termo: false,
      open_termo: false,
      faq: false,
    }

  }

  consultarStatus = async e => {
    e.preventDefault()

    //Flega que o cliente clicou em consultar status
    sessionStorage.setItem('consultarStatus', true);
    window.location.href = 'cadastro'

  }

  solicitarCartao = async e => {
    e.preventDefault()

    //Remove a flag, informando que o usuário deseja ir ao wizard
    sessionStorage.removeItem('consultarStatus')
    window.location.href = 'cadastro'
  }

  render() {
    const { accept_termo, open_termo, faq } = this.state
    
    const dialog = <TermoReferencia open={open_termo} close={() => this.setState({open_termo: false})} />
    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />

    return (
      <React.Fragment>
        <link rel="stylesheet" href="css/cores.css" />
        <Box className="box-onboarding">
          <Box className="box-left div-padding-left-5 margin-top-vh3">
            <div>
              <img className="Logo" src="images/logo.png" alt="Logo do parceiro" />
              <p className="text-logo">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
            </div>
            <div className="margin-top-vh10">
              <Typography className="title title-dados-iniciais" >
                Seja bem-vindo(a)!
              </Typography>
            </div>
            <div className="div-30 margin-top-vh3">
              <Typography className="sub-title" >
                Peça já o seu cartão de crédito, são muitas vantagens!
              </Typography>
            </div>
            <div className="margin-top-vh20">
              <img className="img-bem-vindo" src="/images/desktop-home.png" alt="Bem-vindo"/>
              <img className="img-doggie" src="/images/doggie.png" alt="Bem-vindo"/>
            </div>
          </Box>
          <Box className="box-right div-padding-left-5 margin-top-vh2 div-center">
            <div className="div-58 margin-top-vh10">
              <Typography className="sub-title" >
                O que deseja fazer hoje?
              </Typography>
            </div>
            <div className={"text-right div-faq margin-top-vh3"} onClick={() => this.setState({faq: true})}>
              <a className="link-faq">Precisa de ajuda?</a>
            </div>
            <div className="div-center margin-top-vh8">
              <img className="img-bem-vindo" src="/images/bem-vindo.png" alt="Bem-vindo"/>
            </div>
            <div className="divFlex margin-top-vh17 div-dropbox">
              <Checkbox className="flex-01 div-center checkbox-termo" checked={accept_termo} onClick={() => this.setState({ accept_termo: !this.state.accept_termo })} color="inherit" />
              <p className="flex-5 font-termo div-center">
              Declaro que li e aceito os Termos do Cartão de Crédito, Termos de Aceite e autorizo a Senff a consultar meus dados no SCR do Banco Central e de Órgãos de Proteção ao Crédito. 
                <a className="text-link-termo" onClick={() => this.setState({ open_termo: true })}> Saiba Mais</a>
              </p>
            </div>
            <div className="divButtons margin-top-vh3">
              <Button variant="contained" className={accept_termo === false ? "button-disabled" : "buttonNext"} disabled={accept_termo === false} onClick={this.solicitarCartao}>Solicitar cartão</Button>
              <br/>
              <Link component="button" variant="body2" className="link-status margin-top-15" onClick={this.consultarStatus}>Consultar status</Link>
            </div>
          </Box>
          <br/>
        </Box>
        {
          open_termo ? dialog : ''
        }
        {faq ? dialogFAQ : ''}
      </React.Fragment>
    );
  }
}

export default BoasVindas;