import request from './../../common/http-client'
import { checkErrors, dateToISO, stringCamelize, checkMaioridade } from './../../common/validators'

export const setDadosPessoais = (state) => {
  
  return async (dispatch, getState) => {

    state.api = {...state.api, event: '', message: ''}
    
    if (checkErrors(state.errors))
      state.fields = {...state.fields, stepInvalido: true}

    let estado = state.drops.ufRg.find(o => o.chave === Number(state.fields.nacionalidadeEstado))
    let ufRG = state.drops.ufRg.find(o => o.chave === Number(state.fields.ufRg))

    let options = {
      url: '/proposta/dados-pessoais',
      method: 'POST',
      data: {
        idSessao: getState().global.tokenSessao,
        email: state.fields.email,
        dataNascimento: dateToISO(state.fields.dataNascimento),
        rg: state.fields.rg,
        orgaoEmissor: state.fields.orgaoEmissor,
        ufRg: typeof ufRG !== "undefined" ? ufRG.valor : "",
        dataExpedicaoRg: dateToISO(state.fields.dataExpedicaoRg),
        genero: state.fields.genero,
        nacionalidade: Number(state.fields.nacionalidade),
        nacionalidadeCidade: state.fields.nacionalidadeCidade,
        nacionalidadeEstado: typeof estado !== "undefined" ? estado.valor : "",
        nomePai: state.fields.nomePai !== null ? state.fields.nomePai.toString().toUpperCase() : "",
        nomeMae: state.fields.nomeMae !== null ? state.fields.nomeMae.toString().toUpperCase() : "",
        escolaridade: state.fields.escolaridade === "" ? null : Number(state.fields.escolaridade),
        pessoaPoliticamenteExposta: Boolean(Number(state.fields.pep))
      },
    }

    await request(options)
      .then(async (response) => {
        state.fields = {...state.fields, stepInvalido: false}
        await dispatch({ type: 'SET_TERMO_REQUIRED', termo_required: checkMaioridade(state.fields.dataNascimento )})
      }).catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, stepInvalido: true}
      })

    await dispatch({ type: 'SET_DADOS_PESSOAIS', state })
  }
}


export const setAPI = (api) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_API_DADOS_PESSOAIS', api })
  }
}