import React, { Component } from 'react'
import './InputSelect.css'
import { InputLabel, NativeSelect, FormHelperText } from '@material-ui/core'

export class InputMask extends Component {

  render() {
    const { value, error, options, label, field, onBlur, onChange, onKeyDown, required, description } = this.props

    return (
      <div>
        <InputLabel tabIndex={false} className={"label"} htmlFor={field}>{label}</InputLabel>
        <NativeSelect
          defaultValue={value}
          id={field}
          className={"nativeSelect"}
          onBlur={onBlur}
          error={error.length > 0}
          color="secondary"
          placeholder={required ? "Campo obrigatório" : ""}
          inputProps={{
              name: field,
              tabIndex: "0",
              onChange: onChange,
          }}
        >
          <option value="" selected>
              Selecione
          </option>
          {
            typeof options !== "undefined" && options !== null &&
            Object.entries(options).map((option, key) => {
              return <option value={option[1].chave}>{option[1].valor}</option>   
            })
          }
        </NativeSelect>
        <div  className={"input-helper textDanger"}>
          {
            (
              Array.isArray(error) === true && error.length > 0 &&
              <div>
                <ul className="ul-padding-20">
                  {
                    error.map((erro, index) => {
                      return <li key={index}>{erro}</li>
                    })
                  }
                </ul>
              </div>
            )
            ||
            (
              Array.isArray(error) === true && error.length === 0 &&
                <div className="margin-top-vh2 description-info">
                  {description}
                </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default InputMask;