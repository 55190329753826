import React, { Component } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core'

export class PularUpload extends Component {
  render() {
    return (
      <React.Fragment>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Tem certeza que deseja pular o envio das fotos ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Caso você pule essa etapa, é necessário apresentar a documentação na loja emissora, para finalizar a análise.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.props.pularUpload} color="primary" autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default PularUpload;