import axios from 'axios'
import envs from "../config/Variables"

const httpClient = axios.create({
  baseURL: envs.REACT_APP_API_URL
})

// TODO: throw exceptions on non 200 code responses.
const request = async options => {
  const onSuccess = response => response.data;
  let token = await getToken()
  token = token !== null ? token.access_token : '';
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  options = {...options, headers}
  const onError = error => {
    // treats only server errors
    if (typeof error.response !== "undefined" && error.response.status === 500) {
      throw error
    }

    if (typeof error.response === "undefined") {
      return Promise.reject(error);
    }
    return Promise.reject(error.response.data)
  };

  return await httpClient(options)
    .then(onSuccess)
    .catch(onError)
}

const loadToken = async () => {

  let data = new FormData();
  data.append('client_id', envs.REACT_APP_OAUTH_CLIENT_ID);
  data.append('client_secret', envs.REACT_APP_OAUTH_CLIENT_SECRET);
  data.append('grant_type', 'client_credentials');
  data.append('response_type', 'token');

  let token = null
  let config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  await axios.post(envs.REACT_APP_OAUTH_URL, data, config)
  .then(response => {
    let dataExpiracao = new Date()
    dataExpiracao.setSeconds(dataExpiracao.getSeconds() + response.data.expires_in)
    token = {...response.data, dataExpiracao}
  })
  .catch(error => {
    console.log(error)
  });

  sessionStorage.setItem('token', JSON.stringify(token))

  return token;
}

const getToken = async () => {

  let token = JSON.parse(sessionStorage.getItem('token'))

  if (token == null || new Date() > new Date(token.dataExpiracao))
    return loadToken()

  return token;
}

/*
const requestWithRetry = options => {
  const onSuccess = response => {
    if (response.data.code === 200) return response.data.model

    throw response
  };
  const onError = error => Promise.reject(error)

  httpClient.interceptors.response.use(undefined, err => {
    const { config, response } = err

    if (!config || !config.retry) {
      return Promise.reject(err)
    }

    if (response.status === 404) {
      config.retryCount = config.retryCount || 0

      if (config.retryCount >= config.retry) {
        return Promise.reject(err)
      }

      config.retryCount += 1

      const backoff = new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, config.retryDelay || 1)
      });

      return backoff.then(() => requestWithRetry(config))
    }

    return Promise.reject(err)
  });

  return httpClient(options)
    .then(onSuccess)
    .catch(onError);
}

export { requestWithRetry }
*/

export default request
