import React, { Component } from 'react'
import './ClienteExistente.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Typography, Box, Button } from '@material-ui/core'
import { connect } from 'react-redux'
  
export class ClienteExistente extends Component {

  /**
   * Remove o nome do cliente da session storage e caso o mesmo já esteja nulo, redireciona o usuário para a tela inicial.
   */
  removeNomeCliente = () => {

    if (sessionStorage.getItem('nomeCliente') === null)
      window.location.href = 'bem-vindo'

    sessionStorage.removeItem('nomeCliente')
  }
  render() {

    //Ira remover o nome do cliente da session storare.
    this.removeNomeCliente()

    return (
      <React.Fragment>
        <link rel="stylesheet" href="css/cores.css" />
        <Box className="div-flex div-height-vh100">
          <Box className="box-left margin-top-vh3 box-grey">
            <div className="margin-top-vh2 div-left margin-left-vw5">
              <img className="Logo" src="images/logo.png" alt="Logo do parceiro" />
              <p className="text-logo text-left">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
            </div>
            <div className="margin-top-vh10">
              <Typography className="title text-left margin-left-vw5" >
                Olá {this.props.nome}!
              </Typography>
            </div>
            <div className="div-40 margin-top-vh3">
              <Typography className="sub-title text-left margin-left-vw5" >
                Identificamos que <strong>você já possui um cartão</strong>. Este cadastro é apenas para novos clientes.
              </Typography>
            </div>
            <div className="margin-top-vh5">
              <div className="div-center margin-top-vh3">
                  <img className="img-cliente-existente" src="/images/cliente-existente.png" />
              </div>
            </div>
          </Box>
          <Box className="flex-5 div-center max-width-50">
            <div className="text-center margin-top-vh15">
                <Typography className="text-info" >
                    Para acessar informações do seu cartão atual, acesse o link.
                </Typography>
            </div>
            <div className="margin-top-vh2">
                <a href="https://banking.senff.com.br/login?tkn=7zE-Qkh1xeDka17M_D0cc08662MeartxQjSA7sx7fqyfZeXPRjh1geSc1A_UTKJokn7PtpHrf4ApSGn0hE8kIWnmnwb0EoNlxY4bOKRQF7E1" className="link-internet-banking" onClick={this.changeCEP}>Internet Banking</a>
            </div>
            <div>
              <div className="div-center margin-top-vh5">
                  <img className="img-cliente-existente" src="/images/internet-banking.png" />
              </div>
            </div>
            <div className="divButtons margin-top-vh5">
              <Button href="bem-vindo" fullWidth variant="outlined" className="buttonBack"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
            <br/>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  let nomeCliente = sessionStorage.getItem('nomeCliente')
  if(nomeCliente !== null)
    nomeCliente = nomeCliente.toString().split(" ")[0]
  
    return {
    nome: nomeCliente
  }
}
 
export default connect(mapStateToProps)(ClienteExistente);