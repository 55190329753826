import React, { Component } from 'react'
import './Endereco.css'
import HeaderStepper from './../../../components/HeaderStepper/HeaderStepper'
import { InputLabel, Typography, Box, Button, Checkbox } from '@material-ui/core'
import InputText from '../../../components/InputText/InputText'
import InputMask from '../../../components/InputMask/InputMask'
import InputSelectAutoComplete from '../../../components/InputSelectAutoComplete/InputSelectAutoComplete'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { connect } from 'react-redux'
import { setEndereco, integraCEP, setAPI } from '../../../store/actions/EnderecoActions'
import Snackbar from '../../../components/Snackbar/Snackbar'
import ConsultaCEP from '../../ConsultaCEP/ConsultaCEP'
import FAQ from './../../FAQ/FAQ'

export class Endereco extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldsRequired: this.props.fieldsRequired,
      fields: {...this.props.fields, nao_sei_meu_cep: false, checkbox_numero: (this.props.fields.numero === "SN" || this.props.fields.checkbox_numero) ? true : false, numero: this.props.fields.numero === "SN" ? "" : this.props.fields.numero},
      errors: this.props.errors,
      drops: this.props.drops,
      api: this.props.api,
      faq: false,
    }

  }
  
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  prev = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.props.setAPI(api)
    await this.setState({api: api})
  }

  continue = async e => {
    e.preventDefault()
    this.props.changeLoading()
    this.state.fields.stepInvalido = true

    await this.props.setEndereco(this.state)
    this.state.api = this.props.api

    if (this.props.fields.stepInvalido === false)
      this.props.nextStep()
    
    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    for (var error in this.state.errors) {
      if (this.state.errors[error].length > 0 && document.getElementById(error) !== null) {
        document.getElementById(error).focus()
        countErrors++
      }
    }

    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.props.changeLoading()
    this.setState({})
  }

  handleTextFieldKeyDown = (field) => event => {
    if (event.key === 'Enter' || event.key === undefined) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  changeCEP = async e => {
    e.preventDefault()
    let index = this.state.fieldsRequired.indexOf('cep')

    if (index === -1) {
      this.state.fieldsRequired.push('cep')
    } else {
      this.state.fieldsRequired.splice(index, 1)
    }

    this.setState({fields: {...this.state.fields, nao_sei_meu_cep: !this.state.fields.nao_sei_meu_cep}})
  }

  semNumero = async e => {
    e.preventDefault()
    let index = this.state.fieldsRequired.indexOf('numero')
    this.handleChange('checkbox_numero')

    if (index === -1) {
      this.state.fieldsRequired.push('numero')
    } else {
      this.state.fieldsRequired.splice(index, 1)
    }

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.errors[field].length > 0 || this.state.fields[field] === "")
        countErrors++
    })
    this.state.errors.numero = []

    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.state.fields.checkbox_numero = !this.state.fields.checkbox_numero
    this.state.fields.numero = ''
    let numero = document.getElementById('numero')
    if (numero !== null)
      numero.value = ''
    this.setState({})
  }

  changeText = (field) => async e => {

    if (e.target.id === 'numero' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9])/g, '')
      this.state.fields[field] = e.target.value
    }

    if (field === 'complemento' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z])/g, '')
      this.state.fields[field] = e.target.value
    }

    this.state.fields[field] = e.target.value

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    let verificaCEP = e.target.id === 'cep' && e.target.value.length === 9
    let cep = e.target.value

    if (verificaCEP) {
      await this.integraCEP(cep)
    }

    this.setState({})
  }

  integraCEP = async (cep) => {
    await this.props.changeLoading()
    await this.props.integraCEP(cep)
    if (document.getElementById('cidade') !== null && typeof this.props.fields.cidade !== "undefined") {
      document.getElementById('rua').value = this.props.fields.rua
      document.getElementById('bairro').value = this.props.fields.bairro
      document.getElementById('cidade').value = this.props.fields.cidade
      let estado = this.state.drops.estado.find(o => o.valor === this.props.fields.estado)
      document.getElementById('estado').value = this.state.drops.estado.length > 0 ? typeof estado === "undefined" ? "" : estado.valor : ""
    }
    if (typeof this.props.fields.cidade !== "undefined" && this.props.fields.cidade !== "") {
      this.state.fields = {...this.state.fields, 
        cep: cep,
        rua: this.props.fields.rua, 
        bairro: this.props.fields.bairro, 
        cidade: this.props.fields.cidade, 
        estado: this.state.drops.estado.length > 0 ? this.state.drops.estado.find(o => o.valor === this.props.fields.estado).valor : ""
      }
    }
    await this.setState({fields: this.state.fields, api: this.props.api})
    await this.props.changeLoading()
  }

  handleChange = (input) => async e => {
    e.preventDefault();

    this.state.errors[input] = []
    if (this.state.fieldsRequired.includes(input) && e.target.value === "") {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    this.state.fields[input] = e.target.value.trim().toUpperCase()

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.errors[field].length > 0 || this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    await this.setState({})
  }

  closeConsultaCEP = async () => {
    await this.props.changeLoading()
    await this.setState({fields: {...this.state.fields, nao_sei_meu_cep: false}, errors: {...this.state.errors, cep: []}})
    if (this.props.fields.cep !== "")
      await this.integraCEP(this.props.fields.cep)
    await this.props.changeLoading()
  }

  render() {
    const { fields, errors, fieldsRequired, api, faq } = this.state
    const { drops } = this.props

    const dialogConsultaCEP = <ConsultaCEP open={fields.nao_sei_meu_cep} close={this.closeConsultaCEP} estado={drops.estado} />
    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />

    return (
      <React.Fragment>
        <HeaderStepper step={2} />
        <Box className={"box"}>
          {
            this.props.mobile === false &&
            <div className={"text-right div-faq"}>
              <a className="link-faq" onClick={() => this.setState({faq: true})}>Precisa de ajuda?</a>
            </div>
          }
          {
            this.props.mobile &&
            <div className={"divContent"}>
              <div className={"divContent"}>
                <Typography className={"title-wizard"} color="primary" variant="h4">
                  Endereço
                </Typography>
              </div>
              <div className={"text-right div-faq"} onClick={() => this.setState({faq: true})}>
                <a className="link-faq">Precisa de ajuda?</a>
              </div>
            </div>
          }
          <div className={"divContent margin-top-vh3"}>
            <InputMask
              type={"tel"}
              mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
              value={fields.cep}
              error={errors.cep}
              field="cep"
              label="CEP"
              required={fieldsRequired.includes('cep')} 
              onBlur={this.handleChange('cep')}
              onChange={this.changeText('cep')}
            /> 
            <a href="#" className="link-cep" onClick={this.changeCEP}>Não sei meu CEP</a>
          </div>
          {
            fields.cidade !== "" &&
            <div>
              <div className={"divContent"}>
                <InputText 
                  value={fields.rua}
                  error={errors.rua} 
                  disabled={this.props.fields.cep !== "" && this.props.fields.rua !== ""}
                  field="rua"
                  label="Rua"
                  maxLength={50}
                  required={fieldsRequired.includes('rua')} 
                  onBlur={this.handleChange('rua')} 
                  onChange={this.changeText('rua')}
                  onKeyDown={this.handleTextFieldKeyDown('numero')} 
                />
              </div>
              <div className={"divContent"}>
                <div className={"divFlex"}>
                  <div className={"flex-4 padding-top-17"}>
                  <InputLabel className={"label"}  htmlFor="nome_pai">Número</InputLabel>
                  </div>
                  <div className={"flex-6 text-right"}>
                    <Checkbox className="label-sem-numero" checked={fields.checkbox_numero} onClick={this.semNumero} color="inherit" />
                    <label className={"label-sem-numero"}>Sem número</label>
                  </div>
                </div>
                <InputText 
                  type="tel"
                  value={fields.numero} 
                  error={errors.numero} 
                  field="numero"
                  maxLength={6}
                  disabled={fields.checkbox_numero}
                  required={fieldsRequired.includes('numero')} 
                  onBlur={this.handleChange('numero')} 
                  onChange={this.changeText('numero')}
                  onKeyDown={this.handleTextFieldKeyDown('complemento')} 
                />
              </div>
              <div className={"divContent"}>
                <InputText 
                  value={fields.complemento} 
                  error={errors.complemento} 
                  field="complemento"
                  label="Complemento"
                  maxLength={50}
                  required={fieldsRequired.includes('complemento')} 
                  onBlur={this.handleChange('complemento')} 
                  onChange={this.changeText('complemento')}
                  onKeyDown={this.handleTextFieldKeyDown('bairro')} 
                />
              </div>
              <div className={"divContent"}>
                <InputText 
                  value={fields.bairro} 
                  error={errors.bairro} 
                  disabled={this.props.fields.cep !== "" && this.props.fields.bairro !== ""}
                  field="bairro"
                  label="Bairro"
                  maxLength={25}
                  required={fieldsRequired.includes('bairro')} 
                  onBlur={this.handleChange('bairro')} 
                  onChange={this.changeText('bairro')}
                  onKeyDown={this.handleTextFieldKeyDown('cidade')} 
                />
              </div>
              <div className={"divContent"}>
                <InputSelectAutoComplete 
                  value={fields.estado} 
                  error={errors.estado} 
                  disabled={this.props.fields.cep !== "" && this.props.fields.estado !== ""}
                  field="estado"
                  label="Estado" 
                  required={fieldsRequired.includes('estado')} 
                  onBlur={this.handleChange('estado')} 
                  onChange={this.changeText('estado')}
                  options={drops.estado}
                />
              </div>
              <div className={"divContent"}>
                <InputText 
                  value={fields.cidade} 
                  error={errors.cidade} 
                  disabled={this.props.fields.cep !== "" && this.props.fields.cidade !== ""}
                  field="cidade"
                  label="Cidade" 
                  maxLength={100}
                  required={fieldsRequired.includes('cidade')} 
                  onBlur={this.handleChange('cidade')} 
                  onChange={this.changeText('cidade')}
                  onKeyDown={this.handleTextFieldKeyDown('cidade')} 
                />
              </div>
            </div>
          }
          <div className={fields.rua !== "" ? "divButtons" : "divButtons margin-top-vh5"}>
            <Button fullWidth variant="contained" onClick={this.continue} color="primary" disabled={fields.stepInvalido} className={fields.stepInvalido ? "button-disabled" : "button-next"} >Próximo</Button>
            <br/>
            <Button fullWidth variant="outlined" onClick={this.prev} color="primary" className={"button-back margin-top-vh2"} ><ArrowBackIcon/> Voltar</Button>
          </div>
          <br />
          {
            api.event !== "" &&
            <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
          }
          {dialogConsultaCEP}
          {dialogFAQ}
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldsRequired: state.endereco.fieldsRequired,
    fields: state.endereco.fields,
    errors: state.endereco.errors,
    drops: state.endereco.drops,
    globalFields: state.global,
    api: state.endereco.api,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEndereco: (state) => dispatch(setEndereco(state)),
    integraCEP: (cep) => dispatch(integraCEP(cep)),
    setAPI: (api) => dispatch(setAPI(api)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Endereco);