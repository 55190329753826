import request from './../../common/http-client'
import axios from 'axios'
import { checkErrors, dateToISO, stringCapitalize, stringCamelize } from './../../common/validators'

export const setEndereco = (state) => {
  
  return async (dispatch, getState) => {

    state.api = {...state.api, event: '', message: ''}
    
    if (checkErrors(state.errors))
      state.fields = {...state.fields, stepInvalido: true}

    let options = {
      url: '/proposta/dados-endereco',
      method: 'POST',
      data: {
        idSessao: getState().global.tokenSessao,
        cep: state.fields.cep.replace(/\D/g, ''),
        rua: state.fields.rua,
        numero: Boolean(state.fields.checkbox_numero) === false ? state.fields.numero : "SN",
        complemento: state.fields.complemento,
        bairro: state.fields.bairro,
        cidade: state.fields.cidade,
        estado: state.fields.estado.toString(),
        semNumeroInformado: Boolean(state.fields.checkbox_numero),
        clienteNaoSabeOCep: Boolean(state.fields.nao_sei_meu_cep),
      },
    }

    await request(options)
      .then(response => {
        state.fields = {...state.fields, stepInvalido: false}
      }).catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, stepInvalido: true}
      })

    await dispatch({ type: 'SET_ENDERECO', state })
  }
}

export const integraCEP = (cep) => {

  return async (dispatch, getState) => {

    let fields = {}
    let api = {event: '', message: ''}

    await axios.get('https://viacep.com.br/ws/'+cep+'/json/')
      .then(async (response) => {
        if (response.data !== null) {
          //Verificação se existe erro no cep, a API dos correios devolve "erro": true caso o CEP não seja encontrado
          if (response.data.erro === true) {
            api = {event: 'warning', message: 'Não foi possível localizar esse CEP'}
          } else {
            fields = {
              cep: cep,
              rua: response.data.logradouro,
              bairro: response.data.bairro,
              cidade: response.data.localidade,
              estado: response.data.uf,
            }
          }
        }
    }).catch(async (error) => {
      api = {event: 'error', message: 'Ocorreu um erro inesperado!'}
    })

    await dispatch({ type: 'SET_API', api })
    await dispatch({ type: 'INTEGRA_CEP', fields })
  }

}

export const setAPI = (api) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_API_ENDERECO', api })
  }
}

export const setCEP = (cep) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_CEP', cep })
  }
}