import React, { Component } from 'react';
import './DialogPermissaoCamera.css'
import { connect } from 'react-redux'
import { carregarFAQ } from '../../store/actions/globalActions'
import { Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

export class FAQ extends Component {

  render() {
    const { open, onClose } = this.props

    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        transitionDuration={600}>
        <DialogTitle id="alert-dialog-slide-title">
          <div className="flex">
            <img src="/images/icone_camera.png" alt="Camera" className="icone_camera" />
            <p className="dialog-camera-title">Ops!</p>
          </div>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText id="alert-dialog-slide-description" className="texto-dialog-camera">
            Você não liberou a permissão de acesso à câmera. Você pode continuar preenchendo sua solicição, pois seus dados ficarão salvos. <br/>
            Mas para concluir, será preciso habilitar à câmera para a etapa de Biometria.<br/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Ok, entendi.
        </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faq: state.faq.faq,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    carregaFAQ: () => dispatch(carregarFAQ()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);