import React, { Component } from 'react'
import './Wizard.css'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import DadosIniciais from './../../Desktop/DadosIniciais/DadosIniciais'
import { setStep, resetState } from '../../../store/actions/globalActions'
import { connect } from 'react-redux'
import CircularProgress from './../../../components/CircularProgress/CircularProgress'
import Token from './../../Desktop/Token/Token'
import DadosPessoais from './../../EtapasWizard/DadosPessoais/DadosPessoais'
import Endereco from './../../EtapasWizard/Endereco/Endereco'
import Ocupacao from './../../EtapasWizard/Ocupacao/Ocupacao'
import Cartao from './../../EtapasWizard/Cartao/Cartao'
import Documentos from './../../EtapasWizard/Documentos/Documento'
import { Box, Typography } from '@material-ui/core'
import { checkStateExpired } from './../../../store/sessionStorage'

export class Wizard extends Component {
  constructor (props) {
    super(props)

    let cores = JSON.parse(sessionStorage.getItem('cores'))

    //Caso não tenha obtido as cores ainda, direciona para a tela de bem-vindo
    if (cores === null)
      window.location.href = 'bem-vindo'

    this.state = {
      step: this.props.step !== null ? this.props.step : 1,
      loading: false,
      cores: cores,
      cpf: '',
      nome: ''
    }
  }

  

  componentDidUpdate () {
    //Verifica se o cadastro do usuário não está vencido
    checkStateExpired()
  }

  // Proceed to next step
  nextStep = async () => {
    const { step } = this.state

    let newStep = step + 1
    this.setState({
      step: newStep,
      stepInvalido: true
    })

    await this.props.setStep(newStep)
  }

  // Go back to prev step
  prevStep = async () => {
    const { step } = this.state

    let newStep = step === 3 ? 1 :step - 1
    
    //Caso o usuário esteja no step 1, limpa toda a sessão do mesmo
    if (newStep === 1){
      sessionStorage.removeItem('state')
      await this.props.resetState()
    }
    
    this.setState({
      step: newStep,
      stepInvalido: false,
    })

    await this.props.setStep(newStep)
  }

  // Refresh to step
  refreshStep = () => {

    this.setState({
      step: this.props.step,
      stepInvalido: true
    })

  }

  titleStep = (step) => {
    switch (step) {
      case 3:
        return "Dados Pessoais"
      case 4:
        return "Endereço"
      case 5:
        return "Renda"
      case 6:
        return "Sobre o seu Cartão"
      case 7:
        return "Documentos"
      default:
          return ""
    }
  }

  descriptionStep = (step) => {
    switch (step) {
      case 3:
        return "Vamos começar com algumas informações sobre você."
      case 4:
        return "Também precisaremos de informações sobre seu endereço."
      case 5:
        return "Por favor agora preencha algumas informações relacionadas à sua renda."
      case 6:
        return "Nesta etapa será possível escolher qual a melhor data de vencimento para você e como prefere receber suas faturas."
      case 7:
        return "Estamos quase lá! Para finalizar precisaremos de uma selfie e do seu documento de identificação."
      default:
          return ""
    }
  }

  render() {
    const { step, loading } = this.state

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: this.state.cores.primary
        },
        secondary: {
          main: this.state.cores.secondary
        },
      },
    })

    
    const step1 = <DadosIniciais
      cpfChanged={(cpf) => this.setState({cpf}) }
      nomeChanged={(nome) => this.setState({nome})}
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      refreshStep={this.refreshStep}
      changeLoading={() => this.setState({loading: !this.state.loading})}
    />

    const step2 = <Token
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      refreshStep={this.refreshStep}
      changeLoading={() => this.setState({loading: !this.state.loading})}
    />

    const step3 = <DadosPessoais
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({loading: !this.state.loading})}
      mobile={false}
    />

    const step4 = <Endereco
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({loading: !this.state.loading})}
      mobile={false}
    />

    const step5 = <Ocupacao
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({loading: !this.state.loading})}
      mobile={false}
    />

    const step6 = <Cartao
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({loading: !this.state.loading})}
      mobile={false}
    />

    const step7 = <Documentos
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      changeLoading={() => this.setState({loading: !this.state.loading})}
      mobile={false}
      cpf={sessionStorage.getItem('cpfCliente')}
      nome={sessionStorage.getItem('nomeCliente')}
    />

    const switchStep = (step) => {
      switch (step) {
        case 1:
          return step1
        case 2:
          return step2
        case 3:
          return step3
        case 4:
          return step4
        case 5:
          return step5
        case 6:
          return step6
        case 7:
          return step7
        default:
            return step1
      }
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div className={loading ? "opacity-2" : ""}>
          <React.Fragment>
            {
              step <= 2 ? switchStep(step) : 
              <React.Fragment>
                <Box className="div-flex margin-0 div-height-100">
                  <Box className="box-left box-grey">
                     <div className="div-padding-left-5 div-left position-fixed overflow-x-hidden">
                        <div>
                          <img className="Logo margin-top-vh3" src="images/logo.png" alt="Logo do parceiro" />
                          <p className="text-logo">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
                        </div>
                        <div className="div-35 margin-top-vh5">
                          <Typography className="title" >
                            {this.titleStep(step)}
                          </Typography>
                        </div>
                        <div className="div-35 margin-top-vh3">
                          <Typography className="sub-title" >
                            {this.descriptionStep(step)}  
                          </Typography>
                        </div>
                        <div className="div-bottom-2">
                          <img className="img-house" src="/images/house.png" alt="Bem-vindo"/>
                          <img className="img-pendente div-padding-left-5" src="/images/bike.png" alt="Bem-vindo"/>
                        </div>
                     </div>
                  </Box>
                  <Box className="box-right box-white margin-0 div-height-100">
                    {switchStep(step)}
                  </Box>
                </Box>
              </React.Fragment>
            }
          </React.Fragment>
        </div>
        {
          loading &&
          <CircularProgress />
        }
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    step: state.global.step,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (step) => dispatch(setStep(step)),
    resetState: () => dispatch(resetState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);