import request from './../../common/http-client'
import { checkErrors, stringCamelize } from './../../common/validators'

export const setOcupacao = (state) => {
  
  return async (dispatch, getState) => {

    state.api = {...state.api, event: '', message: ''}
    
    if (checkErrors(state.errors))
      state.fields = {...state.fields, stepInvalido: true}

    let renda = typeof state.fields.salario === "string" ? state.fields.salario.substring(3, state.fields.salario.length).replace('.', '').replace('.', '').replace(',', '.') : state.fields.salario
    renda = parseFloat(renda)

    let options = {
      url: '/proposta/dados-renda',
      method: 'POST',
      data: {
        idSessao: getState().global.tokenSessao,
        codigoProfissao: state.fields.profissao !== "" && Number(state.fields.profissao) !== 0 ? Number(state.fields.profissao) :  null,
        empresa: state.fields.empresa,
        telefoneComercial: state.fields.telefone !== "" ? state.fields.telefone.replace(/\D/g, '').substr(2, 9) : null,
        dddTelefoneComercial: state.fields.telefone !== "" ? state.fields.telefone.toString().substr(1, 2) : null,
        rendaPrincipal: renda,
        patrimonio: Number(state.fields.patrimonio),
      },
    }

    await request(options)
      .then(response => {
        state.fields = {...state.fields, stepInvalido: false}
      }).catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, stepInvalido: true}
      })
      
      await dispatch({ type: 'SET_OCUPACAO', state })
  }
}

export const setAPI = (api) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_API_OCUPACAO', api })
  }
}