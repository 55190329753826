import { loadState} from './../sessionStorage'

let initState = {
  fieldsRequired: ['cep', 'rua', 'numero', 'bairro', 'cidade', 'estado'],
  fields: {
    stepInvalido: true,
    cep: '',
    rua: '',
    numero: '',
    complemento: '',
    bairro: '',
    estado: '',
    cidade: '',
  },
  errors: {
    cep: [],
    rua: [],
    numero: [],
    complemento: [],
    bairro: [],
    estado: [],
    cidade: [],
  },
  api: {
    event: '',
    message: '',
  },
  drops: {
    estado: []
  }
}

const persistedState = loadState('endereco')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}


const enderecoReducer = (state = initState, action) => {
  switch (action.type) {
      case 'RESET_ENDERECO':
        return initState
      case 'SET_ENDERECO':
        state = action.state
        return state
      case 'SET_FIELDS_ENDERECO':
        return {...state, fields: action.fields}
      case 'SET_DROPS_ENDERECO':
        return {...state, drops: action.drops}
      case 'INTEGRA_CEP':
        let fields = {...state.fields, cep: action.fields.cep, rua: action.fields.rua, bairro: action.fields.bairro, cidade: action.fields.cidade, estado: action.fields.estado }
        return {...state, fields: fields}
      case 'SET_API':
        return {...state, api: action.api}
      case 'SET_CEP':
        return {...state, fields: {...state.fields, cep: action.cep}}
      default:
          return state;
  }
}

export default enderecoReducer