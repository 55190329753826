import React, { Component } from 'react'
import './Home.css'
import { Typography, Box, Button, Checkbox } from '@material-ui/core'
import TermoReferencia from '../../EtapasWizard/TermoReferencia/TermoReferencia'
  
export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accept_termo: false,
      open_termo: false,
    }

  }

  render() {
    const { accept_termo, open_termo } = this.state

    const dialog = <TermoReferencia open={open_termo} close={() => this.setState({open_termo: false})} />
    return (
      <React.Fragment>
          <Box className="box">
            <div className="margin-0 margin-top-vh7 div-75">
              <Typography className="title">
                Que bom ter você por aqui!
              </Typography>
            </div>
            <div className="margin-top-vh3 div-58">
              <Typography className="sub-title" >
                Vamos começar?
              </Typography>
            </div>
            <div className="div-center">
              <img className="img-home" src="/images/home.png" />
            </div>
            <div className="divFlex">
              <Checkbox className="flex-01" checked={accept_termo} onClick={() => this.setState({ accept_termo: !this.state.accept_termo })} color="inherit" />
              <p className="flex-9 font-termo">
              Declaro que li e aceito os Termos do Cartão de Crédito, Termos de Aceite e autorizo a Senff a consultar meus dados no SCR do Banco Central e de Órgãos de Proteção ao Crédito.
                <a className="text-link-termo" onClick={() => this.setState({ open_termo: true })}> Saiba Mais</a>
              </p>
            </div>
            <div className="divButtons margin-top-vh5">
              <Button href="tutorial" fullWidth variant="contained" disabled={accept_termo === false} className={accept_termo === false ? "button-disabled" : "buttonNext"} >Novo cadastro</Button>
            </div>
            <br/>
          </Box>
          {
            open_termo ? dialog : ''
          }
      </React.Fragment>
    );
  }
}

export default Home;