import request from './../../common/http-client'
import { stringCamelize } from './../../common/validators'
import {isIOS, isMobileSafari} from 'react-device-detect';
const requestIp = require('request-ip');
export const setDocumento = (state, file, type, fileEncrypted = null) => {

  const codigoArquivo = {
    RG: 1,
    CNH: 2,
    RNE: 7,
    SELFIE: 11,
    COMPLEMENTO: 14,
  }

  const modoCaptura = {
    InBrowser: 0,
    MobileCamera: 1,
    Galeria: 2,
  }
  
  console.log('fileEncrypted - ' + fileEncrypted)
  return async (dispatch, getState) => {

      state.fields = {...state.fields, [type]: ""}
      
      let formData = new FormData()
      let docs_identificacao = ''

      let idSessao = getState().global.tokenSessao
      formData.append("idSessao", idSessao)
      formData.append("encrypted", fileEncrypted)
      formData.append("file", file)

      //Preenche o código do arquivo para a API
      if (["frente", "verso"].includes(type)) {
        formData.append("codigoArquivo", state.fields.documento === "RG" ? codigoArquivo.RG : state.fields.documento === "CNH" ? codigoArquivo.CNH : codigoArquivo.RNE)
        docs_identificacao = type
        type = state.fields.documento.toString().toLowerCase() + '_' + type
      }
      if (type === 'complemento')
        formData.append("codigoArquivo", codigoArquivo.COMPLEMENTO)
      if (type === 'selfie') {
        formData.append("codigoArquivo", codigoArquivo.SELFIE)
        formData.append("modoCaptura", modoCaptura.InBrowser)
      }

      formData.append("cpf", state.fields.cpf);
      formData.append("nome", state.fields.nome);

      //Tratativa para iphone
      if (type === 'selfie' && (isIOS && isMobileSafari === false)) {
        formData.append("modoCaptura", modoCaptura.MobileCamera)
      }

      formData.append("modoCaptura", modoCaptura.Galeria)

      let options = {
      url: '/proposta/documentos',
      method: 'POST',
      headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }

    await request(options)
      .then(response => {
        state.fields = {...state.fields, [type]: response.model.id}
        if (docs_identificacao !== '') {
          state.fields = {...state.fields, [docs_identificacao]: response.model.id, [type]: response.model.id}
        }
        state.api = {...state.api, event: 'success', message: 'Documento enviado com sucesso!'}

      }).catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          state.api.message = []
          error.errors.map(error => {
            state.api.message = []
            state.api.event = "warning"
            state.api.message.push(error.value)

          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, [type]: ""}
      })

      await dispatch({ type: 'SET_DOCUMENTO', state })
  }
}

export const finalizarProposta = (state) => {
  return async (dispatch, getState) => {

    state.api = {...state.api, event: '', message: ''}
    
    let idSessao = getState().global.tokenSessao
    let options = {
      url: '/proposta/finalizar-proposta',
      method: 'POST',
      data: {
        idSessao: idSessao,
      },
    }

    await request(options)
      .then(response => {
        state.fields = {...state.fields, stepInvalido: false}
      })
      .catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, stepInvalido: true}
      })
    
    await dispatch({ type: 'SET_DOCUMENTO', state })
  }
}

export const criarSessaoBiometria = (state) => {
  return async (dispatch, getState) => {

    state.api = {...state.api, event: '', message: ''}
    
    let idSessao = getState().global.tokenSessao
    let oldState = getState()

    let options = {
      url: `/proposta/sessao/${idSessao}/biometria`,
      method: 'POST',
      data: 
      {
        sistemaOrigem: '0fef5834-26d8-4921-86e4-4126ab987f1d',
        cpf: sessionStorage.getItem('cpfCliente').replace(/\D/g, ''),
        nomeCliente: sessionStorage.getItem('nomeCliente'),
        telefone: sessionStorage.getItem('celular').replace(/[^\w\s]/gi, '').replace(/\s/g, ''),
        linkRedirect: window.location.origin + '/' + window.location.pathname.toString().split('/')[1] + '/fim',
        dispositivo: {
          browser: oldState.global.browser,
          device: oldState.global.device,
          ipOrigem: oldState.global.ipOrigem,
          portaOrigem: oldState.global.portaOrigem,
        }
      }
    }

    await request(options)
      .then(response => {
        window.location.href = `${response.model.urlRedirect}`;
        state.fields = {...state.fields, stepInvalido: false}
      })
      .catch(error => {
        if (typeof error.errors === 'undefined') {
          state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
        } else {
          error.errors.map(error => {
            let key = stringCamelize(error.key)
            
            if (typeof state.errors[key] === "undefined" || state.errors[key].indexOf(error.value) !== -1) {
              state.api.message = []
              state.api.event = "warning"
              state.api.message.push(error.value)
            }

            if (Array.isArray(state.errors[key]) === false)
              state.errors[key] = []

            state.errors[key].push(error.value)
          })

          if (typeof state.errors['idSessao'] !== "undefined" && state.errors['idSessao'].length > 0) {
            state.api = {...state.api, event: 'warning', message: 'Sessão não encontrada'}
            dispatch({ type: 'SET_STEP', step: 1 })
          }

          if (error.code === 500) {
            state.api = {...state.api, event: 'error', message: 'Ocorreu um erro inesperado!'}
          }
        }

        state.fields = {...state.fields, stepInvalido: true}
      })
    
    await dispatch({ type: 'SET_DOCUMENTO', state })
  }
}


export const setAPI = (api) => {
  return async (dispatch, getState) => {
    await dispatch({ type: 'SET_API_DOCUMENTO', api })
  }
}