import React, { Component } from 'react';
import './CircularProgress.css'
import CircularProgressMaterial from '@material-ui/core/CircularProgress';

export class CircularProgress extends Component {

  render() {
    return (
      <CircularProgressMaterial  className={"circularProgress"} color={"primary"} />
    );
  }
}

export default CircularProgress;