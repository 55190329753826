import React, { Component } from 'react'
import HeaderStepper from './../../../components/HeaderStepper/HeaderStepper'
import { Typography, Box, Button } from '@material-ui/core'
import InputSelect from '../../../components/InputSelect/InputSelect'
import InputText from '../../../components/InputText/InputText'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Snackbar from '../../../components/Snackbar/Snackbar'
import { connect } from 'react-redux'
import { setCartao, setAPI } from '../../../store/actions/CartaoActions'
import { validaEmail } from '../../../common/validators'
import FAQ from './../../FAQ/FAQ'

export class Cartao extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldsRequired: this.props.fieldsRequired,
      fields: {...this.props.fields, emailFatura: this.props.fields.emailFatura === "" ? this.props.email : this.props.fields.emailFatura},
      errors: this.props.errors,
      drops: this.props.drops,
      api: {...this.props.api, open: () => this.props.api.event !== ''},
      faq: false,
    }
  }
  
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  prev = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.props.setAPI(api)
    await this.setState({api: api})
  }

  continue = async e => {
    e.preventDefault()
    this.props.changeLoading()
    this.state.fields.stepInvalido = true

    await this.props.setCartao(this.state)
    this.state.api = this.props.api

    if (this.props.fields.stepInvalido === false)
      this.props.nextStep()

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    for (var error in this.state.errors) {
      if (this.state.errors[error].length > 0 && document.getElementById(error) !== null) {
        document.getElementById(error).focus()
        countErrors++
      }
    }

    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.props.changeLoading()
    this.setState({})
  }

  handleTextFieldKeyDown = (field) => event => {
    if (event.key === 'Enter' || event.key === undefined) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  changeText = (field) => e => {

    this.state.fields[field] = e.target.value

    if (e.target.value !== "" && e.target.id === "vencimento") {
      let dia = this.state.drops.vencimento.find(o => o.chave === Number(e.target.value))
      let diaCompra = this.state.drops.melhorDiaCompra.find(o => o.dia === dia.valor)
      this.state.fields.melhorDiaCompra = typeof diaCompra !== "undefined" && diaCompra !== null ? diaCompra.melhorDiaCompra : ""
    }

    if (Number(this.state.fields.formaEnvio) === 2) {
      let index = this.state.fieldsRequired.indexOf('emailFatura')

      if (index === -1) {
        this.state.fieldsRequired.push('emailFatura')
      }
    }

    if (Number(this.state.fields.formaEnvio) !== 2) {
      let index = this.state.fieldsRequired.indexOf('emailFatura')

      if (index !== -1) {
        this.state.fieldsRequired.splice(index, 1)
      }
    }
    
    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  handleChange = (input) => async e => {
    e.preventDefault();

    this.state.errors[input] = []
    if (this.state.fieldsRequired.includes(input) && e.target.value === "") {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    this.state.fields[input] = e.target.value.trim().toUpperCase()

    if (e.target.id === 'emailFatura' && e.target.value !== '' && validaEmail(e.target.value) === false)
      this.state.errors.emailFatura.push('Digite um e-mail válido')

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.errors[field].length > 0 || this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  render() {
    const { fields, errors, fieldsRequired, api, faq } = this.state
    const { drops } = this.props
   
    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />

    return (
      <React.Fragment>
        <HeaderStepper step={4} />
        <Box className={"box"}>
          {
            this.props.mobile === false &&
            <div className={"text-right div-faq"}>
              <a className="link-faq" onClick={() => this.setState({faq: true})}>Precisa de ajuda?</a>
            </div>
          }
          {
            this.props.mobile &&
            <div className={"divContent"}>
              <div className={"divContent"}>
                <Typography className={"title-wizard"} color="primary" variant="h4">
                  Personalize seu cartão
                </Typography>
              </div>
              <div className={"text-right div-faq"} onClick={() => this.setState({faq: true})}>
                <a className="link-faq">Precisa de ajuda?</a>
              </div>
            </div>
          }
          <div className={"divContent margin-top-vh4"}>
            <InputSelect 
              value={fields.vencimento} 
              error={errors.vencimento} 
              field="vencimento"
              label="Qual o vencimento que você deseja para a fatura do seu cartão?" 
              description={fields.vencimento !== "" ? "Melhor dia para compra: " + (fields.melhorDiaCompra)  : ""}
              required={fieldsRequired.includes('vencimento')} 
              onBlur={this.handleChange('vencimento')} 
              onChange={this.changeText('vencimento')}
              onKeyDown={this.handleTextFieldKeyDown('formaEnvio')} 
              options={drops.vencimento}
            />
          </div>
          <div className={"divContent"}>
            <InputSelect 
              value={fields.formaEnvio} 
              error={errors.formaEnvio} 
              field="formaEnvio"
              label="Como deseja receber a fatura?" 
              required={fieldsRequired.includes('formaEnvio')} 
              onBlur={this.handleChange('formaEnvio')} 
              onChange={this.changeText('formaEnvio')}
              onKeyDown={this.handleTextFieldKeyDown('formaEnvio')} 
              options={drops.formaEnvio}
            />
          </div>
          {
            drops.formaRecebimento.length > 1 &&
            <div className={"divContent"}>
              <InputSelect 
                value={fields.formaRecebimento} 
                error={errors.formaRecebimento} 
                field="formaRecebimento"
                label="Como deseja receber o cartão?" 
                required={fieldsRequired.includes('formaRecebimento')} 
                onBlur={this.handleChange('formaRecebimento')} 
                onChange={this.changeText('formaRecebimento')}
                onKeyDown={this.handleTextFieldKeyDown('formaRecebimento')} 
                options={drops.formaRecebimento}
              />
            </div>
          }
          {
            this.state.fields.formaEnvio === "2" && this.props.email === "" &&
            <div className={"divContent"}>
              <InputText 
                type="email"
                value={fields.emailFatura} 
                error={errors.emailFatura} 
                field="emailFatura"
                label="E-mail para receber a fatura"
                maxLength={100}
                required={fieldsRequired.includes('emailFatura')} 
                onChange={this.changeText('emailFatura')}
                onBlur={this.handleChange('emailFatura')}  
              />
            </div>
          }
          <div className="divButtons">
          <Button fullWidth variant="contained" onClick={this.continue} color="primary" disabled={fields.stepInvalido} className={fields.stepInvalido ? "button-disabled" : "button-next"} >Próximo</Button>
            <br/>
            <Button fullWidth variant="outlined" onClick={this.prev} color="primary" className={"button-back margin-top-vh2"} ><ArrowBackIcon/> Voltar</Button>
          </div>
          <br />
          {
            api.event !== "" &&
            <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
          }
          {dialogFAQ}
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldsRequired: state.cartao.fieldsRequired,
    fields: state.cartao.fields,
    errors: state.cartao.errors,
    drops: state.cartao.drops,
    globalFields: state.global,
    api: state.cartao.api,
    email: state.dadosPessoais.fields.email,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartao: (state) => dispatch(setCartao(state)),
    setAPI: (api) => dispatch(setAPI(api)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cartao);