import React, { Component } from 'react'
import './ClienteExistente.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Typography, Box, Button } from '@material-ui/core'
import { connect } from 'react-redux'
  
export class ClienteExistente extends Component {

  /**
   * Remove o nome do cliente da session storage e caso o mesmo já esteja nulo, redireciona o usuário para a tela inicial.
   */
  removeNomeCliente = () => {

    if (sessionStorage.getItem('nomeCliente') === null)
      window.location.href = 'bem-vindo'

    sessionStorage.removeItem('nomeCliente')
  }
  render() {

    //Ira remover o nome do cliente da session storare.
    this.removeNomeCliente()

    return (
      <React.Fragment>
          <link rel="stylesheet" href="css/cores.css" />
          <Box className="box">
            <div className="margin-0">
                <img className="Logo" src="images/logo.png" alt="Logo do parceiro" />
                <p className="text-logo">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
            </div>
            <div className="div-60">
                <Typography className="title primary-color">
                    Olá {this.props.nome}!
                </Typography>
            </div>
            <div className="div-90 margin-top-vh2">
                <Typography className="text-info" >
                    Identificamos que <strong>você já possui um cartão</strong>. Este cadastro é apenas para novos clientes.
                </Typography>
            </div>
            <div className="div-90">
                <Typography className="text-info" >
                    Para acessar informações do seu cartão atual, acesse o link.
                </Typography>
            </div>
            <div className="div-center margin-top-vh2">
                <a href="https://banking.senff.com.br/login?tkn=7zE-Qkh1xeDka17M_D0cc08662MeartxQjSA7sx7fqyfZeXPRjh1geSc1A_UTKJokn7PtpHrf4ApSGn0hE8kIWnmnwb0EoNlxY4bOKRQF7E1" className="link-internet-banking" onClick={this.changeCEP}>Internet Banking</a>
            </div>
            <div className="div-center margin-top-vh3">
                <img className="img-home" src="/images/house.png" />
            </div>
            <div className="divButtons margin-top-vh3">
              <Button href="bem-vindo" fullWidth variant="outlined" className="buttonBack"><ArrowBackIcon/> Voltar ao início</Button>
            </div>
            <br/>
          </Box>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
    let nomeCliente = sessionStorage.getItem('nomeCliente')

    if(nomeCliente !== null)
      nomeCliente = nomeCliente.toString().split(" ")[0]

    return {
      nome: nomeCliente
    }
}
 
export default connect(mapStateToProps)(ClienteExistente);