import React, { Component } from 'react';
import './Etapa1.css'
import { Box, Typography, Button, MobileStepper } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

export class Etapa1 extends Component {

  render() {

    return (
      <React.Fragment>
        <Box className="box">
          <div className="margin-top-vh7 div-95">
            <Typography className="title">
              Bem-vindo(a)!
            </Typography>
          </div>
          <div className="margin-top-vh10 div-center">
            <img className="img-etapa-1" src="/images/etapa1.png" alt="Bem-vindo(a)!" />
          </div>
          <div className="div-center div-90">
            <Typography className="text-onboarding">
              Vamos começar seu cadastro, para isso precisamos de algumas informações importantes para a criação do seu cartão. :)
            </Typography>
          </div>
          <div className="divButtons div-bottom-5">
            <div className="div-flex">
              <div className="flex-7">
                <MobileStepper
                  variant="dots"
                  steps={3}
                  activeStep={0}
                  className="margin-top-vh1"
                />
              </div>
              <div className="flex-3">
                <Button variant="contained" onClick={this.props.nextStep} id="button-next-onboarding" className="button-next-onboarding" ><ArrowForwardIcon className="icon-button-next"/></Button>
              </div>
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
  }
}

export default Etapa1;