import React, { Component } from 'react';
import './HeaderStepper.css'
import { Stepper, Step, StepLabel } from '@material-ui/core';

export class AppBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    
  }

  render() {
    /** 
     * BLOCO COM OS SVG'S
     */
    const user = <svg class={this.props.step === 1 ? "icon-header-active" : "icon-header-completed"} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                  <path id="user_1_" data-name="user (1)" d="M31.42,5.391A18.406,18.406,0,0,0,5.391,31.42,18.406,18.406,0,0,0,31.42,5.391ZM7.988,30.865A10.53,10.53,0,0,1,18.406,21.6a10.491,10.491,0,0,1,10.418,9.267,16.215,16.215,0,0,1-20.835,0ZM18.406,19.377A5.59,5.59,0,1,1,24,13.787,5.6,5.6,0,0,1,18.406,19.377ZM30.68,29.042A12.679,12.679,0,0,0,22.743,20.2a7.747,7.747,0,1,0-8.672,0A12.668,12.668,0,0,0,6.13,29.04a16.249,16.249,0,1,1,24.55,0Zm0,0"/>
                  </svg>

    const home = <svg class={this.props.step > 1 ? this.props.step === 2 ? "icon-header-active" : "icon-header-completed" : "icon-header"} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                  <g id="Group_8" data-name="Group 8" transform="translate(0.101 1.019)">
                    <g id="home" transform="translate(0 -0.894)">
                      <g id="Group_5" data-name="Group 5" transform="translate(0 0)">
                        <g id="Group_4" data-name="Group 4">
                          <path id="Path_1" data-name="Path 1" class="cls-1" d="M37.358,41.092l-17.9-13.105a.981.981,0,0,0-1.159,0L.4,41.092A.981.981,0,1,0,1.56,42.676L18.88,29.995,36.2,42.675a.981.981,0,0,0,1.159-1.583Z" transform="translate(0.001 -27.797)"/>
                        </g>
                      </g>
                      <g id="Group_7" data-name="Group 7" transform="translate(4.163 15.1)">
                        <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
                          <path id="Path_2" data-name="Path 2" class="cls-1" d="M84.9,232.543a.981.981,0,0,0-.981.981v15.617H76.075v-8.525a4.906,4.906,0,0,0-9.812,0v8.525H58.414V233.524a.981.981,0,1,0-1.962,0v16.6a.981.981,0,0,0,.981.981h9.811a.981.981,0,0,0,.977-.9.732.732,0,0,0,0-.076v-9.506a2.944,2.944,0,1,1,5.887,0v9.506a.714.714,0,0,0,0,.075.981.981,0,0,0,.977.906H84.9a.981.981,0,0,0,.981-.981v-16.6A.981.981,0,0,0,84.9,232.543Z" transform="translate(-56.452 -232.543)"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

    const hand = <svg class={this.props.step > 2 ? this.props.step === 3 ? "icon-header-active" : "icon-header-completed" : "icon-header"} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                  <g id="hand_2_" data-name="hand (2)" transform="translate(0.203 0.15)">
                    <path id="Path_4" data-name="Path 4" class="cls-1" d="M188.449,8.144A8.144,8.144,0,1,0,196.594,0,8.154,8.154,0,0,0,188.449,8.144Zm14.808,0a6.664,6.664,0,1,1-6.664-6.663A6.671,6.671,0,0,1,203.257,8.144Zm0,0" transform="translate(-174.53)"/>
                    <path id="Path_5" data-name="Path 5" class="cls-1" d="M9.029,234.295a.74.74,0,0,0,1.045.006l3.59-3.536a2.237,2.237,0,0,0,.527-2.3l.772-.745A2.208,2.208,0,0,1,16.5,227.1h9.843A6.629,6.629,0,0,0,31,225.211c.051-.05-.391.471,6.715-8.022a2.962,2.962,0,0,0-4.507-3.844l-4.369,4.491a2.976,2.976,0,0,0-2.3-1.1H18.276a9.552,9.552,0,0,0-3.708-.74,9.16,9.16,0,0,0-8.349,4.734,2.212,2.212,0,0,0-1.98.614L.717,224.874a.741.741,0,0,0,0,1.044Zm5.539-16.821a8.083,8.083,0,0,1,3.26.678.739.739,0,0,0,.3.062h8.408a1.481,1.481,0,1,1,0,2.962H20.492a.74.74,0,1,0,0,1.481h6.041a2.957,2.957,0,0,0,2.936-3.349c4.226-4.344,4.822-4.955,4.848-4.985a1.481,1.481,0,1,1,2.261,1.913l-6.641,7.939a5.156,5.156,0,0,1-3.589,1.443H16.5a3.681,3.681,0,0,0-2.57,1.038l-.629.607-5.8-5.8A7.644,7.644,0,0,1,14.568,217.474Zm-9.281,4.913a.739.739,0,0,1,.917-.1c.128.078-.24-.257,6.413,6.387a.742.742,0,0,1,0,1.044l-3.06,3.015L2.286,225.4Zm0,0" transform="translate(-0.501 -196.595)"/>
                    <path id="Path_6" data-name="Path 6" class="cls-1" d="M267.315,40.729V41.6a2.221,2.221,0,0,0,.74,4.315.74.74,0,1,1,0,1.481,1.339,1.339,0,0,1-.961-.56.74.74,0,1,0-1.133.953,3.071,3.071,0,0,0,1.354.964v.864a.74.74,0,0,0,1.481,0v-.868a2.221,2.221,0,0,0-.74-4.315.74.74,0,0,1,0-1.481,1.189,1.189,0,0,1,.8.387.74.74,0,1,0,1.03-1.064,2.861,2.861,0,0,0-1.09-.682v-.862a.74.74,0,1,0-1.481,0Zm0,0" transform="translate(-246.139 -37.027)"/>
                  </g></svg>

    const creditCard = <svg class={this.props.step > 3 ? this.props.step === 4 ? "icon-header-active icon-card" : "icon-header-completed icon-card" : "icon-header icon-card"} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <g id="credit-card" transform="translate(0.15 0.15)">
                          <g id="Group_10" data-name="Group 10" transform="translate(0 0)">
                            <g id="Group_9" data-name="Group 9" transform="translate(0 0)">
                              <path id="Path_7" data-name="Path 7" class="cls-1" d="M34.065,85.333H3.961A3.967,3.967,0,0,0,0,89.294v17.429a3.967,3.967,0,0,0,3.961,3.961h30.1a3.967,3.967,0,0,0,3.961-3.961V89.294A3.966,3.966,0,0,0,34.065,85.333Zm2.377,21.39a2.38,2.38,0,0,1-2.377,2.377H3.961a2.38,2.38,0,0,1-2.377-2.377V89.294a2.38,2.38,0,0,1,2.377-2.377h30.1a2.38,2.38,0,0,1,2.377,2.377v17.429Z" transform="translate(0 -85.333)"/>
                            </g>
                          </g>
                          <g id="Group_12" data-name="Group 12" transform="translate(0 4.753)">
                            <g id="Group_11" data-name="Group 11">
                              <path id="Path_8" data-name="Path 8" class="cls-1" d="M37.234,149.333H.792a.793.793,0,0,0-.792.792v4.753a.793.793,0,0,0,.792.792H37.234a.793.793,0,0,0,.792-.792v-4.753A.793.793,0,0,0,37.234,149.333Zm-.792,4.753H1.584v-3.169H36.442v3.169Z" transform="translate(0 -149.333)"/>
                            </g>
                          </g>
                          <g id="Group_14" data-name="Group 14" transform="translate(4.753 15.844)">
                            <g id="Group_13" data-name="Group 13" transform="translate(0 0)">
                              <path id="Path_9" data-name="Path 9" class="cls-1" d="M74.3,298.667H64.792a.792.792,0,1,0,0,1.584H74.3a.792.792,0,1,0,0-1.584Z" transform="translate(-64 -298.667)"/>
                            </g>
                          </g>
                          <g id="Group_16" data-name="Group 16" transform="translate(4.753 19.013)">
                            <g id="Group_15" data-name="Group 15" transform="translate(0 0)">
                              <path id="Path_10" data-name="Path 10" class="cls-1" d="M74.3,341.333H64.792a.792.792,0,0,0,0,1.585H74.3a.792.792,0,0,0,0-1.585Z" transform="translate(-64 -341.333)"/>
                            </g>
                          </g>
                          <g id="Group_18" data-name="Group 18" transform="translate(26.935 14.26)">
                            <g id="Group_17" data-name="Group 17">
                              <path id="Path_11" data-name="Path 11" class="cls-1" d="M366.628,277.333h-1.584a2.38,2.38,0,0,0-2.377,2.377v1.584a2.38,2.38,0,0,0,2.377,2.377h1.584A2.38,2.38,0,0,0,369,281.294V279.71A2.38,2.38,0,0,0,366.628,277.333Zm.792,3.961a.793.793,0,0,1-.792.792h-1.584a.793.793,0,0,1-.792-.792V279.71a.793.793,0,0,1,.792-.792h1.584a.793.793,0,0,1,.792.792Z" transform="translate(-362.667 -277.333)"/>
                            </g>
                          </g>
                        </g></svg>

    const camera = <svg class={this.props.step > 4 ? this.props.step === 5 ? "icon-header-active icon-card" : "icon-header-completed icon-card" : "icon-header icon-card"} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g id="photo-camera_3_" data-name="photo-camera (3)" transform="translate(0 -46.15)">
                      <g id="Group_19" data-name="Group 19" transform="translate(0 46.15)">
                        <path id="Path_12" data-name="Path 12" class="cls-1" d="M0,55.3V71.567a4.5,4.5,0,0,0,4.5,4.5H32.359a4.5,4.5,0,0,0,4.5-4.5V55.3a4.282,4.282,0,0,0-4.28-4.28H26.59l-.143-.624a5.468,5.468,0,0,0-5.356-4.25H15.758A5.48,5.48,0,0,0,10.4,50.4l-.143.624H4.28A4.287,4.287,0,0,0,0,55.3Zm11-2.437a.917.917,0,0,0,.9-.715l.308-1.339a3.638,3.638,0,0,1,3.558-2.821h5.333a3.638,3.638,0,0,1,3.558,2.821l.308,1.339a.925.925,0,0,0,.9.715H32.57A2.437,2.437,0,0,1,35.007,55.3V71.567a2.655,2.655,0,0,1-2.655,2.655H4.5a2.655,2.655,0,0,1-2.655-2.655V55.3A2.437,2.437,0,0,1,4.28,52.867H11Z" transform="translate(0 -46.15)"/>
                        <circle id="Ellipse_4" data-name="Ellipse 4" class="cls-1" cx="1.234" cy="1.234" r="1.234" transform="translate(5.002 9.417)"/>
                        <path id="Path_13" data-name="Path 13" class="cls-1" d="M149.84,190.63a7.74,7.74,0,1,0-7.74-7.74A7.753,7.753,0,0,0,149.84,190.63Zm0-13.637a5.9,5.9,0,1,1-5.9,5.9A5.907,5.907,0,0,1,149.84,176.993Z" transform="translate(-131.411 -165.447)"/>
                      </g>
                    </g></svg>
     /** 
     * FIM DO BLOCO
     */

    return (
      <div className="header-stepper">
        <div id="div-header-stepper">
          <Stepper alternativeLabel activeStep={(this.props.step - 1)}>
            <Step ><StepLabel icon={user}>Dados pessoais</StepLabel></Step>
            <Step><StepLabel icon={home} >Endereço</StepLabel></Step>
            <Step><StepLabel icon={hand} >Renda</StepLabel></Step>
            <Step><StepLabel icon={creditCard}>Personalize seu cartão</StepLabel></Step>
            <Step><StepLabel icon={camera}>Documentos</StepLabel></Step>
          </Stepper>
        </div>
      </div>
    );
  }
}

export default AppBar;