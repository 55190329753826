import swal from 'sweetalert';

export const loadState = (reducer) => {
  try {
    const serializedState = sessionStorage.getItem('state')

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState)[reducer]
  } catch (error) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('state', serializedState)
  } catch (error) {
    // Ignore write errors.
  }
}

export const checkStateExpired = () => {
  try {
    
    let serializedState = sessionStorage.getItem('state')
    let state = JSON.parse(serializedState)
    let urlLocal = window.location.href.split('/')[3]
    let url = window.location.href.split('/')[4]

    if (url === 'bem-vindo' || urlLocal === 'bem-vindo')
      return false;
    
    if (serializedState != null && new Date() > new Date(state.global.dataExpiracao)) {
      sessionStorage.removeItem('state')
      swal("Sessão expirada", "Você será redirecionado para a página inicial", "warning")
      setTimeout(function() {
        window.location.href = 'bem-vindo'
      }, 3000)
    }

  } catch (error) {
    // Ignore write errors.
  }
}