import React, { Component } from 'react';
import './DadosIniciais.css'
import { Box, Typography, Button } from '@material-ui/core';
import InputText from '../../../components/InputText/InputText'
import InputMask from '../../../components/InputMask/InputMask'
import { connect } from 'react-redux'
import { setDadosIniciais, setAPI } from '../../../store/actions/dadosIniciaisActions'
import { validaCPF, validaCelular, validaNomeCompleto } from './../../../common/validators'
import { carregarDrops, obterRascunhoProposta } from '../../../store/actions/TokenActions'
import Snackbar from '../../../components/Snackbar/Snackbar'
import FAQ from './../../FAQ/FAQ'

export class DadosIniciais extends Component {
  state = {
    fieldsRequired: this.props.fieldsRequired,
    fields: this.props.fields,
    errors: this.props.errors,
    api: this.props.api,
    faq: false,
  }

  async componentDidMount () {
    //Scrolla a tela até o topo
    window.scrollTo(0, 0)

    //Verifica se o parametro de reenvio da proposta está marcado como true, caso esteja, realiza a consulta da proposta e direciona o usuário para o passo atual
    let reenvio_da_proposta = sessionStorage.getItem('reenvio_da_proposta')
    let idSessao = sessionStorage.getItem('idSessao')
    
    if (Boolean(reenvio_da_proposta) === true && idSessao !== null) {
      //Habilita o loading da tela
      this.props.changeLoading()
      
      //Chamada para a API
      await this.props.carregarDrops(this.state, idSessao)
      await this.props.buscarProposta(idSessao)
      this.state.api = this.props.api

      //Condição para verificar se a API não retornou erro, caso tenha retornado erro o step não é atualizado
      if (this.props.fields.stepInvalido === false)
        await this.props.refreshStep()

      //Remove as váriaveis utilizadas da sessão
      sessionStorage.removeItem('reenvio_da_proposta')
      sessionStorage.removeItem('idSessao')

      //Desabilita o loading da tela
      this.props.changeLoading()
    }

  }

  continue = async e => {
    e.preventDefault()
    this.props.changeLoading()
    this.state.fields.stepInvalido = true

    //Chamada para a API para integrar os dados iniciais
    await this.props.setDadosIniciais(this.state)
    this.state.api = this.props.api

    if (this.props.fields.stepInvalido === false)
      await this.props.refreshStep()

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "")
        countErrors++
    })

    for (var error in this.state.errors) {
      if (this.state.errors[error].length > 0 && document.getElementById(error) !== null) {
        document.getElementById(error).focus()
        countErrors++
      }
    }

    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
    this.props.changeLoading()
  }

  prev = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  closeSnackbar = async e => {
    e.preventDefault()
    let api = {...this.state.api, event: '', message: ''}
    await this.props.setAPI(api)
    await this.setState({api: api})
  }

  handleTextFieldKeyDown = (field) => event => {
    if (event.key === 'Enter' || event.key === undefined) {
      document.getElementById(field).focus()
      event.preventDefault();
    }
  }

  changeText = (campo) => e => {

    if (e.target.id === 'nome' && e.target.value !== '') {
      e.target.value = e.target.value.replace(/([^0-9a-z A-Z]|[\d])/g, '')
      this.state.fields[campo] = e.target.value
      this.props.nomeChanged(this.state.fields['nome']);
    }

    e.target.value = e.target.value.toUpperCase()
    this.state.fields[campo] = e.target.value
    if (e.target.id === 'cpf') this.props.cpfChanged(this.state.fields['cpf']);

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.fields[field] === "" || (campo === 'celular' && validaCelular(this.state.fields.celular) === false))
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  handleChange = (input) => e => {
    e.preventDefault();

    this.state.errors[input] = []
    if (e.target.value === "") {
      this.state.errors[input].push("Campo obrigatório")
    } else {
      this.state.errors[input] = []
    }

    this.state.fields[input] = e.target.value.trim()

    if (e.target.id === 'nome' && e.target.value !== '')
      validaNomeCompleto(e.target.value) ? this.props.nomeChanged(this.state.fields['nome']) : this.state.errors.nome.push('Você deve digitar seu nome completo');
    
    if (e.target.id === 'cpf' && e.target.value !== '')
      validaCPF(e.target.value) ? this.props.cpfChanged(this.state.fields['cpf']) : this.state.errors.cpf.push('CPF inválido')

    if (e.target.id === 'celular' && e.target.value !== '' && validaCelular(e.target.value) === false)
      this.state.errors.celular.push('Digite um celular válido')

    let countErrors = 0
    this.state.fieldsRequired.map(field => {
      if (this.state.errors[field].length > 0 || this.state.fields[field] === "")
        countErrors++
    })

    this.state.fields.stepInvalido = true
    if (countErrors === 0)
      this.state.fields.stepInvalido = false

    this.setState({})
  }

  render() {
    const { fields, errors, fieldsRequired, api, faq } = this.state

    let consultarStatus = Boolean(sessionStorage.getItem('consultarStatus'))
    const dialogFAQ = <FAQ open={faq} close={() => this.setState({faq: false})} mobile={this.props.mobile} />

    const mudouCpf = () => {
      this.handleTextFieldKeyDown('cpf'); 
      this.props.cpfChanged(this.state.fields['cpf']); 
    }

    const mudouNome = () => {
      this.handleTextFieldKeyDown('nome'); 
      this.props.nomeChanged(this.state.fields['nome']); 
    }

    return (
      <React.Fragment>
        <Box className="div-flex div-height-vh100">
          <Box className="box-left margin-top-vh3 box-grey">
            <div className="margin-top-vh2">
              <img className="Logo" src="images/logo.png" alt="Logo do parceiro" />
              <p className="text-logo text-left margin-left-vw5">parceiro <a href="https://www.senff.com.br" className="text-link">Senff</a></p>
            </div>
            <div className="margin-top-vh10">
              <Typography className="title text-left margin-left-vw5" >
                {consultarStatus === false ? 'Bem-vindo(a)!' : 'Que bom ter você por aqui!'}
              </Typography>
            </div>
            <div className="div-40 margin-top-vh3">
              <Typography className="sub-title text-left margin-left-vw5" >
                {consultarStatus === false ? 
                'Precisaremos de algumas informações importantes para a criação do seu cartão :)' : 
                'Preencha os dados ao lado para que possamos verificar o status da sua proposta.'}
              </Typography>
            </div>
            <div className="div-40 margin-top-vh3">
              <Typography className="sub-title text-left margin-left-vw5" >
                {consultarStatus === false ? 'Não se preocupe, este processo pode demorar um pouquinho, mas vai valer a pena!' : ''}
              </Typography>
            </div>
            <div className="margin-top-vh13 margin-left-vw5 div-flex">
              <img className="img-dados-iniciais flex-3" src="/images/dados_iniciais_desktop.png" alt="Bem-vindo"/>
              <div className="flex-6">
                <Typography className="sub-title-wizard" >
                  Sua segurança é a nossa prioridade, seus dados estão totalmente protegidos conosco.
                </Typography>
              </div>
            </div>
          </Box>
          <Box className="flex-5 margin-left-vw10 div-center">
            <div className="margin-top-vh15 div-left">
              <Typography className="title">
                {consultarStatus === false ? 'Sobre você' : 'Consulta do status'}
              </Typography>
            </div>
            <div className="margin-top-vh2 div-left">
              <Typography className="sub-title" >
                {consultarStatus === false ? 'Vamos começar pelo essencial :)' : ''}
              </Typography>
            </div>
            <div className={"text-right div-faq margin-top-vh5"} onClick={() => this.setState({faq: true})}>
              <a className="link-faq">Precisa de ajuda?</a>
            </div>
            <div className="div-field margin-top-vh10">
              <InputText 
                value={fields.nome} 
                error={errors.nome} 
                field={"nome"}
                label={"Qual o seu nome completo?"}
                maxLength={50}
                required={fieldsRequired.includes('nome')} 
                onChange={this.changeText('nome')}
                onBlur={this.handleChange('nome')}
                onKeyDown={mudouNome} 
              />
            </div>
            <div className="div-field">
              <InputMask 
                type={"tel"}
                mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                value={fields.cpf} 
                error={errors.cpf} 
                field={"cpf"}
                label={"CPF"}
                required={fieldsRequired.includes('cpf')}
                onChange={this.changeText('cpf')}
                onBlur={this.handleChange('cpf')} 
                onKeyDown={mudouCpf}
                placeholder={"000.000.000-00"}
              />
            </div>
            <div className="div-field">
              <InputMask
                type={"tel"}
                mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={fields.celular} 
                error={errors.celular} 
                field={"celular"}
                label={"Telefone celular"} 
                required={fieldsRequired.includes('celular')} 
                onChange={this.changeText('celular')}
                onBlur={this.handleChange('celular')} 
                onKeyDown={this.handleTextFieldKeyDown('celular')} 
                placeholder={"(DD) 99999-9999"}
              />
            </div>
            <div className="divButtons div-35 margin-top-vh10">
              <Button variant="contained" onClick={this.continue} disabled={fields.stepInvalido} className={fields.stepInvalido === false ? "button-next" : "button-disabled"} color="primary">Continuar</Button>
            </div>
            <br />
            {
              api.event !== "" &&
              <Snackbar open={api.event !== ""} onClose={this.closeSnackbar} variant={api.event} message={api.message} />
            }
            {dialogFAQ}
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldsRequired: state.dadosIniciais.fieldsRequired,
    fields: state.dadosIniciais.fields,
    errors: state.dadosIniciais.errors,
    api: state.dadosIniciais.api,
    globalFields: state.global,
    possui_cartao: state.clienteExistente.nome !== null,
    consultarStatus: state.global.consultarStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDadosIniciais: (state) => dispatch(setDadosIniciais(state)),
    setAPI: (api) => dispatch(setAPI(api)),
    carregarDrops: (state, idSessao) => dispatch(carregarDrops(state, idSessao)),
    buscarProposta: (idSessao) => dispatch(obterRascunhoProposta(idSessao)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DadosIniciais);
