import React, { Component } from 'react'
import './NotFound.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Typography, Box, Button } from '@material-ui/core'
  
export class NotFound extends Component {

  render() {
    return (
      <React.Fragment>
          <Box className="box">
            <div className="div-right">
              <img className="img-404-superior-desktop" src="/images/404.png" />
            </div>
            <div className="div-60">
              <Typography className="title-not-found margin-0">
                  Ops...
              </Typography>
            </div>
            <div className="div-90 margin-top-vh3">
              <Typography className="sub-title-not-found" >
                  Como você chegou até aqui?
              </Typography>
            </div>
            <div className="div-center margin-top-vh5">
                <img className="img-404-center" src="/images/center_404.png" />
            </div>
            <div className="div-left margin-top-vh3">
                <img className="micro-logo-inferior" src="/images/senff.png" />
            </div>
            <div className="div-center">
              <Typography className="text-description-not-found" >
                  Parece que a página que você tentou acessar não existe.
              </Typography>
            </div>
          </Box>
      </React.Fragment>
    );
  }
}

 
export default NotFound;