import { loadState} from './../sessionStorage'

const { detect } = require('detect-browser')
const browser = detect()


let initState = {
    step: 1,
    consultaStatus: false,
    idLojista: '',
    tokenSessao: '',
    browser: browser === null ? "" : browser.name,
    device: navigator.userAgentData ? navigator.userAgentData.platform : navigator.platform,
    os: browser === null ? "" : browser.os,
    date: new Date().toLocaleString(),
    dataInicio: new Date(),
    dataExpiracao: new Date(new Date().setSeconds(new Date().getSeconds() + (30 * 60)))
}

const persistedState = loadState('global')
if (typeof persistedState !== "undefined") {
    initState = persistedState
}

const globalReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_TOKEN_SESSAO':
        return { ...state, tokenSessao: action.tokenSessao }
      case 'SET_STEP':
        return { ...state, step: action.step }
      case 'SET_ID_LOJISTA':
        return { ...state, idLojista: action.idLojista }
      case 'SET_CORES':
        return { ...state, cores: action.cores }
      case 'CONSULTAR_STATUS':
        return { ...state, consultaStatus: true }
      default:
          return state;
    }
}

export default globalReducer